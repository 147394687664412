import { authHeader, postHeader, history, handleResponse } from "../helpers";
import { urlConstants } from "../constants";

export const overtimeService = {
  createOvertime,
  getUserOvertimeByOrder,
  getUserOvertimeById
};

function createOvertime(overtime) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(overtime)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/overtime/create`,
    requestOptions
  ).then(handleResponse);
}

function getUserOvertimeByOrder(orderId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/overtime/get-by-id/` + orderId,
    requestOptions
  ).then(handleResponse);
}

function getUserOvertimeById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/overtime/by-id/` + id,
    requestOptions
  ).then(handleResponse);
}
