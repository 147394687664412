import { combineReducers } from 'redux';

import { authentication } from './authentification.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { jobs } from './jobs.reducer';

const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    alert,
    jobs
});

export default rootReducer;