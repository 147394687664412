import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

export default function TransitionsModal() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span style={classes.button} type="button" onClick={handleOpen}>
        Help me to estimate hours
      </span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div style={classes.paper}>
            <h4 style={classes.modalHeader} id="transition-modal-description">
              Please see the table below for some typical examples of hours
            </h4>
            <table style={classes.table}>
              <tbody>
                <tr>
                  <td style={classes.tdDataName} />
                  <td style={classes.tdData}>Small (&lt;100sqm)</td>
                  <td style={classes.tdData}>Med (100-300sqm)</td>
                  <td style={classes.tdData}>Large (&gt;300sqm)</td>
                </tr>
                <tr>
                  <td style={classes.tdDataName}>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    less than 2 weeks's growth
                  </td>
                  <td style={classes.tdData}>2</td>
                  <td style={classes.tdData}>3</td>
                  <td style={classes.tdData}>4</td>
                </tr>
                <tr>
                  <td style={classes.tdDataName}>2- 4 weeks growth</td>
                  <td style={classes.tdData}>3</td>
                  <td style={classes.tdData}>5</td>
                  <td style={classes.tdData}>8</td>
                </tr>
                <tr>
                  <td style={classes.tdDataName}>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    over a month's growth
                  </td>
                  <td style={classes.tdData}>5</td>
                  <td style={classes.tdData}>8</td>
                  <td style={classes.tdData}>16</td>
                </tr>
              </tbody>
            </table>
            <div style={classes.addInfo}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              If you're still stuck then email{" "}
              <a href="mailto:hello@tecmow.com">hello@tecmow.com</a> with a
              description and some photos or call the office on{" "}
              <a href="tel:012158225333">01215 8225333</a>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = () => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalHeader: {
    fontWeight: 500,
    textAlign: "center"
  },
  paper: {
    backgroundColor: "#fff",
    border: "2px solid #4caf50",
    padding: "15px",
    fontSize: "16px",
    borderRadius: "10px"
  },
  table: {
    textAlign: "center",
    marginTop: "30px",
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: "10px",
    borderCollapse: "collapse"
  },
  tdDataName: { border: "1px solid black" },
  tdData: { width: 75, border: "1px solid black" },
  addInfo: { fontSize: "16px", fontWeight: 500, textAlign: "center", marginTop: "30px" },
  button: {
    color: "#4caf50",
    fontWeight: 600,
    fontSize: "18px",
    cursor: "pointer"
  }
});
