import React from "react";
import {urlConstants} from "../../constants/api-url";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Favorite from "@material-ui/icons/Favorite";
import withStyles from "@material-ui/core/styles/withStyles";
import mainLayoutStyles from "assets/jss/material-kit-pro-react/views/mainLayoutStyle.jsx";

const MainLayout = ({children, history, classes, ...rest}) => {
    return (
        <div>
             <Header
                    color="transparent"
                    brand="TEC MOW"
                    logoLink={urlConstants.LANDING_URL}
                    links={<HeaderLinks history={history} dropdownHoverColor="primary"/>}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "primary"
                    }}
                    {...rest}
                />
                <Parallax
                    image={require("assets/img/tec-mow/blade-of-grass-depth.jpg")}
                    filter="dark"
                    className={
                        classes.parallax
                    }
                />
                <div>{children}</div>
                <Footer
                    content={
                        <div>
                            <div className={classes.right}>
                                &copy; {1900 + new Date().getYear()} , made
                                with{" "}
                                <Favorite className={classes.icon}/> by{" "}
                                <a href="https://www.creative-tim.com">Creative
                                    Tim</a> for a
                                better web.
                            </div>
                        </div>
                    }
                />
              
        </div>
    )
}

export default withStyles(mainLayoutStyles)(MainLayout)