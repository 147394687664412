import { overtimeConstants } from "../constants/overtime.constants";
import { overtimeService } from "../services/overtime.service";
import { alertActions } from "./";

export const overtimeActions = {
  createOvertime,
  getUserOvertimeByOrder,
  getOvertimeById
};

function createOvertime(overtimeObj) {
  return async dispatch => {
    try {
      dispatch(request(overtimeObj));
      const overtime = await overtimeService.createOvertime(overtimeObj);
      dispatch(success(overtime));
      return overtime;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(overtime) {
    return { type: overtimeConstants.POST_OVERTIME_REQUEST, overtime };
  }
  function success(overtime) {
    return { type: overtimeConstants.POST_OVERTIME_SUCCESS, overtime };
  }
  function failure(error) {
    return { type: overtimeConstants.POST_OVERTIME_FAILURE, error };
  }
}

function getUserOvertimeByOrder(orderId) {
  return async dispatch => {
    try {
      dispatch(request());
      const overtime = await overtimeService.getUserOvertimeByOrder(orderId);
      dispatch(success(overtime));
      return overtime;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(overtime) {
    return { type: overtimeConstants.GET_OVERTIME_REQUEST, overtime };
  }
  function success(overtime) {
    return { type: overtimeConstants.GET_OVERTIME_SUCCESS, overtime };
  }
  function failure(error) {
    return { type: overtimeConstants.GET_OVERTIME_FAILURE, error };
  }
}
function getOvertimeById(id) {
  return async dispatch => {
    try {
      dispatch(request());
      const overtime = await overtimeService.getUserOvertimeById(id);
      dispatch(success(overtime));
      return overtime;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(overtime) {
    return { type: overtimeConstants.GET_OVERTIME_REQUEST, overtime };
  }
  function success(overtime) {
    return { type: overtimeConstants.GET_OVERTIME_SUCCESS, overtime };
  }
  function failure(error) {
    return { type: overtimeConstants.GET_OVERTIME_FAILURE, error };
  }
}
