import {
    successColor,
    primaryColor
} from "assets/jss/material-kit-pro-react.jsx";

const photoPreloaderComponentStyle = {
        wrapper: {
            display: "block",
            top: "0",
            opacity: "1",
            background: primaryColor[0],
            zIndex: "10000",
			maxWidth: '50px',
			borderRadius: '50%',
			margin: '0 auto',
            "&>div": {
                height: "50px !important",
                width: "50px !important",
                position: "absolute",
                top: "21%",
                left: "0 !important",
                right: "0 !important",
                margin: "0 auto",

                "&>svg": {
                    color: successColor[0]
                }
            }
        }
};

export default photoPreloaderComponentStyle;