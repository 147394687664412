import { feedbackConstants } from "../constants/feedback.constants";
import { feedbackService } from "../services/feedback.service";
import { alertActions } from "./";

export const feedbackActions = {
  leaveFeedback,
  findFeedback
};

function findFeedback(feedbackId) {
  return async dispatch => {
    try {
      dispatch(request(feedbackId));
      const feedback = await feedbackService.getUserJobFeedback(feedbackId);
      dispatch(success(feedback));
      return feedback;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(feedback) {
    return { type: feedbackConstants.GET_FEEDBACK_REQUEST, feedback };
  }
  function success(feedback) {
    return { type: feedbackConstants.GET_FEEDBACK_SUCCESS, feedback };
  }
  function failure(error) {
    return { type: feedbackConstants.GET_FEEDBACK_FAILURE, error };
  }
}

function leaveFeedback(feedbackObj) {
  return async dispatch => {
    try {
      dispatch(request(feedbackObj));
      const feedback = await feedbackService.createFeedback(feedbackObj);
      dispatch(success(feedback));
      return feedback;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(feedback) {
    return { type: feedbackConstants.POST_FEEDBACK_REQUEST, feedback };
  }
  function success(feedback) {
    return { type: feedbackConstants.POST_FEEDBACK_SUCCESS, feedback };
  }
  function failure(error) {
    return { type: feedbackConstants.POST_FEEDBACK_FAILURE, error };
  }
}
