import { authHeader, postHeader, history, handleResponse } from "../helpers";
import { urlConstants } from "../constants";

export const orderService = {
  createOrder,
  getUserOrderById,
  geOrderById
};

function createOrder(order) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(order)
  };

  return fetch(urlConstants.BACKEND_URL + `/order/create`, requestOptions).then(
    handleResponse
  );
}

function getUserOrderById(jobId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/order/get-by-id/` + jobId,
    requestOptions
  ).then(handleResponse);
}
function geOrderById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/order/by-id/` + id,
    requestOptions
  ).then(handleResponse);
}
