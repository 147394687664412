import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

function MyDropzone({ uploadFiles }) {

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container">
      <div
        {...getRootProps({
          onChange: event => {
            uploadFiles(event.target.files);
          }
        })}
        className={"file-dropzone"}
      >
        <input {...getInputProps()} />
        <p>
          drag or <span className={"upload-span"}>upload</span> project files
        </p>
      </div>

      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
    </section>
  );
}

export default MyDropzone;
