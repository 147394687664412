import React from "react";
import {createBrowserHistory} from "history";
import { Router, Route, Switch } from "react-router";
import {Provider} from 'react-redux';
import "../../assets/scss/material-kit-pro-react.scss?v=1.3.0";
import SwitchComponent from "../SwitchComponent/SwitchComponent"
import store from "../../store";

var hist = createBrowserHistory();

function RouterComponent() {
    return (
        <Provider store={store}>
            <Router history={hist}>
                <Route path="/" render={p => <SwitchComponent {...p}/>} />
            </Router>
        </Provider>
    );
}

export default RouterComponent;
