export const paymentConstants = {
  POST_JOB_PAYMENT_REQUEST: "POST_JOB_PAYMENT_REQUEST",
  POST_JOB_PAYMENT_SUCCESS: "POST_JOB_PAYMENT_SUCCESS",
  POST_JOB_PAYMENT_FAILURE: "POST_JOB_PAYMENT_FAILURE",

  POST_SEND_JOB_PAYMENT_REQUEST: "POST_SEND_JOB_PAYMENT_REQUEST",
  POST_SEND_JOB_PAYMENT_SUCCESS: "POST_SEND_JOB_PAYMENT_SUCCESS",
  POST_SEND_JOB_PAYMENT_FAILURE: "POST_SEND_JOB_PAYMENT_FAILURE",

  POST_JOB_PAYMENT_OVERTIME_REQUEST: "POST_JOB_PAYMENT_OVERTIME_REQUEST",
  POST_JOB_PAYMENT_OVERTIME_SUCCESS: "POST_JOB_PAYMENT_OVERTIME_SUCCESS",
  POST_JOB_PAYMENT_OVERTIME_FAILURE: "POST_JOB_PAYMENT_OVERTIME_FAILURE",

  POST_JOB_EXECUTE_PAYMENT_REQUEST: "POST_JOB_EXECUTE_PAYMENT_REQUEST",
  POST_JOB_PAYMENT_EXECUTE_SUCCESS: "POST_JOB_PAYMENT_EXECUTE_SUCCESS",
  POST_JOB_PAYMENT_EXECUTE_FAILURE: "POST_JOB_PAYMENT_EXECUTE_FAILURE"
};
