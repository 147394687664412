import { authHeader, postHeader, history, handleResponse } from "../helpers";
import { urlConstants } from "../constants";

export const jobService = {
  createJob,
  getJobsPaginated,
  getJobsPaginatedClientContracts,
  deleteJobById,
  getUserJobById,
  getJobById,
  updateUserJobById,
  updateContractorJobById,
  cancelContractorJobById,
  getAllJobsPaginated,
  getContractorJobsPaginated,
  searchJobsPaginated,
  getJobByselfId
};

function createJob(job) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(job)
  };

  return fetch(urlConstants.BACKEND_URL + `/job/create`, requestOptions).then(
    handleResponse
  );
}

function getJobsPaginated(page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/get-paginated?page=` + page,
    requestOptions
  ).then(handleResponse);
}

function getJobsPaginatedClientContracts(page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/get-paginated-client-contracts?page=` + page,
    requestOptions
  ).then(handleResponse);
}

function deleteJobById(jobId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/delete-by-id/` + jobId,
    requestOptions
  ).then(handleResponse);
}

function getUserJobById(jobId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/get-by-id/` + jobId,
    requestOptions
  ).then(handleResponse);
}

function getJobById(jobId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/get-by-id/` + jobId,
    requestOptions
  ).then(handleResponse);
}

function getJobByselfId(jobId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/get-self-by-id/` + jobId,
    requestOptions
  ).then(handleResponse);
}

function getAllJobsPaginated(page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/get-all-jobs?page=` + page,
    requestOptions
  ).then(handleResponse);
}

function getContractorJobsPaginated(page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/get-contractor-jobs?page=` + page,
    requestOptions
  ).then(handleResponse);
}

function searchJobsPaginated(text, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/search-jobs/` + text + `?page=` + page,
    requestOptions
  ).then(handleResponse);
}

function updateUserJobById(jobId, jobObject) {
  const requestOptions = {
    method: "PUT",
    headers: postHeader(),
    body: JSON.stringify(jobObject)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/update-by-id/` + jobId,
    requestOptions
  ).then(handleResponse);
}

function updateContractorJobById(jobId, jobObject) {
  const requestOptions = {
    method: "PUT",
    headers: postHeader(),
    body: JSON.stringify(jobObject)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/accept-contract/` + jobId,
    requestOptions
  ).then(handleResponse);
}

function cancelContractorJobById(jobId, jobObject) {
  const requestOptions = {
    method: "PUT",
    headers: postHeader(),
    body: JSON.stringify(jobObject)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/job/cancel-contract/` + jobId,
    requestOptions
  ).then(handleResponse);
}
