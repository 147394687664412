/* eslint-disable */
import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import jobDetailsPageStyle
    from "assets/jss/material-kit-pro-react/views/jobDetailsPageStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../actions";
import {jobActions} from "../../actions";
import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent"
import Slide from "@material-ui/core/Slide";
import ViewJobPostTab from "./NavPills/ViewJobPostTab";


function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class JobDetailsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jobId: "",
            jobName: "",
            jobPayment: "",
            jobNameError: false,
            jobNameErrorMessage: "",
            jobDescription: "",
            jobDescriptionError: false,
            jobDescriptionErrorMessage: "",
            lawnMoving: false,
            maintainingHerbaceousBorders: false,
            gardenCoaching: false,
            gardenClearance: false,
            hedgeTrimming: false,
            pruning: false,
            hours: "",
            bags: "",
            lawnSize: "",
            lawnSizeError: false,
            lawnSizeErrorMessage: "",
            maintenanceFrequency: "",

            dateTime: "",
            dateTimeEnd: "",
            dateTimeError: false,
            dateTimeEndError: false,
            dateTimeErrorMessage: "",
            dateTimeEndErrorMessage: "",
            city: "",
            cityError: false,
            cityErrorMessage: "",
            zipCode: "",
            zipCodeError: false,
            zipCodeErrorMessage: "",
            address: "",
            addressError: false,
            addressErrorMessage: "",
            minPrice: "25",
            maxPrice: "100",

            orderPhone: "",
            orderPhoneError: false,
            orderPhoneErrorMessage: "",
            orderBestTimeToCall: "",
            orderProposals: 0,

            reviewFlag: false,
            isBusy: true,

            step: 5,

            servicesString: "",
            servicesStringFlag: true,

            errorDialog: false,
            comments: [],
            jobDetail: []

        };
    }

    componentDidMount() {
        this.setState({['isBusy']: true});
        this.props.getUser().then(
            res => {
                let jobId = this.props.match.params.id;
                this.props.getUserJobById(jobId).then(
                    res => {
                        const jobObj = this.props.job;
                       
                        this.setState({
                            jobDetail: jobObj,
                            jobId: jobId,
                            jobName: jobObj.jobName,
                            jobPayment: jobObj.payment,
                            jobDescription: jobObj.jobDescription,
                            lawnMoving: jobObj.lawnMoving,
                            maintainingHerbaceousBorders: jobObj.maintainingHerbaceousBorders,
                            gardenCoaching: jobObj.gardenCoaching,
                            gardenClearance: jobObj.gardenClearance,
                            hedgeTrimming: jobObj.hedgeTrimming,
                            pruning: jobObj.pruning,
                            lawnSize: jobObj.lawnSize,
                            hours: jobObj.hours,
                            bags: jobObj.bags,
                            total: jobObj.total,
                            maintenanceFrequency: jobObj.maintenanceFrequency,
                            dateTime: jobObj.dateTime,
                            dateTimeEnd: jobObj.dateTimeEnd,
                            city: jobObj.city,
                            zipCode: jobObj.zipCode,
                            address: jobObj.address,
                            payment: jobObj.payment,
                            minPrice: jobObj.minPrice,
                            maxPrice: jobObj.maxPrice,
                            orderPhone: jobObj.orderPhone,
                            orderBestTimeToCall: jobObj.orderBestTimeToCall,
                        }, () => {
                            let proposalsArr = [
                                {
                                    id: '1',
                                    author: 'Tina Andrew',
                                    email: 'Tina@gmail.com',
                                    phone: '564564564156',
                                    message: "Chance too good. God level bars. I'm so proud of @LifeOfDesiigner #1 song in the country. Panda! Don't be scared of the truth because we need to restart the human foundation in truth I stand with the most humility. We are so blessed! Editors, who receive no royalties or expenses and who are only very rarely commissioned by the Society, are encouraged to approach the Editorial Secretary with a detailed proposal of the text they wish to suggest to the Society early in their work",
                                    date: '2019-05-23 16:00'
                                }
                            ];

                            this.setState({
                                isBusy: false,
                                 comments: proposalsArr
                            });
                        });
                    },
                    err => {
                        this.setState({['isBusy']: false});
                        this.props.history.push({
                            pathname: '/customer-dashboard',
                            state: {
                                jobError: err
                            }
                        });
                    }
                );

            },
            err => {
                console.log(err);
                this.props.history.push({
                    pathname: '/customer-dashboard',
                    state: {
                        jobError: err
                    }
                });
            }
        );

        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    render() {
        const {classes, ...rest} = this.props;
        return (
            <div>
                <div>
                    <div className={classNames(classes.main, classes.mainRaised)}>
                        <div
                            className={
                                classes.jobNameDiv
                            }>
                            <p
                                className={
                                    classes.jobNameP
                                }>
                                { this.state.jobName }
                            </p>
                        </div>
                        <ViewJobPostTab
                            job={this.state.jobDetail}
                            jobName={this.state.jobName}
                            jobDescription={this.state.jobDescription}
                            lawnMoving={this.state.lawnMoving}
                            maintainingHerbaceousBorders={this.state.maintainingHerbaceousBorders}
                            gardenCoaching={this.state.gardenCoaching}
                            gardenClearance={this.state.gardenClearance}
                            hedgeTrimming={this.state.hedgeTrimming}
                            pruning={this.state.pruning}
                            hours={this.state.hours}
                            bags={this.state.bags}
                            lawnSize={this.state.lawnSize}
                            maintenanceFrequency={this.state.maintenanceFrequency}
                            dateTime={this.state.dateTime}
                            dateTimeEnd={this.state.dateTimeEnd}
                            city={this.state.city}
                            total={this.state.total}
                            zipCode={this.state.zipCode}
                            address={this.state.address}
                            minPrice={this.state.minPrice}
                            maxPrice={this.state.maxPrice}
                            orderPhone={this.state.orderPhone}
                            jobPayment={this.state.payment}
                            orderBestTimeToCall={this.state.orderBestTimeToCall}
                            orderProposals={this.state.orderProposals}
                        />

                    </div>
                </div>

                { this.state.isBusy && <PreloaderComponent/> }
            </div>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user,
            job: store.jobs.job,
        }
    }, {
        getUser: userActions.getUser,
        getUserJobById: jobActions.getUserJobById,
        updateUserJob: jobActions.updateUserJob
    }
)(withStyles(jobDetailsPageStyle)(JobDetailsPage));
