import { authHeader, postHeader, handleResponse } from "../helpers";
import { urlConstants } from "../constants";

export const feedbackService = {
  createFeedback,
  getUserJobFeedback
};

function createFeedback(feedback) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(feedback)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/feedback/create`,
    requestOptions
  ).then(handleResponse);
}

function getUserJobFeedback(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/feedback/by-id/` + id,
    requestOptions
  ).then(handleResponse);
}
