export function authHeader() {
  const token = "Bearer " + localStorage.getItem('token');
  if (localStorage.getItem('token')) {
    return {"Authorization": token};
  } else {
    return {};
  }
}

export function postHeader() {
  const token = "Bearer " + localStorage.getItem('token');
  if (localStorage.getItem('token')) {
    return {
      "Content-Type": "application/json",
      "Authorization": token
    };
  } else {
    return {};
  }
}
