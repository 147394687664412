/* eslint-disable */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import jobPostingPageStyle from "assets/jss/material-kit-pro-react/views/jobPostingPageStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../../actions";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";

class Step5Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            servicesString: '',
            percent: 10
        }
    }

    componentDidUpdate() {
        this.props.servicesStringCreation();
    }

    componentDidMount() {
        this.props.servicesStringCreation();
    }

    render() {
        const {classes} = this.props;
        return (
            <GridItem xs={12} sm={12} md={12}>

                <div className={
                    classes.marginBottom20
                }></div>

                <GridItem xs={12} sm={12} md={12}
                          className={
                              classes.textCenter
                          }
                >
                    <p
                        className={
                            classes.reviewTitle
                    }>Review and Update</p>
                </GridItem>

                <div className={
                    classes.marginBottom20
                }></div>

                <Card
                    className={classes.textCenter + " "
                    + classes.marginAuto + " "
                    + classes.cardWidth
                    }>
                    <CardBody>

                        <h3 className={classes.cardTitle}>
                            <p
                                className={classes.cardTitleText}
                            >Title</p>
                            <p
                                className={classes.cardTitleText}
                            >step 1 of 3</p>
                        </h3>

                        <div className={classes.follow + " "
                        + classes.top47
                        }>
                            <Tooltip
                                id="tooltip-top"
                                title="Edit 1st step"
                                placement="top"
                                classes={{tooltip: classes.tooltip}}
                            >
                                <Button
                                    justIcon
                                    round
                                    color="primary"
                                    onClick={e => this.props.changeStep(1)}
                                    className={classes.followButton}
                                >
                                    <Edit className={classes.followIcon}/>
                                </Button>
                            </Tooltip>
                        </div>

                        {
                            this.props.jobName &&

                            <GridItem xs={12} sm={12} md={12}>
                                <p className={
                                    classes.finalStepP
                                }>
                                    Job Name:
                                    <span className={
                                        classes.finalStepSpan
                                    }>
                                        {" " + this.props.jobName}
                                    </span>
                                </p>
                            </GridItem>
                        }

                        {
                            this.props.jobDescription &&

                            <GridItem xs={12} sm={12} md={12}>
                                <p className={
                                    classes.finalStepP
                                }>
                                    Job Description:
                                    <span className={
                                        classes.finalStepSpan
                                    }>
                                        {" " + this.props.jobDescription}
                                    </span>
                                </p>
                            </GridItem>
                        }

                        {
                            this.props.servicesString !== '' &&

                            <GridItem xs={12} sm={12} md={12}>
                                <div>
                                    <p className={
                                        classes.finalStepP
                                    }>
                                        Selected service types:
                                        <span className={
                                            classes.finalStepSpan
                                        }>
                                        {" " + this.props.servicesString}
                                    </span>
                                    </p>
                                </div>
                            </GridItem>
                        }
                        {
                            this.state.total !== '' &&

                            <GridItem xs={12} sm={12} md={12}>
                                <div>
                                    <p className={
                                        classes.finalStepP
                                    }>
                                        Total amount:
                                        <span className={
                                            classes.finalStepSpan
                                        }>{" "}
                                        £{(this.props.total + this.props.total * this.state.percent /100).toFixed(2)} 
                                    </span>
                                    </p>
                                </div>
                            </GridItem>
                        }
                        {
                            this.props.lawnMoving &&

                            <GridItem xs={12} sm={12} md={12}>
                                <div>
                                    {
                                        this.props.lawnSize &&
                                        <p className={
                                            classes.finalStepP
                                        }>
                                            Lawn Size:
                                            <span className={
                                                classes.finalStepSpan
                                            }>
                                        {" " + this.props.lawnSize + " sqm"}
                                    </span>
                                        </p>
                                    }
                                    {
                                        this.props.maintenanceFrequency &&
                                        <p className={
                                            classes.finalStepP
                                        }>
                                            Lawn Maintenance Frequency:
                                            <span className={
                                                classes.finalStepSpan
                                            }>
                                        {" " + this.props.maintenanceFrequency}
                                    </span>
                                        </p>
                                    }
                                </div>
                            </GridItem>
                        }
                        {this.props.hours && (
                            <GridItem xs={12} sm={12} md={12}>
                                {
                                    this.props.hours &&
                                    <p className={
                                        classes.finalStepP
                                    }>
                                        Hours:
                                        <span className={
                                            classes.finalStepSpan
                                        }>
                                    {" " + this.props.hours + " h"}
                                </span>
                                    </p>
                                }
                            </GridItem>
                        )}

                      {this.props.bags > 0 && (
                        <GridItem xs={12} sm={12} md={12}>
                          {
                            this.props.bags > 0 &&
                            <p className={
                              classes.finalStepP
                            }>
                              Bags:
                              <span className={
                                classes.finalStepSpan
                              }>
                                    {" " + this.props.bags}
                                </span>
                            </p>
                          }
                        </GridItem>
                      )}

                    </CardBody>
                </Card>

                <div className={
                    classes.marginBottom20
                }></div>

                <Card
                    className={classes.textCenter + " "
                    + classes.marginAuto + " "
                    + classes.cardWidth
                    }>
                    <CardBody>
                        <h3 className={classes.cardTitle}>
                            <p
                                className={classes.cardTitleText}
                            >Address</p>
                            <p
                                className={classes.cardTitleText}
                            >step 2 of 3</p>
                        </h3>

                        <div className={
                            classes.follow + " "
                            + classes.top47
                        }>
                            <Tooltip
                                id="tooltip-top"
                                title="Edit 2nd step"
                                placement="top"
                                onClick={e => this.props.changeStep(2)}
                                classes={{tooltip: classes.tooltip}}
                            >
                                <Button
                                    justIcon
                                    round
                                    color="primary"
                                    className={classes.followButton}
                                >
                                    <Edit className={classes.followIcon}/>
                                </Button>
                            </Tooltip>
                        </div>

                        {
                            this.props.dateTime &&
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={
                                    classes.finalStepP
                                }>
                                    Job date and time:
                                    <span className={
                                        classes.finalStepSpan
                                    }>
                                        {this.props.dateTimeEnd && this.props.dateTimeEnd !== ""
                                            ? " " +
                                            this.props.dateTime +
                                            " - " +
                                            this.props.dateTimeEnd
                                            : " " + this.props.dateTime}
                                    </span>
                                </p>
                            </GridItem>
                        }
                        {
                            this.props.city &&
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={
                                    classes.finalStepP
                                }>
                                    Job city:
                                    <span className={
                                        classes.finalStepSpan
                                    }>
                                        {" " + this.props.city}
                                    </span>
                                </p>
                            </GridItem>
                        }
                        {
                            this.props.zipCode &&
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={
                                    classes.finalStepP
                                }>
                                    Job postcode:
                                    <span className={
                                        classes.finalStepSpan
                                    }>
                                        {" " + this.props.zipCode}
                                    </span>
                                </p>
                            </GridItem>
                        }
                        {
                            this.props.address &&
                            <GridItem xs={12} sm={12} md={12}>
                                <p className={
                                    classes.finalStepP
                                }>
                                    Job address:
                                    <span className={
                                        classes.finalStepSpan
                                    }>
                                        {" " + this.props.address}
                                    </span>
                                </p>
                            </GridItem>
                        }
                    </CardBody>
                </Card>

                <div className={
                    classes.marginBottom20
                }></div>

                {/*<Card*/}
                    {/*className={classes.textCenter + " "*/}
                    {/*+ classes.marginAuto + " "*/}
                    {/*+ classes.cardWidth*/}
                    {/*}>*/}
                    {/*<CardBody>*/}
                        {/*<h3 className={classes.cardTitle}>*/}
                            {/*<p*/}
                                {/*className={classes.cardTitleText}*/}
                            {/*>Budget</p>*/}
                            {/*<p*/}
                                {/*className={classes.cardTitleText}*/}
                            {/*>step 3 of 3</p>*/}
                        {/*</h3>*/}

                        {/*<div className={classes.follow + " "*/}
                        {/*+ classes.top47*/}
                        {/*}>*/}
                            {/*<Tooltip*/}
                                {/*id="tooltip-top"*/}
                                {/*title="Edit 3rd step"*/}
                                {/*placement="top"*/}
                                {/*classes={{tooltip: classes.tooltip}}*/}
                            {/*>*/}
                                {/*<Button*/}
                                    {/*justIcon*/}
                                    {/*round*/}
                                    {/*color="primary"*/}
                                    {/*onClick={e => this.props.changeStep(3)}*/}
                                    {/*className={classes.followButton}*/}
                                {/*>*/}
                                    {/*<Edit className={classes.followIcon}/>*/}
                                {/*</Button>*/}
                            {/*</Tooltip>*/}
                        {/*</div>*/}

                        {/*<GridItem xs={12} sm={12} md={12}>*/}
                            {/*<p className={*/}
                                {/*classes.finalStepP*/}
                            {/*}>*/}
                                {/*Job min price:*/}
                                {/*<span className={*/}
                                    {/*classes.finalStepSpan*/}
                                {/*}>{" " + this.props.minPrice}</span>*/}
                            {/*</p>*/}
                            {/*<p className={*/}
                                {/*classes.finalStepP*/}
                            {/*}>*/}
                                {/*Job max price:*/}
                                {/*<span className={*/}
                                    {/*classes.finalStepSpan*/}
                                {/*}>{" " + this.props.maxPrice}</span>*/}
                            {/*</p>*/}
                        {/*</GridItem>*/}
                    {/*</CardBody>*/}
                {/*</Card>*/}

                <div className={
                    classes.marginBottom20
                }></div>

                <Card
                    className={classes.textCenter + " "
                    + classes.marginAuto + " "
                    + classes.cardWidth
                    }>
                    <CardBody>
                        <h3 className={classes.cardTitle}>
                            <p
                                className={classes.cardTitleText}
                            >Contact</p>
                            <p
                                className={classes.cardTitleText}
                            >step 3 of 3</p>
                        </h3>

                        <div className={classes.follow + " "
                        + classes.top47
                        }>
                            <Tooltip
                                id="tooltip-top"
                                title="Edit 3rd step"
                                placement="top"
                                classes={{tooltip: classes.tooltip}}
                            >
                                <Button
                                    justIcon
                                    round
                                    color="primary"
                                    onClick={e => this.props.changeStep(4)}
                                    className={classes.followButton}
                                >
                                    <Edit className={classes.followIcon}/>
                                </Button>
                            </Tooltip>
                        </div>

                        {
                            this.props.orderPhone &&

                            <GridItem xs={12} sm={12} md={12}>
                                <p className={
                                    classes.finalStepP
                                }>
                                    Contact phone:
                                    <span className={
                                        classes.finalStepSpan
                                    }>{" " + this.props.orderPhone}</span>
                                </p>
                            </GridItem>
                        }

                        {
                            this.props.orderBestTimeToCall &&

                            <GridItem xs={12} sm={12} md={12}>
                                <p className={
                                    classes.finalStepP
                                }>
                                    Best Time to Call:
                                    <span className={
                                        classes.finalStepSpan
                                    }>{" " + this.props.orderBestTimeToCall}</span>
                                </p>
                            </GridItem>
                        }
                    </CardBody>
                </Card>

                <GridItem xs={12} sm={12} md={12}
                          className={
                              classes.textCenter
                          }
                >
                    <Button
                        className={
                            classes.marginTop20 + " "
                            + classes.marginBottom20
                        }
                        onClick={e => this.props.updateJob(e)}
                        color="primary"
                    >Update a Job</Button>
                </GridItem>

            </GridItem>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user
        }
    }, {
        getUser: userActions.getUser
    }
)(withStyles(jobPostingPageStyle)(Step5Card));
