/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPoundSign } from "@fortawesome/free-solid-svg-icons";
import Time from "@material-ui/icons/AccessTime";
import Phone from "@material-ui/icons/LocalPhone";
import Event from "@material-ui/icons/Event";
import Home from "@material-ui/icons/Home";
import City from "@material-ui/icons/LocationCity";
import Code from "@material-ui/icons/Code";
import ZoomOutMap from "@material-ui/icons/ZoomOutMap";
import PreloaderComponent from "../../../components/PreloaderComponent/PreloaderComponent";

import "assets/css/style.css";

import jobDetailsPageStyle from "assets/jss/material-kit-pro-react/views/jobDetailsPageStyle.jsx";
import StarRatingComponent from "react-star-rating-component";
import "assets/css/style.css";
import classNames from "classnames";
import { connect } from "react-redux";
import { jobActions, userActions } from "../../../actions";
import { feedbackActions } from "../../../actions/feedback.actions";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

// eslint-disable-next-line react/prop-types

class JobContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: "",
      feedback: "",
      feedbackLoad: true,
      preloader: false,
      textValue: "",
      percent: 10,
      rating: 1
    };
  }
  payWork(id) {
    this.props.history.push("/payment-job/" + id);
  }

  acceptJob() {
    const jobObject = {
      contractorId: this.props.user._id,
      accept: true,
      status: "Active"
    };

    let jobId = this.props.match.params.id;

    this.props.updateContractorJob(jobId, jobObject).then(
      res => {
        this.props.history.push({
          pathname: "/my-contracts",
          state: {
            jobSuccess: "Job has been updated"
          }
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  handleChange = event => {
    this.setState({ textValue: event.target.value });
  };

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }

  leaveFeedback() {
    const feedback = {
      userId: this.props.user._id,
      jobId: this.state.job._id,
      comment: this.state.textValue,
      ratings: this.state.rating
    };

    this.props.leaveFeedback(feedback).then(
      res => {
        this.props.history.push("/customer-dashboard");
      },
      err => {
        console.log(err);
      }
    );
  }

  componentDidMount() {
    this.setState({ preloader: true });
    let jobId = this.props.match.params.id;
    if (jobId) {
      this.props.getJobById(jobId).then(
        res => {
          if (res.job.accept === true && this.props.user.account_type != 1) {
            this.props.history.push("/my-contracts");
          }
          this.setState({ job: res.job, preloader: false }, () => {
            //added service string after response server
            let tempString = "";
            if (this.state.job.lawnMoving) {
              tempString = tempString.concat(" Lawn Mowing, ");
            }
            if (this.state.job.maintainingHerbaceousBorders) {
              tempString = tempString.concat("Maintaining herbaceous borders, ");
            }
            if (this.state.job.gardenCoaching) {
              tempString = tempString.concat("Garden Coaching, ");
            }
            if (this.state.job.gardenClearance) {
              tempString = tempString.concat(" Garden Clearance, ");
            }
            if (this.state.job.hedgeTrimming) {
              tempString = tempString.concat(" Hedge Trimming, ");
            }
            if (this.state.job.pruning) {
              tempString = tempString.concat(" Pruning, ");
            }
            tempString = tempString.substr(0, tempString.length - 2);
            this.setState({ servicesString: tempString });
          });
        },
        err => {
          this.setState({ preloader: false });
        }
      );
    }

    this.props.findFeedback(jobId).then(
      res => {
        this.setState({
          feedback: res.feedback,
          feedbackLoad: false
        });
      },
      err => {}
    );
  }

  render() {
    const { rating } = this.state;
    const { classes, rest } = this.props;

    return (
      <div>
        <div
          className={classNames(
            classes.main + " fix-height-conteiner",
            classes.mainRaised + " m-header" + " content-center"
          )}
        >
          <div
            className={
              classes.container + " " + classes.customerDashboardContainer
            }
          >
            <GridContainer
              justify="center"
              className={classes.customerNameContainer + " m-cont"}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.customerName}
                >
                  <Card className={"cart-container-job-contractor"}>
                    <CardBody>
                      <h3 className={"v-j-variable job-page-title"}>
                        {this.state.job.jobName}
                      </h3>
                      <hr />
                      {this.state.job.jobDescription && (
                        <p className={"v-j-variable"}>
                          Description:
                          <span className={"v-j-variable-text"}>
                            {" " + this.state.job.jobDescription}
                          </span>
                        </p>
                      )}
                      {this.state.servicesString && (
                        <p className={"v-j-variable"}>
                          Selected service types:
                          <span className={"v-j-variable-text"}>
                            {" " + this.state.servicesString}
                          </span>
                        </p>
                      )}
                      {this.state.job.jobDescription && (
                        <p className={"v-j-variable"}>
                          {this.props.user.account_type === 2
                            ? "Client"
                            : "Contractor"}
                          <span className={"v-j-variable-text"}>
                            {this.props.user.account_type === 2 ? (
                              this.state.job.userId.first_name +
                              " " +
                              this.state.job.userId.last_name
                            ) : (
                              <Link
                                className={
                                  "job-link all-jobs-name info-contractor"
                                }
                                to={
                                  "/contractor-info/" +
                                  this.state.job.contractorId._id
                                }
                              >
                                {this.state.job.contractorId.first_name +
                                  " " +
                                  this.state.job.contractorId.last_name}
                              </Link>
                            )}
                          </span>
                        </p>
                      )}

                      <hr />
                      <div className={"job-page-first"}>
                        {this.state.job.dateTime && (
                          <p className={"v-j-variable"}>
                            <Event className={"img-page-job"} />
                            Date and time:
                            <span className={"v-j-variable-text"}>
                              {this.state.job.dateTimeEnd &&
                              this.state.job.dateTimeEnd !== ""
                                ? " " +
                                  this.state.job.dateTime +
                                  " - " +
                                  this.state.job.dateTimeEnd
                                : " " + this.state.job.dateTime}
                            </span>
                          </p>
                        )}
                        {this.state.job.lawnSize &&
                          this.state.job.lawnSize !== 0 && (
                            <p className={"v-j-variable"}>
                              <ZoomOutMap className={"img-page-job"} />
                              sqm:
                              <span className={"v-j-variable-text"}>
                                {" " + this.state.job.lawnSize + " sqm"}
                              </span>
                            </p>
                          )}
                        {this.state.job.hours && this.state.job.hours !== 0 && (
                          <p className={"v-j-variable"}>
                            <Time className={"img-page-job"} />
                            Hours:
                            <span className={"v-j-variable-text"}>
                              {" " + this.state.job.hours + " h"}
                            </span>
                          </p>
                        )}
                        {this.state.job.total && (
                          <p className={"v-j-variable"}>
                            <FontAwesomeIcon icon={faPoundSign} />
                            &nbsp;Price:
                            <span className={"v-j-variable-text"}>
                              {this.props.user.account_type === 1
                                ? this.state.job.total +
                                  (this.state.job.total * this.state.percent) /
                                    100
                                : this.state.job.total}
                            </span>
                          </p>
                        )}
                      </div>
                      <hr />
                      <div className={"job-page-first"}>
                        {this.state.job.city && (
                          <p className={"v-j-variable"}>
                            <City className={"img-page-job"} /> City:
                            <span className={"v-j-variable-text"}>
                              {" " + this.state.job.city}
                            </span>
                          </p>
                        )}

                        {this.state.job.zipCode && (
                          <p className={"v-j-variable"}>
                            <Code className={"img-page-job"} /> Postcode:
                            <span className={"v-j-variable-text"}>
                              {" " + this.state.job.zipCode}
                            </span>
                          </p>
                        )}
                        {this.state.job.address && (
                          <p className={"v-j-variable"}>
                            <Home className={"img-page-job"} /> Address:
                            <span className={"v-j-variable-text"}>
                              {" " + this.state.job.address}
                            </span>
                          </p>
                        )}
                      </div>
                      <hr />
                      <div className={"job-page-first"}>
                        {this.state.job.orderPhone && (
                          <p className={"v-j-variable"}>
                            <Phone className={"img-page-job"} /> Contact phone:
                            <span className={"v-j-variable-text"}>
                              {" " + this.state.job.orderPhone}
                            </span>
                          </p>
                        )}
                        {this.state.job.orderBestTimeToCall && (
                          <p className={"v-j-variable"}>
                            <Phone className={"img-page-job"} /> Best Time to
                            Call:
                            <span className={"v-j-variable-text"}>
                              {" " + this.state.job.orderBestTimeToCall}
                            </span>
                          </p>
                        )}
                      </div>
                      {this.props.user.account_type === 2 ? <hr /> : null}
                      <div className={"job-page-btn-and-proposol"}>
                        {this.props.user.account_type === 2 ? (
                          <Button
                            color="success"
                            className={"btn-center-job"}
                            type={"submit"}
                            onClick={e => this.acceptJob(e)}
                          >
                            Accept a job
                          </Button>
                        ) : null}
                      </div>
                      {this.state.job.status === "Pending approval" ? (
                        <div>
                          <hr />
                          <Button
                            className={"btn-submit-work-client"}
                            color={"success"}
                            onClick={e => this.payWork(this.state.job._id)}
                          >
                            View Payment Request
                          </Button>
                        </div>
                      ) : null}

                      {!this.state.feedbackLoad &&
                      this.props.user.account_type === 1 &&
                      this.state.job.status === "Completed" ? (
                        this.state.feedback ? (
                          <div>
                            <hr />
                            <div>
                              <h3 className={"v-j-variable"}>
                                Rating: {this.state.feedback.ratings}
                              </h3>
                              <StarRatingComponent
                                name="rate1"
                                starCount={5}
                                editing={false}
                                emptyStarColor={"#EDECEC"}
                                value={this.state.feedback.ratings}
                                onStarClick={this.onStarClick.bind(this)}
                              />
                            </div>
                            {this.state.feedback.comment ? (
                              <div>
                                <p className={"v-j-variable"}>Your feedback</p>
                                <p className={"v-j-variable approval-font-100"}>
                                  {this.state.feedback.comment}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div>
                            <hr />
                            <div>
                              <h3 className={"v-j-variable"}>
                                Rating: {rating}
                              </h3>
                              <StarRatingComponent
                                name="rate1"
                                starCount={5}
                                value={rating}
                                emptyStarColor={"#EDECEC"}
                                onStarClick={this.onStarClick.bind(this)}
                              />
                            </div>
                            <div className={"v-j-variable"}>
                              Leave a feedback
                            </div>
                            <textarea
                              className={"submit-work-textarea"}
                              value={this.state.textValue}
                              onChange={this.handleChange}
                            />
                            <Button
                              className={"btn-submit-work"}
                              color={"success"}
                              type={"submit"}
                              onClick={e => this.leaveFeedback(e)}
                            >
                              Send
                            </Button>
                          </div>
                        )
                      ) : null}
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </div>
        </div>
        
        {this.state.preloader && <PreloaderComponent />}
      </div>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    getJobById: jobActions.getJobById,
    updateContractorJob: jobActions.updateContractorJob,
    cancelContractorJob: jobActions.cancelContractorJob,
    leaveFeedback: feedbackActions.leaveFeedback,
    findFeedback: feedbackActions.findFeedback
  }
)(withStyles(jobDetailsPageStyle)(JobContractor));
