import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import photoPreloaderComponentStyle
    from "../../assets/jss/material-kit-pro-react/components/photoPreloaderComponentStyle";

const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
});

function PhotoPreloaderComponent(props) {
    const { classes } = props;
    return (
        <div
            className={
                classes.wrapper
            }
        >
            <CircularProgress className={classes.progress} color="primary" />
        </div>
    );
}

PhotoPreloaderComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(photoPreloaderComponentStyle)(PhotoPreloaderComponent);