import { authHeader, postHeader, history, handleResponse } from "../helpers";
import { urlConstants } from "../constants";

export const proposalService = {
  createProposal,
  getJobsPaginated
};

function createProposal(proposal) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(proposal)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/proposal/create`,
    requestOptions
  ).then(handleResponse);
}

function getJobsPaginated(page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/proposal/get-all-proposals/` + page,
    requestOptions
  ).then(handleResponse);
}
