/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";
import { urlConstants } from "../../../constants/api-url";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Money from "@material-ui/icons/AttachMoney";
import Time from "@material-ui/icons/AccessTime";
import ZoomOutMap from "@material-ui/icons/ZoomOutMap";
import Phone from "@material-ui/icons/LocalPhone";
import Home from "@material-ui/icons/Home";
import City from "@material-ui/icons/LocationCity";
import StarRatingComponent from "react-star-rating-component";
import Code from "@material-ui/icons/Code";
import PreloaderComponent from "../../../components/PreloaderComponent/PreloaderComponent";

import "assets/css/style.css";

import jobDetailsPageStyle from "assets/jss/material-kit-pro-react/views/jobDetailsPageStyle.jsx";
import "assets/css/style.css";
import classNames from "classnames";
import { connect } from "react-redux";
import { jobActions } from "../../../actions";
import { feedbackActions } from "../../../actions/feedback.actions";
import Priority from "@material-ui/core/SvgIcon/SvgIcon";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";

// eslint-disable-next-line react/prop-types

class MyContractorJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: "",
      feedback: "",
      servicesString: "",
      feedbackLoad: true,
      rating: 1,
      preloader: false,
      percent: 10,
      Error: false,
      ErrorMessage: ''
    };
  }

  submitWork = id => {
    this.props.history.push("/complete-work/" + id);
  };

  cancelWork = id => {
    const jobObject = {
      accept: false,
      contractorId: null
    };

    this.props.cancelContractorJob(id, jobObject).then(
      res => {
        this.props.history.push({
          pathname: "/my-contracts",
          state: {
            jobSuccess: "Job has been updated"
          }
        });
      },
      err => {
        console.log(err);
        this.setState({ preloader: false, Error: true, ErrorMessage: err});
      }
    );
  };

  submitProposalForm = e => {
    e.preventDefault();

    const jobObject = {
      accept: false
    };

    let jobId = this.props.match.params.id;

    this.props.updateContractorJob(jobId, jobObject).then(
      res => {
        this.props.history.push({
          pathname: "/my-contracts",
          state: {
            jobSuccess: "Job has been updated"
          }
        });
      },
      err => {
        console.log(err);
      }
    );
  };

  componentDidMount() {
    this.setState({ preloader: true });
    let jobId = this.props.match.params.id;
    if (jobId) {
      this.props.getJobById(jobId).then(
        res => {
          this.setState({ job: res.job, preloader: false }, () => {
            this.getServicesString();
          });
        },
        err => {
          this.setState({ preloader: false });
        }
      );
    }
    this.props.findFeedback(jobId).then(
      res => {
        this.setState({
          feedback: res.feedback,
          feedbackLoad: false
        });
      },
      err => {}
    );
  }
  submitWork = id => {
    this.props.history.push("/complete-work/" + id);
  };

  getServicesString = () => {
    let tempString = "";
    if (this.state.job.lawnMoving) {
      tempString = tempString.concat(" Lawn Mowing, ");
    }
    if (this.state.job.maintainingHerbaceousBorders) {
      tempString = tempString.concat(" Maintaining herbaceous borders, ");
    }
    if (this.state.job.gardenCoaching) {
      tempString = tempString.concat(" Garden Coaching, ");
    }
    if (this.state.job.gardenClearance) {
      tempString = tempString.concat(" Garden Clearance, ");
    }
    if (this.state.job.hedgeTrimming) {
      tempString = tempString.concat(" Hedge Trimming, ");
    }
    if (this.state.job.pruning) {
      tempString = tempString.concat(" Pruning, ");
    }
    tempString = tempString.substr(0, tempString.length - 2);
    this.setState({ servicesString: tempString });
  };

  closeAlerts = () => {
    const state = { ...this.props.location.state };
    this.setState({ Error: false });
  };

  render() {
    const { classes, rest } = this.props;
    const { rating } = this.state;
    return (
      <div>
        <div
          className={classNames(
            classes.main + " fix-height-conteiner",
            classes.mainRaised + " m-header" + " content-center"
          )}
        >
          <div
            className={
              classes.container + " " + classes.customerDashboardContainer
            }
          >
            {this.state.Error ? (
              <div>
                <SnackbarContent
                  message={<span>{this.state.ErrorMessage}</span>}
                  close
                  color="danger"
                  icon={Priority}
                  closeAlert={this.closeAlerts}
                />
              </div>
            ) : null}
            <GridContainer
              justify="center"
              className={classes.customerNameContainer + " m-cont"}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.customerName}
                >
                  <Card className={"cart-container-job-contractor"}>
                    <CardBody>
                      <h3 className={"v-j-variable job-page-title"}>
                        {this.state.job.jobName}
                      </h3>
                      <hr />
                      <form className={""} onSubmit={this.submitProposalForm}>
                        {this.state.job.jobDescription && (
                          <p className={"v-j-variable"}>
                            Description:
                            <span className={"v-j-variable-text"}>
                              {" " + this.state.job.jobDescription}
                            </span>
                          </p>
                        )}

                        {this.state.servicesString && (
                          <p className={"v-j-variable"}>
                            Selected service types:
                            <span className={"v-j-variable-text"}>
                              {" " + this.state.servicesString}
                            </span>
                          </p>
                        )}

                        {this.state.job.userId && (
                          <p className={"v-j-variable"}>
                            {this.props.user.account_type === 2
                              ? "Client"
                              : "Contractor"}
                            <span className={"v-j-variable-text"}>
                              {this.props.user.account_type === 2
                                ? this.state.job.userId.first_name +
                                  " " +
                                  this.state.job.userId.last_name
                                : this.state.job.contractorId.first_name +
                                  " " +
                                  this.state.job.contractorId.last_name}
                            </span>
                          </p>
                        )}
                        <hr />
                        <div className={"job-page-first"}>
                          {this.state.job.dateTime && (
                            <p className={"v-j-variable"}>
                              <Time className={"img-page-job"} /> Date and time:
                              <span className={"v-j-variable-text"}>
                                {this.state.job.dateTimeEnd &&
                                this.state.job.dateTimeEnd !== ""
                                  ? " " +
                                    this.state.job.dateTime +
                                    " - " +
                                    this.state.job.dateTimeEnd
                                  : " " + this.state.job.dateTime}
                              </span>
                            </p>
                          )}
                          {this.state.job.lawnSize &&
                            this.state.job.lawnSize !== 0 && (
                              <p className={"v-j-variable"}>
                                <ZoomOutMap className={"img-page-job"} />
                                sqm:
                                <span className={"v-j-variable-text"}>
                                  {" " + this.state.job.lawnSize + " sqm"}
                                </span>
                              </p>
                            )}
                          {this.state.job.hours && this.state.job.hours !== 0 && (
                            <p className={"v-j-variable"}>
                              <Time className={"img-page-job"} />
                              Hours:
                              <span className={"v-j-variable-text"}>
                                {" " + this.state.job.hours + " h"}
                              </span>
                            </p>
                          )}
                          {this.state.job.total && (
                            <p className={"v-j-variable"}>
                              <Money className={"img-page-job"} />
                              Price:
                              <span className={"v-j-variable-text"}>
                                {this.state.job.total}
                              </span>
                            </p>
                          )}
                        </div>
                        <hr />
                        <div className={"job-page-first"}>
                          {this.state.job.city && (
                            <p className={"v-j-variable"}>
                              <City className={"img-page-job"} /> City:
                              <span className={"v-j-variable-text"}>
                                {" " + this.state.job.city}
                              </span>
                            </p>
                          )}

                          {this.state.job.zipCode && (
                            <p className={"v-j-variable"}>
                              <Code className={"img-page-job"} /> Postcode:
                              <span className={"v-j-variable-text"}>
                                {" " + this.state.job.zipCode}
                              </span>
                            </p>
                          )}
                          {this.state.job.address && (
                            <p className={"v-j-variable"}>
                              <Home className={"img-page-job"} /> Address:
                              <span className={"v-j-variable-text"}>
                                {" " + this.state.job.address}
                              </span>
                            </p>
                          )}
                        </div>
                        <hr />
                        <div className={"job-page-first"}>
                          {this.state.job.orderPhone && (
                            <p className={"v-j-variable"}>
                              <Phone className={"img-page-job"} /> Contact
                              phone:
                              <span className={"v-j-variable-text"}>
                                {" " + this.state.job.orderPhone}
                              </span>
                            </p>
                          )}
                          {this.state.job.orderBestTimeToCall && (
                            <p className={"v-j-variable"}>
                              <Phone className={"img-page-job"} /> Best Time to
                              Call:
                              <span className={"v-j-variable-text"}>
                                {" " + this.state.job.orderBestTimeToCall}
                              </span>
                            </p>
                          )}
                        </div>

                        {this.state.job.status === "Active" ? (
                          <div>
                            <hr />
                            <div className={"job-page-btn-and-proposol"}>
                              <Button
                                color="danger"
                                className={"btn-center-job"}
                                onClick={e =>
                                  this.cancelWork(this.state.job._id)
                                }
                              >
                                Cancel a job
                              </Button>
                              <Button
                                className={"btn-submit-work"}
                                color={"success"}
                                onClick={e =>
                                  this.submitWork(this.state.job._id)
                                }
                              >
                                Submit work
                              </Button>
                            </div>
                          </div>
                        ) : null}

                        {!this.state.feedbackLoad &&
                        this.state.job.status === "Completed" ? (
                          this.state.feedback ? (
                            <div>
                              <hr />
                              <div>
                                <h3 className={"v-j-variable"}>
                                  Rating: {this.state.feedback.ratings}
                                </h3>
                                <StarRatingComponent
                                  name="rate1"
                                  starCount={5}
                                  editing={false}
                                  emptyStarColor={"#EDECEC"}
                                  value={this.state.feedback.ratings}
                                />
                              </div>
                              {this.state.feedback.comment ? (
                                <div>
                                  <p className={"v-j-variable"}>
                                    Сlient's feedback
                                  </p>
                                  <p
                                    className={"v-j-variable approval-font-100"}
                                  >
                                    {this.state.feedback.comment}
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            <div></div>
                          )
                        ) : null}
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </div>
        </div>
        {this.state.preloader && <PreloaderComponent />}
      </div>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    getJobById: jobActions.getJobById,
    updateContractorJob: jobActions.updateContractorJob,
    cancelContractorJob: jobActions.cancelContractorJob,
    findFeedback: feedbackActions.findFeedback
  }
)(withStyles(jobDetailsPageStyle)(MyContractorJob));
