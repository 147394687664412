import {
  container,
  description,
  cardTitle,
  whiteColor,
  grayColor,
  successColor,
} from "assets/jss/material-kit-pro-react.jsx";

import customCheckboxRadioSwitch from "../customCheckboxRadioSwitchStyle";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

const signupPageStyle = theme => ({
  ...customCheckboxRadioSwitch,
  ...modalStyle,
  description,
  cardTitle: {
    ...cardTitle,
    color: whiteColor + "  !important"
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "50px"
    },
    "@media (max-width: 992px)": {
      paddingBottom: "0px",
    }
  },
  pageHeader: {
    color: whiteColor,
    border: "0",
    height: "100%",
    margin: "0",
    display: "block!important",
    padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    alignItems: "center",
    "&:before": {
      background: "rgba(45, 84, 47, 0.71) !important"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    },
    "@media (max-width: 992px)": {
      paddingTop: "100px",
      paddingBottom: "0px !important",
      // minHeight: "100%",
    }
  },
  form: {
    margin: "0"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: grayColor[13]
  },
  textCenter: {
    textAlign: "center"
  },
  iconButtons: {
    marginRight: "3px !important",
    marginLeft: "3px !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  blockList: {
    display: "block !important",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important"
    }
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important"
    }
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  footer: {
    position: "absolute",
    width: "100%",
    background: "transparent",
    bottom: "0",
    color: whiteColor,
    zIndex: "2",
    "@media (max-width: 992px)": {
      position: "relative",
    }
  },
  marginBottom: {
    marginBottom: "10px",
  },
  contentCenter: {
    margin: "0 auto",
    display: "table",
    "& > a": {
      color: successColor[0]
    }
  },
  loginButton: {
    border: "1px solid",
    borderColor: successColor[0],
    lineHeight: "0.333",
  },
  greenText: {
    color: successColor[0],
  },
  inlineUl: {
    display: "inline-block",
    "& li": {
      paddingTop: "0px",
      paddingBottom: "0px"
    },
    "& li a": {
      paddingTop: "0px",
      paddingBottom: "0px"
    },
  },
  height76vh: {
    "@media (min-width: 992px)": {
      height: "76vh",
    }
  },
  transform20: {
    "@media (min-width: 992px)": {
      transform: "translate(0%, 20%)"
    }
  },
  cardHeaderText: {
    fontSize: "30px",
    fontWeight: '700'
  },
  padding30: {
    paddingRight: "30px",
    paddingLeft: "30px"
  },
  marginBottom20: {
    marginBottom: "20px"
  },
  marginBottom25: {
    marginBottom: "25px"
  },
  marginTop20: {
    marginTop: "20px"
  },
  marginTop10: {
    marginTop: "10px"
  },
  font700: {
    fontWeight: "700"
  },
  errorMessage: {
    color: "red",
    width: "100%",
    textAlign: "center"
  },
  displayNone: {
    display: "none"
  },
  displayBlock: {
    display: "block"
  },
  iPadScreen: {
    "@media (min-device-width: 768px) and (min-device-height: 1024px)": {
        height: "70vh",
        transform: "translate(0%, 20%)"
    },

    "@media (min-device-width: 1024px) and (min-device-height: 768px)": {
      height: "30vh !important",
    }
  },
  iPadScreenHeader: {
    "@media (min-device-width: 1024px) and (min-device-height: 768px)": {
      paddingTop: "20px"
    }
  },
  pixel2XL: {
    "@media (min-device-width: 411px) and (min-device-height: 823px)": {
      marginBottom: "90px"
    }
  }
});

export default signupPageStyle;
