/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Check from "@material-ui/icons/Check";

import CardBody from "components/Card/CardBody.jsx";
import Pagination from "components/Pagination/PaginationComponent";
import { Link } from "react-router-dom";
import SmallPreloaderComponent from "../../../components/PreloaderComponent/SmallPreloaderComponent";
import Button from "components/CustomButtons/Button.jsx";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import "assets/css/style.css";

import jobDetailsPageStyle from "assets/jss/material-kit-pro-react/views/jobDetailsPageStyle.jsx";
import MyContractAccordions from "./AccordionsCardMyContract";

class MyContractorJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        resizeOption: window.innerWidth > 480
    };
  }

  handlePageChange = pageNumber => {
    this.props.changeActivePage(pageNumber);
    this.props.call(pageNumber);
  };

    componentDidMount() {
        window.addEventListener('resize', () => {
            if (window.innerWidth < 480) {
                this.setState({resizeOption: false});
            } else {
                this.setState({resizeOption: true});
            }
        })
    };

  render() {
    const { classes, jobs, jobsLoading } = this.props;
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <h3 className={classes.cardTitle + " all-jobs-height"}>
              <p
                className={
                  classes.cardTitleText +
                  " " +
                  classes.customerCardTitle +
                  " all-jobs-height" +
                  " title-pages"
                }
              >
                My Contracts
              </p>
            </h3>
            {jobsLoading && <SmallPreloaderComponent />}
            {jobs.length > 0 && (
              <div className={"table-div"}>
                  { this.state.resizeOption === true
                      ? (
                          <table className={"table-contracts"}>
                              <thead className={"v-j-variable"}>
                              <tr>
                                  <th className={"table-th"}>Job</th>
                                  <th className={"table-th"}>Client</th>
                                  <th className={"table-th"}>Date</th>
                                  <th className={"table-th"}>Status</th>
                                  <th className={"table-th status-work-style"}>Status Work</th>
                              </tr>
                              </thead>
                              <tbody>
                              {jobs.map(job => (
                                  <tr key={job._id} className={"table-tr"}>
                                      <td className={"table-td"}>
                                          <Link
                                              className={
                                                  "job-link all-jobs-name job-title job-fix-wiight"
                                              }
                                              to={"/my-contracts/job/" + job._id}
                                          >
                                              {job.jobName}
                                          </Link>
                                      </td>
                                      <td className={"table-td"}>
                                          {job.userId.first_name} {job.userId.last_name}
                                      </td>
                                      <td className={"table-td"}>{job.dateTime}</td>
                                      <td className={"table-td"}>{job.status}</td>

                                      <td className={"table-td tb-submit-work status-work-style"}>
                                          {job.status === "Active" ? (
                                              <Button
                                                  className={"btn-submit-work"}
                                                  color={"success"}
                                                  onClick={e => this.props.submitWork(job._id)}
                                              >
                                                  Submit work
                                              </Button>
                                          ) : (
                                              <Check className={"verifide-color"} />
                                          )}
                                      </td>
                                  </tr>
                              ))
                              }
                              </tbody>
                          </table>
                      ) :
                      <MyContractAccordions
                          submitWork={this.props.submitWork}
                          jobs={jobs}
                          classes={classes}
                      />
                  }
              </div>
            )}
            {!jobsLoading && !jobs.length &&(
              <h4 className={"empty-msg"}>No contracts yet...</h4>
            )}

            {jobs.length !== 0 && (
              <div className={"pagination-container-hire"}>
                <Pagination
                  activePage={this.props.activePage}
                  totalItemsCount={this.props.jobCount} 
                  onChange={this.handlePageChange}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

export default withStyles(jobDetailsPageStyle)(MyContractorJobs);
