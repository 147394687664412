import React from "react";
import {urlConstants} from "../../constants/api-url";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import { Link } from "react-router-dom";
import { accountTypeConstants } from "../../constants/acoount-types";
import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent";

class LoginPage extends React.Component {
	
  constructor(props) {
    super(props);
	if(props.user){
		props.user.account_type == accountTypeConstants.customer
            ? this.props.history.push("/customer-dashboard")
            : this.props.history.push("/contractor-all-jobs");
	}
    this.state = {
      checked: [21, 22],
      email: "",
      emailError: false,
      emailErrorMessage: "",
      password: "",
      passwordError: false,
      passwordErrorMessage: "",
      userLogin: false,
      modalTitle: "",
      modalText: "",
      loginDisabled: false,
      loginError: false
    };
  }

  componentDidMount() {    
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  handleChange(event, name) {
    this.setState({ loginError: false });
    this.setState({ [name]: event.target.value });
    switch (name) {
      case "email":
        let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(event.target.value)) {
          this.setState({ [name + "Error"]: false });
        } else {
          this.setState({ [name + "Error"]: true });
          this.setState({ [name + "ErrorMessage"]: "Email is not valid" });
        }
        break;
      case "password":
        if (event.target.value.length < 6 && name === "password") {
          this.setState({ ["passwordError"]: true });
          this.setState({
            ["passwordErrorMessage"]: "Password too short"
          });
        } else {
          this.setState({ [name + "Error"]: false });
        }
        break;

      default:
        break;
    }
  }

  handleBlur(event, name) {}

  handleClickOpen(modal, title, text) {
    var x = [];
    x[modal] = true;
    this.setState(x);
    this.setState({ modalTitle: title });
    this.setState({ modalText: text });
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleClick() {
    if (this.state.email === "") {
      this.setState({ emailError: true });
      this.setState({ ["emailErrorMessage"]: "Email is mandatory field" });
    }
    if (this.state.password === "") {
      this.setState({ passwordError: true });
      this.setState({
        ["passwordErrorMessage"]: "Password is mandatory field"
      });
    }

    if (
      this.state.email !== "" &&
      this.state.password !== "" &&
      !this.state.emailError &&
      !this.state.passwordError
    ) {
      this.setState({ loginDisabled: true });
      this.props.login(this.state.email, this.state.password).then(
        res => {
          this.setState({ loginDisabled: false });
          const userRole = this.props.user.account_type;

          userRole === accountTypeConstants.customer
            ? this.props.history.push("/customer-dashboard")
            // : this.props.history.push("/" + "contractor-page");
            : this.props.history.push("/contractor-all-jobs");
        },
        err => {
          this.setState({ loginDisabled: false });
          this.setState({ loginError: true });
        }
      );
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleClick();
    }
  }
  

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          absolute
			logoLink = {urlConstants.LANDING_URL}
          color="transparent"
          brand="TEC MOW"
          links={<HeaderLinks dropdownHoverColor="info" />}
        />
        <div className={classes.pageHeader + " " + classes.iPadScreenHeader}>
          <div className={classes.container + " " + classes.iPadScreen}>
            <GridContainer justify="center" className={classes.height76vh}>
              <GridItem xs={12} sm={12} md={4} className={classes.transform20}>
                <Card className={classes.pixel2XL}>
                  <form className={classes.form} onKeyDown={this.handleKeyDown}>
                    <CardHeader
                      color="primary"
                      signup
                      className={classes.cardHeader}
                    >
                      <h4
                        className={
                          classes.cardTitle + " " + classes.cardHeaderText
                        }
                      >
                        Login Page
                      </h4>
                    </CardHeader>
                    <CardBody signup>
                      <CustomInput
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Email...",
                          type: "email",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          onChange: e => this.handleChange(e, "email"),
                          onBlur: e => this.handleBlur(e, "email"),
                          error: this.state.emailError,
                          errormessage: this.state.emailErrorMessage
                        }}
                      />
                      <CustomInput
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Password",
                          type: "password",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
                          ),
                          onChange: e => this.handleChange(e, "password"),
                          onBlur: e => this.handleBlur(e, "password"),
                          error: this.state.passwordError,
                          errormessage: this.state.passwordErrorMessage
                        }}
                      />
                    </CardBody>
                    <div
                      className={classes.container + " " + classes.padding30}
                    >
                      <div className={classes.ligthGreenText}>
                        <span>Forgot password? Click </span>
                        <Link
                          to={"/forgot-password"}
                          className={classes.font700}
                        >
                          here
                        </Link>
                      </div>
                    </div>
                    <div
                      className={
                        classes.textCenter +
                        " " +
                        classes.marginTop10 +
                        " " +
                        classes.font700 +
                        " " +
                        (this.state.loginError
                          ? classes.displayBlock
                          : classes.displayNone)
                      }
                    >
                      <span className={classes.errorMessage}>
                        Invalid credentials
                      </span>
                    </div>
                    <div
                      className={
                        classes.textCenter +
                        " " +
                        classes.marginTop20 +
                        " " +
                        classes.marginBottom20 +
                        " " +
                        classes.marginBottom25
                      }
                    >
                      <Button
                        simple
                        color="success"
                        size="lg"
                        className={classes.loginButton}
                        onClick={e => this.handleClick()}
                        disabled={this.state.loginDisabled}
                      >
                        Login
                      </Button>
                    </div>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer className={classes.footer} content={<div />} />
        </div>

        {this.state.loginDisabled && <PreloaderComponent />}
      </div>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    login: userActions.login
  }
)(withStyles(loginPageStyle)(LoginPage));
