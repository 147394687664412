/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Money from "@material-ui/icons/AttachMoney";
import Time from "@material-ui/icons/AccessTime";
import Home from "@material-ui/icons/Home";
import City from "@material-ui/icons/LocationCity";
import Code from "@material-ui/icons/Code";
import ShowMore from "react-show-more";
import "assets/css/style.css";
import jobDetailsPageStyle from "assets/jss/material-kit-pro-react/views/jobDetailsPageStyle.jsx";
import "assets/css/style.css";
import { connect } from "react-redux";
import { jobActions, userActions } from "../../../actions";
// eslint-disable-next-line react/prop-types

class ProposalJobDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: "",
      preloader: false,
      isAccordion: false
    };
  }

  componentDidMount() {}

  onAccordion = () => {
    this.setState({ isAccordion: !this.state.isAccordion });
  };

  render() {
    const { classes, rest } = this.props;
    return (
      <GridItem xs={12} sm={12} md={12} className={classes.customerName}>
        <Card className={"cart-container-job-contractor-detail"}>
          <CardBody>
            <h3 className={"v-j-variable job-page-title"}>Job details</h3>
            <hr />
            <form className={""} onSubmit={this.submitProposalForm}>
              <div className={"job-detail-proposal"}>
                <div className={"job-detail-proposal-descr"}>
                  {this.props.job.jobName && (
                    <p className={"v-j-variable job-page-title"}>
                      {this.props.job.jobName}
                    </p>
                  )}
                  {this.props.job.jobDescription && (
                    <span className={"v-j-variable-text desc-no-padding"}>
                      <ShowMore
                        lines={2}
                        more={
                          <div id={"re-show-more"} onClick={this.onAccordion}>
                            Show more
                          </div>
                        }
                        less={<div onClick={this.onAccordion}>Show less</div>}
                        anchorClass="react-show-more"
                      >
                        <div>{this.props.job.jobDescription}</div>
                      </ShowMore>
                    </span>
                  )}
                </div>
                <div className={"job-page-first ext-class"}>
                  <span className={"line"}>
                    {this.props.job.dateTime && (
                      <p className={"v-j-variable"}>
                        <Time className={"img-page-job"} />
                        <span className={"v-j-variable-text"}>
                          {" " + this.props.job.dateTime}
                        </span>
                      </p>
                    )}
                    {this.props.job.city && (
                      <p className={"v-j-variable"}>
                        <City className={"img-page-job"} />
                        <span className={"v-j-variable-text"}>
                          {" " + this.props.job.city}
                        </span>
                      </p>
                    )}
                    {this.props.job.minPrice && (
                      <p className={"v-j-variable"}>
                        <Money className={"img-page-job"} />
                        <span className={"v-j-variable-text"}>
                          {" " +
                            this.props.job.minPrice +
                            "-" +
                            this.props.job.maxPrice}
                        </span>
                      </p>
                    )}
                    {this.props.job.address && (
                      <p className={"v-j-variable"}>
                        <Home className={"img-page-job"} />
                        <span className={"v-j-variable-text"}>
                          {" " + this.props.job.address}
                        </span>
                      </p>
                    )}
                  </span>
                </div>
              </div>

              {this.props.job.servicesString && (
                <p className={"v-j-variable"}>
                  Selected service types:
                  <span className={"v-j-variable-text"}>
                    {" " + this.props.job.servicesString}
                  </span>
                </p>
              )}

              <span className={this.state.viewJobPosting}>
                <div className={"job-page-first"}>
                  {this.state.job.zipCode && (
                    <p className={"v-j-variable"}>
                      <Code className={"img-page-job"} /> Postcode:
                      <span className={"v-j-variable-text"}>
                        {" " + this.state.job.zipCode}
                      </span>
                    </p>
                  )}
                </div>
                <hr />
                <div className={"job-page-first"} />
              </span>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    getJobById: jobActions.getJobById
  }
)(withStyles(jobDetailsPageStyle)(ProposalJobDetail));
