import {
    grayColor,
    container,
    cardTitle,
    whiteColor,
    blackColor,
    hexToRgb,
} from "assets/jss/material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";

const calculationPageStyle = {
    container: {
        ...container,
        zIndex: "2",
        position: "relative",
        paddingTop: "20vh",
        color: whiteColor,
    },
    pageHeader: {
        minHeight: "100vh",
        height: "auto",
        display: "inherit",
        position: "relative",
        margin: "0",
        padding: "0",
        border: "0",
        alignItems: "center",
        "&:before": {
            background: "rgba(45, 84, 47, 0.71) !important"
        },
        "&:before,&:after": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: '""'
        }
    },
    cardSignup: {
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(" +
            hexToRgb(blackColor) +
            ", 0.14), 0 6px 30px 5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
            hexToRgb(blackColor) +
            ", 0.2);",
        marginBottom: "100px",
        padding: "40px 0px",
        paddingTop: "0px"
    },
    cardTitle: {
        ...cardTitle,
        textDecoration: "none",
        textAlign: "center !important",
        marginBottom: "0.75rem",
        marginTop: "0px !important",
        paddingTop: "20px",
        paddingBottom: "20px",
        boxShadow: "0 12px 20px -10px rgba(182, 215, 168, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(182, 215, 168, 0.2)"
    },
    ...customCheckboxRadioSwitchStyle,
    ...customSelectStyle,
    socials: {
        marginTop: "0",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px"
    },
    textCenter: {
        textAlign: "center"
    },
    inputAdornment: {
        marginRight: "18px",
        position: "relative"
    },
    inputAdornmentIcon: {
        color: grayColor[13]
    },
    form: {
        margin: "0"
    },
    infoArea: {
        padding: "0px 0px 20px !important"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block",
        "&,& *,& *:hover,& *:focus": {
            color: whiteColor + "  !important"
        }
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right",
        "&,& *,& *:hover,& *:focus": {
            color: whiteColor + "  !important"
        }
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
    inlineBlockSelect: {
        display: "inline-block"
    },
    blockSelect: {
        display: "block"
    },
    noLeftMargin: {
        marginLeft: "0px !important"
    },
    marginTop30: {
        marginTop: "30px"
    },
    marginTop50: {
        marginTop: "50px"
    },
    marginBottom50: {
        marginBottom: "50px !important"
    },
    width90: {
        width: "90% !important"
    },
    inlineText: {
        display: "inline-flex",
        paddingTop: "27px",
        margin: "0 0 17px 0",
        flexDirection: "column",
        verticalAlign: "text-bottom",
        height: "59px",
        marginBottom: "0",
        justifyContent: "flex-end"
    },
    paddingRight10: {
        paddingRight: "10px"
    },
    paddingLeft15: {
        paddingLeft: "15px"
    },
    marginBottom0: {
        marginBottom: "0px"
    },
    displayBlock: {
        display: 'block'
    },
    displayInlineBlock: {
      display: 'inline-block'
    },
    width48: {
        width: '48%'
    },
    marginTop20: {
        marginTop: "20px"
    },
    font700: {
        fontWeight: "700"
    },
    font20: {
        fontSize: "20px"
    },
    marginTop25: {
        marginTop: "25px"
    },
};

export default calculationPageStyle;
