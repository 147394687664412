import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiAccordion from "@material-ui/core/ExpansionPanel";
import MuiAccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiAccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Button from "../../../components/CustomButtons/Button";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VerifiedUser from "@material-ui/icons/VerifiedUser";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: "12px"
  }
}))(MuiAccordionDetails);

export default function JobAccordions(props) {
  const { jobs, percent, editJobButtonClick, payJob, showDeletionJob, openDeletionConfirmation, classes } = props;
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      { jobs && jobs.length > 0
          ? jobs.map((job, index) => (
              <Accordion
                  key={index}
                  square
                  expanded={expanded === `panel${index+1}`}
                  onChange={handleChange(`panel${index+1}`)}
              >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>#{index+1} {job.jobName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.mobileWrapTableItem}>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Description
                      </div>
                      <div className={classes.mobileTableValue}>
                        {job.jobDescription}
                      </div>
                    </div>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Due date
                      </div>
                      <div className={classes.mobileTableValue}>
                        {moment(job.dateTime, "DD-MM-YYYY hh:mm A").format("DD-MM-YYYY")}
                      </div>
                    </div>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Price
                      </div>
                      <div className={classes.mobileTableValue}>
                        £{(job.total + job.total * percent / 100).toFixed(2)}
                      </div>
                    </div>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Actions
                      </div>
                      <div className={classes.mobileTableValue}>
                        <div className={classes.valueWrap}>
                          {(job.order && job.order.payment && job.order.payment.status !== 'execute') || !job.order || !job.order.payment? (
                              <Button round justIcon size="sm" color={"success"}
                                      onClick={() => editJobButtonClick(job._id)}
                              >
                                <Edit />
                              </Button>
                          ) : null }
                          {(job.order && job.order.payment && job.order.payment.status !== 'execute') || !job.order || !job.order.payment ? (
                              <Button round justIcon size="sm" color={"danger"}
                                      onClick={() => openDeletionConfirmation(job._id)}
                              >
                                <Close />
                              </Button>
                          ) : null }
                          <Button round justIcon size="sm" color={"info"}
                                  onClick={() => showDeletionJob(job._id)}
                          >
                            <Visibility />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Status
                      </div>
                      <div className={classes.mobileTableValue}>
                        <div className={classes.valueWrap}>
                          { (job.order && job.order.payment && job.order.payment.status !== 'execute') || !job.order || !job.order.payment ? (
                                  <Button className={"btn-finalize"} color={"success"}
                                          onClick={() => payJob(job._id)}
                                  >
                                    Finalise
                                  </Button>
                              ) :
                              <VerifiedUser className={"verifide-color"} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
          )) : null }
    </div>
  );
}
