/* eslint-disable */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import jobPostingPageStyle
    from "assets/jss/material-kit-pro-react/views/jobPostingPageStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../../actions";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import noUiSlider from "nouislider";

class Step3Card extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let slider =  this.refs.sliderPrice;
        noUiSlider.create(this.refs.sliderPrice, {
            start: [this.props.minPrice, this.props.maxPrice],
            connect: [false, true, false],
            step: 1,
            range: { min: 25, max: 100 }
        });
        slider.noUiSlider.on('update', this.props.handleSliderChange);
    }

    render() {
        const {classes} = this.props;
        return (
            <GridItem xs={12} sm={12} md={12}>
                <Card
                    className={classes.textCenter + " "
                    + classes.marginAuto + " "
                    + classes.cardWidth + " "
                    + classes.step3Card
                    }>
                    <CardBody>
                        <h3 className={classes.cardTitle}>
                            <p
                                className={classes.cardTitleText}
                            >Budget</p>
                            <p
                                className={classes.cardTitleText}
                            >step 3 of 3</p>
                        </h3>
                        <div className={
                            classes.marginBottom20
                        }></div>

                        <div
                            className={
                                classes.pricesDivContainer
                            }
                        >
                            <div
                                className={
                                    classes.checkboxAndRadio + " "
                                    + classes.checkboxAndRadioHorizontal + " "
                                    + classes.minPriceWidth
                                }>
                                <FormControlLabel
                                    control={
                                        <CustomInput
                                            labelText="Min price (£)"
                                            id="city"
                                            inputProps={{
                                                disabled: true,
                                                fullWidth: true,
                                                value: this.props.minPrice,
                                            }}
                                        />
                                    }
                                />
                            </div>
                            <div
                                className={
                                    classes.checkboxAndRadio + " "
                                    + classes.checkboxAndRadioHorizontal + " "
                                    + classes.maxPriceWidth
                                }>
                                <FormControlLabel
                                    control={
                                        <CustomInput
                                            labelText="Max price (£)"
                                            id="city"
                                            inputProps={{
                                                disabled: true,
                                                fullWidth: true,
                                                value: this.props.maxPrice,
                                            }}
                                        />
                                    }
                                />
                            </div>
                        </div>



                        <div
                            className={
                                classes.horizontalMargin20
                        }>
                            <br />
                            <div
                                onChange={this.handleSliderChange}
                                className="slider-success" ref="sliderPrice" />
                            <br />
                        </div>


                        <div className={
                            classes.marginBottom20
                        }></div>

                        {
                            !this.props.reviewFlag &&
                            <div>
                                <Button onClick={e => this.props.handleBackClick(e, 'step')}
                                        color="primary">Back</Button>
                                <Button onClick={e => this.props.handleNextClick(e, 'step')}
                                        color="primary">Next</Button>
                            </div>
                        }
                        {
                            this.props.reviewFlag &&
                            <Button onClick={e => this.props.handleUpdateClick(e)}
                                    color="primary">Update</Button>
                        }
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user
        }
    }, {
        getUser: userActions.getUser
    }
)(withStyles(jobPostingPageStyle)(Step3Card));
