import React from "react";
import {urlConstants} from "../../constants/api-url";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import forgotPasswordStyle from "assets/jss/material-kit-pro-react/views/forgotPasswordStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../actions";

class ForgotPasswordPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
      email: '',
      emailError: false,
      emailErrorMessage: '',
      continueDisabled: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  handleChange(event, name) {
    this.setState({[name]: event.target.value});
    let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailReg.test(event.target.value)) {
      this.setState({[name + 'Error']: false});
    } else {
      this.setState({[name + 'Error']: true});
      this.setState({[name + 'ErrorMessage']: 'Email is not valid'});
    }
  }

  sendEmail() {
    if (
        this.state.email !== ""
        && !this.state.emailError
    ) {
      this.setState({'continueDisabled': true});
      this.props.resetPass(this.state.email).then(
        res => {
          this.setState({ isHidden: !this.state.isHidden });
          this.setState({'continueDisabled': false});
        },
        err => {
          this.setState({'emailError': true});
          this.setState({'emailErrorMessage': 'Password or email is incorrect'});
          this.setState({'continueDisabled': false});
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
			logoLink = {urlConstants.LANDING_URL}
          absolute
          color="transparent"
          brand="TEC MOW"
          links={<HeaderLinks dropdownHoverColor="info" />}
        />
        <div
          className={
            classes.pageHeader
          }
          style={{}}
        >
          <div className={classes.container + " "
            + classes.smallScreen60vh
          }>
            <GridContainer justify="center"
                className={
                  classes.transform10
            }>
              <GridItem xs={12} sm={12} md={4} className={
                classes.transform50
              }>
                <Card>
                  {
                    !this.state.isHidden &&

                    <form className={classes.form}>
                      <CardHeader
                          color="primary"
                          signup
                          className={classes.cardHeader}
                      >
                        <h4 className={
                          classes.cardTitle + " "
                          + classes.cardHeaderText
                        }>Reset your
                          password</h4>
                      </CardHeader>
                      <CardBody signup>
                        <CustomInput
                            id="email"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              placeholder: "Email...",
                              type: "email",
                              startAdornment: (
                                  <InputAdornment position="start">
                                    <Email className={classes.inputIconsColor} />
                                  </InputAdornment>
                              ),
                              onChange: e => this.handleChange(e, 'email'),
                              error: this.state.emailError,
                              errormessage: this.state.emailErrorMessage
                            }}
                        />
                      </CardBody>
                      <div className={
                        classes.textCenter + " "
                        + classes.marginBottom10
                      }>
                        <Button simple color="success" size="lg"
                                className={classes.loginButton}
                                onClick={e => this.sendEmail()}
                                disabled={this.state.continueDisabled}
                        >
                          Continue
                        </Button>
                      </div>
                    </form>
                  }
                  {
                    this.state.isHidden &&

                    <form className={classes.form}>
                      <CardHeader
                          color="primary"
                          signup
                          className={classes.cardHeader}
                      >
                        <h4 className={classes.cardTitle}>Reset password message</h4>
                      </CardHeader>
                      <CardBody signup>
                        <div>
                          <p className={
                            classes.textCenter + " "
                            + classes.font20
                          }>
                            A reset password e-mail has been sent to your e-mail
                          </p>
                          <p className={
                            classes.textCenter + " "
                            + classes.font30
                          }>
                            <i className="fas fa-envelope"></i>
                          </p>
                          <p className={
                            classes.textLeft + " "
                            + classes.fontItalic
                          }>
                            Don't receive the message?
                            It could be due to a typo in the e-mail address you
                            provided or because the message got caught in your
                            spam filter. If you continue to have problems
                            with reset password, please contact
                            <a className={
                              classes.fontNormal
                            }
                                href="#"> support</a>
                          </p>
                        </div>
                      </CardBody>
                    </form>
                  }

                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer
            className={classes.footer}
          />
        </div>
      </div>
    );
  }
}

export default connect(
    store => {
      return {
        user: store.users.user
      }
    }, {
      resetPass: userActions.resetPass
    }
)(withStyles(forgotPasswordStyle)(ForgotPasswordPage));
