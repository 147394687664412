import { authHeader, history, handleResponse } from "../helpers";
import { urlConstants } from "../constants";

export const userService = {
  login,
  logout,
  register,
  registerConfirm,
  resetPass,
  resetPassConfirm,
  getAll,
  getUserData,
  getById,
  getUserById,
  update,
  delete: _delete
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password })
  };

  return fetch(urlConstants.BACKEND_URL + `/user/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("token", user.token);
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`/users`, requestOptions).then(handleResponse);
}

function getUserData() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    urlConstants.BACKEND_URL + `/user/get-data`,
    requestOptions
  ).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function getUserById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/user/get-user/` + id,
    requestOptions
  ).then(handleResponse);
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };
  return fetch(urlConstants.BACKEND_URL + `/user/create`, requestOptions).then(
    handleResponse
  );
}

function registerConfirm(userId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  };
  return fetch(
    urlConstants.BACKEND_URL + `/user/activate/` + userId,
    requestOptions
  ).then(handleResponse);
}

function resetPass(userEmail) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userEmail })
  };
  return fetch(
    urlConstants.BACKEND_URL + `/user/reset-pass`,
    requestOptions
  ).then(handleResponse);
}

function resetPassConfirm(userID, userPass) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userID, userPass })
  };  
  return fetch(
    urlConstants.BACKEND_URL + `/user/reset-pass-confirm`,
    requestOptions
  ).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };
  return fetch(urlConstants.BACKEND_URL+`/user/update/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };

  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}