import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.jsx";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";
import { userActions } from "../../actions";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import { history } from "../../helpers";

function HeaderLinks({ history, ...props }) {

  function customerdashboardPage() {
    const urlSlug = window.location.href.substr(
      window.location.href.lastIndexOf("/") + 1
    );
    if (urlSlug.indexOf("customer-dashboard") === -1) {
      history.push("/customer-dashboard");
    }
  }

  function customerProfilePage() {
    const urlSlug = window.location.href.substr(
      window.location.href.lastIndexOf("/") + 1
    );
    if (urlSlug.indexOf("customer-page") === -1) {
      history.push("/customer-page");
    }
  }

  function contractorProfilePage() {
    const urlSlug = window.location.href.substr(
      window.location.href.lastIndexOf("/") + 1
    );
    if (urlSlug.indexOf("contractor-page") === -1) {
      history.push("/contractor-page");
    }
  }

  function customerMyContracts() {
    const urlSlug = window.location.href.substr(
      window.location.href.lastIndexOf("/") + 1
    );
    if (urlSlug.indexOf("my-contracts") === -1) {
      history.push("/my-contracts");
    }
  }

  function customerdashboardPageContractor() {
    const urlSlug = window.location.href.substr(
      window.location.href.lastIndexOf("/") + 1
    );
    if (urlSlug.indexOf("contractor-all-jobs") === -1) {
      history.push("/contractor-all-jobs");
    }
  }

  var urlSlug = window.location.href.substr(
    window.location.href.lastIndexOf("/") + 1
  );
  const { classes } = props;

  return (
    <div className={classes.RightFloatDiv + " " + classes.mobileWidth100}>
      <List
        className={
          classes.list +
          " " +
          classes.mlAuto +
          " " +
          classes.firstLineList +
          " " +
          classes.mobileWidth100
        }
      >
        {urlSlug !== "signup-page" && (
          <ListItem
            className={
              classes.listItem +
              " " +
              classes.secondListItem +
              " " +
              (urlSlug.indexOf("signup-page") !== -1
                ? classes.greenBackground
                : "") +
              " " +
              (urlSlug.indexOf("signup-page") !== -1
                ? classes.blackBorder
                : "") +
              " "
            }
          >
            {!props.user && (
              <Link
                to={"/signup-page"}
                color={window.innerWidth < 960 ? "info" : "white"}
                className={
                  classes.navButton +
                  " " +
                  classes.secondListLink +
                  " " +
                  classes.buttonLink +
                  " " +
                  (urlSlug === "signup-page" ? classes.greenBackground : "") +
                  " " +
                  (urlSlug === "signup-page"
                    ? classes.horPadding23
                    : classes.horPadding25) +
                  " " +
                  classes.marginRight10
                }
              >
                Sign Up
              </Link>
            )}
          </ListItem>
        )}
        {!props.user && urlSlug !== "login-page" && urlSlug !== "" && (
          <ListItem
            className={
              classes.listItem +
              " " +
              classes.navLinkItem +
              " " +
              (urlSlug.indexOf("login-page") !== -1
                ? classes.greenBackground
                : "") +
              " " +
              (urlSlug.indexOf("login-page") !== -1
                ? classes.blackBorder
                : "") +
              " "
            }
          >
            <Link
              to={"/login-page"}
              color={window.innerWidth < 960 ? "info" : "white"}
              className={
                classes.navButton +
                " " +
                classes.secondListLink +
                " " +
                classes.buttonLink +
                " " +
                (urlSlug === "login-page" ? classes.greenBackground : "")
              }
            >
              Login
            </Link>
          </ListItem>
        )}

        {props.user && (
          <ListItem
            className={
              classes.listItem +
              " " +
              classes.navLinkItem +
              " " +
              classes.greyButtonDropdownListItem
            }
          >
            {props.user.account_type == 2 ? (
              <CustomDropdown
                buttonText="Jobs"
                buttonProps={{
                  color: window.innerWidth < 960 ? "info" : "white"
                }}
                dropdownList={[
                  {
                    name: "Dashboard",
                    onClick: customerdashboardPageContractor
                  },
                  { name: "Profile Page", onClick: contractorProfilePage },
                  { name: "My Contracts", onClick: customerMyContracts }
                ]}
              />
            ) : (
              <CustomDropdown
                buttonText="Jobs"
                buttonProps={{
                  color: window.innerWidth < 960 ? "info" : "white"
                }}
                dropdownList={[
                  { name: "Dashboard", onClick: customerdashboardPage },
                  { name: "Profile Page", onClick: customerProfilePage }
                ]}
              />
            )}
          </ListItem>
        )}

        {props.user && (
          <ListItem className={classes.listItem + " " + classes.navLinkItem}>
            <Button
              color={window.innerWidth < 960 ? "info" : "white"}
              className={
                classes.navButton +
                " " +
                classes.secondListLink +
                " " +
                classes.greyButton
              }
              onClick={props.logout}
            >
              Logout
            </Button>
          </ListItem>
        )}
      </List>
      <List
        className={
          classes.list +
          " " +
          classes.mlAuto +
          " " +
          classes.borderedList +
          " " +
          classes.floatRight
        }
      />
    </div>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withStyles(headerLinksStyle)(
  connect(
    store => {
      return {
        user: store.users.user
      };
    },
    {
      logout: userActions.logout
    }
  )(withRouter(HeaderLinks))
);
