const mainLayoutStyle = {
    parallax: {
        height: "100px",
        backgroundPosition: "top center",
        marginBottom: "20px",
        boxShadow: "16px 16px 24px 2px rgba(0, 0, 0, 0.14), 6px 6px 30px 5px rgba(0, 0, 0, 0.12), 6px 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    right: {
        padding: "15px 0",
        margin: "0",
        float: "right"
    },
    icon: {
        width: "18px",
        height: "18px",
        top: "3px",
        position: "relative"
    },
}

export default mainLayoutStyle;