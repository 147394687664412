import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import customTextAreaStyle
  from "../../assets/jss/material-kit-pro-react/components/customTextAreaStyle";

function CustomTextArea({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    inputProps,
    error,
    success,
    rowsCount,
    value,
    name
  } = props;
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <TextField
          id="outlined-multiline-flexible"
          label={ labelText }
          multiline
          rowsMax={ rowsCount }
          className={
            classes.textField + " "
            + classes.greenColorUnderline + " "
            + (inputProps.error ? classes.redColorUnderline : '' )
          }
          margin="normal"
          value={value}
          name = {name}
          onChange = {props.onChange}
          onBlur = {props.onBlur}
          InputLabelProps={{
            FormLabelClasses: {
              focused: classes.focusedLabel
            }
          }}
      />
      {
        inputProps.error && inputProps.errormessage &&
        <span className={classes.labelRootError}> { inputProps.errormessage } </span>
      }
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomTextArea.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  errormessage: PropTypes.string,
  success: PropTypes.bool,
  white: PropTypes.bool
};

export default withStyles(customTextAreaStyle)(CustomTextArea);
