/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "assets/jss/material-kit-pro-react/components/footerStyle.jsx";
import brandImage from "assets/img/tec-mow/brandImage.png";
import { urlConstants } from "../../constants";

function Footer(props) {
  const { children, content, classes, theme, big, className } = props;
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container
      }>
        {children !== undefined ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
          " "
        )}
        {
          <div>
            <div className={
              classes.left + " "
              + classes.inlineBlock + " "
              + classes.verticalTop + " "
              + classes.width50 + " "
              + classes.textAlignLeft
            }>
              <div className={
                classes.height34
              }>
              </div>
              <List className={classes.list + " "
              + classes.inlineUl + " "
              + classes.verticalTop
              }>
                <ListItem className={
                  classes.block + " "
                  + classes.marginTop
                }>
                  Copyright &copy; {1900 + new Date().getYear()} , tecmow.com
                </ListItem>
              </List>
            </div>
            <div className={
              classes.left + " "
              + classes.width50 + " "
              + classes.marginTop50 + " "
              + classes.textAlignRight
            }>
              <List className={classes.list + " "
              + classes.inlineUl
              }>
                <ListItem className={classes.inlineBlock}>
                  <img src={brandImage} alt="Smiley face" height="50" width="auto"
                       className={
                         classes.verticalAllignUnset
                  }></img>
                </ListItem>
                <ListItem className={
                  classes.inlineBlock + " "
                  + classes.marginLeft + " "
                  + classes.verticalBotton
                }>
                  <a
                      href='tel:004401452238064'
                      className={classes.block}
                  >
                    <p className={
                      classes.font16
                    }>01215 822 533</p>
                  </a>
                  <a
                      href='mailto:hello@tecmow.com'
                      className={classes.block}
                  >
                    <p className={
                      classes.font16
                    }>hello@tecmow.com</p>
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        }
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
  content: PropTypes.node
};

export default withStyles(footerStyle)(Footer);
