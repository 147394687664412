/* eslint-disable */
import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Button from "components/CustomButtons/Button.jsx";
import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent";

import Check from "@material-ui/icons/Check";
import Info from "@material-ui/icons/Info";
import Priority from "@material-ui/icons/PriorityHigh";
import customerDashboardStyle
    from "assets/jss/material-kit-pro-react/views/customerDashboardStyle.jsx";
import {connect} from "react-redux";
import {jobActions} from "../../actions"
import JobsCard from "./Cards/JobsCard";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import ContractsCard from "./Cards/ContractsCard";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {paymentActions} from "../../actions/payment.actions";
import DialogActions from "@material-ui/core/DialogActions";
import {overtimeActions} from "../../actions/overtime.actions";
import {orderActions} from "../../actions/order.actions";
const queryString = require('query-string');

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class CustomerDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobsPaginated: [],
            jobContractorCount: 1,
            myJobsPaginated: [],
            myJobCount: 1,
            activePageContacts: 1,
            activePageJobs: 1,

            confirmDeletion: false,
            deleteId: '',

            serverMessage: false,
            serverError: false,
            serverMessageTitle: "",
            serverMessageText: "",

            jobUpdatingError: false,
            jobUpdatingErrorMessage: "",
            jobUpdatingSuccess: false,
            jobUpdatingSuccessMessage: "",
            jobsLoading: true,
            preloader: true,
        
            paymentRecived: false,
            paymentRecivedOvertime: false,
            contractorPay: 0
        };
    }

    componentDidMount() {
        this.setState({preloader: true, jobsLoading: true});

        if (this.props.location.search) {
            const param = queryString.parse(this.props.location.search);

            this.props.executePayment(param).then(
                res => {
                    if (res.message === 'payment completed successfully') {
                        if (param.over === 'true') {
                            this.setState({paymentRecivedOvertime: true})
                        } else {
                            this.setState({paymentRecived: true})
                        }
                        this.props.getUserJobsPaginated(this.state.activePageJobs).then(
                            res => {
                                this.setState({
                                    myJobsPaginated: res.jobs,
                                    myJobCount: res.jobsCount,
                                    jobsLoading: false
                                });

                                let paymentId = {paymentId: param.paymentId};

                                this.props.sendPayment(paymentId).then(
                                    res => {
                                        this.props.getUserContractsJobsPaginated(this.state.activePageContacts).then(
                                            res => {
                                                let contractorPay = res.jobs.filter(job => {
                                                    return job.status === 'Pending approval';
                                                });

                                                this.setState({
                                                    jobsLoading: false,
                                                    preloader: false,
                                                    jobsPaginated: res.jobs,
                                                    jobContractorCount: res.jobsCount,
                                                    contractorPay: contractorPay.length
                                                });
                                            },
                                            err => {
                                                this.setState({preloader: true});
                                            }
                                        );
                                    },
                                    err => {
                                    }
                                );
                            },
                            err => {
                                console.log(err);
                            }
                        );
                    }
                },
                err => {
                    console.log(err);
                }
            )
        } else {
            Promise.all([
                this.props.getUserJobsPaginated(this.state.activePageJobs),
                this.props.getUserContractsJobsPaginated(this.state.activePageContacts)
            ]).then(
                res => {
                    let contractorPay = res[1].jobs.filter(job => {
                        return job.status === 'Pending approval';
                    });

                    this.setState({
                        myJobsPaginated: res[0].jobs,
                        myJobCount: res[0].jobsCount,
                        jobsLoading: false,
                        preloader: false,
                        jobsPaginated: res[1].jobs,
                        jobContractorCount: res[1].jobsCount,
                        contractorPay: contractorPay.length
                    });
                },
                err => {
                    console.log(err)
                }
            )
        }
           
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    closeAlerts = () => {
        const state = {...this.props.location.state};
        delete state.createNewPost;
        delete state.jobCompletedPaid;
        this.setState({paymentRecived: false, paymentRecivedOvertime: false});
        this.props.history.replace({...history.location, state});
    };

    fetchContracts = pageNumber => {
        this.setState({ jobsLoading: true})
        this.props.getUserContractsJobsPaginated(pageNumber).then(
            res => {
                this.setState({ jobsPaginated: res.jobs, jobsLoading: false, jobContractorCount: res.jobsCount });
            },
            err => {this.setState({ jobsLoading: false})}
        );
    };

    fetchJobs = pageNumber => {
        this.setState({ jobsLoading: true})
        this.props.getUserJobsPaginated(pageNumber).then(
            res => {
                this.setState({ myJobsPaginated: res.jobs, jobsLoading: false, myJobCount: res.jobsCount });
            },
            err => {this.setState({ jobsLoading: false})}
        );
    };

    changeActivePageJob = pageNumber => {
        this.setState({activePageJobs: pageNumber});
    };

    changeActivePageContract = pageNumber => {
        this.setState({activePageContacts: pageNumber});
    };

    postJobClick(e) {
        this.props.history.push("/job-posting-page");
    };

    editProfileDetails(e) {
        this.props.history.push("/customer-page");
    };

    openDeletionConfirmation = (id) => {
        this.setState({'deleteId': id}, () => {
            this.setState({'confirmDeletion': true});
        });
    };
    showDeletionJob = (id) => {
        this.props.history.push("/job-details-page/" + id);
    };

    payJob = (id) => {
        this.props.history.push("/finalize/" + id);
    };

    payWork = (id) => {
        this.props.history.push("/payment-job/" + id);
    };

    deleteJobButtonClick = () => {
        this.handleClose('confirmDeletion');
        this.props.deleteJobById(this.state.deleteId).then(
            res => {
                this.setState({'serverError': false});
                this.handleClickOpen('serverMessage');
                this.setState(
                    {
                        'serverMessageTitle': 'Success',
                        'serverMessageText': 'Job has been deleted successfully'
                    }
                );
            },
            err => {
                this.handleClickOpen('serverMessage');
                this.setState(
                    {
                        'serverMessageTitle': 'Error',
                        'serverMessageText': err,
                        'serverError': true
                    }
                );
                console.log(err);
            }
        );
    };

    editJobButtonClick = (id) => {
        this.props.history.push("/job-updating-page/" + id);
    };

    arrangePageData() {}

    handleClickOpen(modal) {
        var x = [];
        x[modal] = true;
        this.setState(x);
    }

    handleClose(modal) {
        if (!this.state.serverError && modal !== 'confirmDeletion') {
            this.fetchJobs(this.state.activePageJobs)
        }

        var x = [];
        x[modal] = false;
        this.setState(x);
    }

    editProfileButtonClick = (id) => {
        this.props.history.push("/edit-profile/" + id);
    };

    render() {
        const {classes, ...rest} = this.props;
        return (
            <div>
                {this.state.preloader && <PreloaderComponent/>}

                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={
                        classes.container + " "
                        + classes.customerDashboardContainer
                    }>
                        {this.state.paymentRecived ? (
                            <SnackbarContent
                                message={
                                    <span><b>Payment received.</b> The job is finalized and active. Contractors can now accept this job.</span>
                                }
                                close
                                color="success"
                                closeAlert={this.closeAlerts}
                                icon={Check}
                            />
                        ) : null}
                        {this.state.paymentRecivedOvertime ? (
                            <SnackbarContent
                                message={
                                    <span><b>Payment received.</b> The job is completed.</span>
                                }
                                close
                                color="success"
                                closeAlert={this.closeAlerts}
                                icon={Check}
                            />
                        ) : null}
                        {this.props.location.state && this.props.location.state.createNewPost != undefined ? (
                            <div>
                                <SnackbarContent
                                    message={
                                        <span>You should finalize your job, so that the contractor can accept it. Please, click on Finalize button</span>
                                    }
                                    close
                                    color="warning"
                                    icon={Priority}
                                    closeAlert={this.closeAlerts}
                                />
                            </div>
                        ) : null}
                        {this.props.location.state && this.props.location.state.jobCompletedPaid != undefined ? (
                            <div>
                                <SnackbarContent
                                    message={
                                        <span>Contract successfully closed</span>
                                    }
                                    close
                                    color="success"
                                    icon={Check}
                                    closeAlert={this.closeAlerts}
                                />
                            </div>
                        ) : null}
                        {this.state.contractorPay > 0 ? (
                            <div>
                                <SnackbarContent
                                    message={
                                        <span>The contractor has completed your job successfully. Please, check My contracts section and approve.</span>
                                    }
                                    close
                                    color="warning"
                                    icon={Info}
                                    closeAlert={this.closeAlerts}
                                />
                            </div>
                        ) : null}
                        <GridContainer justify="center"
                                       className={
                                           classes.customerNameContainer
                                       }>
                            <GridItem xs={12} sm={12} md={8}
                                      className={
                                          classes.customerName
                                      }
                            >
                                <div className={
                                    classes.follow + " "
                                    + classes.editProfile
                                }>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="Edit profile details"
                                        placement="top"
                                        onClick={e => this.editProfileDetails(e)}
                                        classes={{tooltip: classes.tooltip}}
                                    >
                                        <Button
                                            justIcon
                                            round
                                            color="primary"
                                            className={classes.followButton}
                                            onClick={(e) => this.editProfileButtonClick(this.props.user._id)}
                                        >
                                            <Edit
                                                className={classes.followIcon}/>
                                        </Button>
                                    </Tooltip>
                                </div>
                                <div className={classes.profile}>
                                    <div className={
                                        classes.name + " "
                                        + classes.customerDashboardName
                                    }>
                                        <h3 className={classes.title}>
                                            {
                                                this.props.user &&
                                                this.props.user.first_name + " "
                                                + this.props.user.last_name
                                            }
                                        </h3>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}
                                      className={
                                          classes.postJobGridItem
                                      }>
                                <div
                                    className={classNames(classes.description, classes.textCenter)}
                                >
                                    <Button type="button" color="success"
                                            className={
                                                classes.jobPostButton
                                            }
                                            onClick={e => this.postJobClick()}
                                    >Create booking</Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <JobsCard
                                jobCount={this.state.myJobCount}
                                call={this.fetchJobs}
                                changeActivePage={this.changeActivePageJob}
                                jobs={this.state.myJobsPaginated}
                                activePage={this.state.activePageJobs}

                                openDeletionConfirmation={this.openDeletionConfirmation}
                                showDeletionJob={this.showDeletionJob}
                                payJob={this.payJob}
                                editJobButtonClick={this.editJobButtonClick}
                            />

                            <ContractsCard
                                jobsLoading={this.state.jobsLoading}
                                jobCount={this.state.jobContractorCount}
                                call={this.fetchContracts}
                                changeActivePage={this.changeActivePageContract}
                                jobs={this.state.jobsPaginated}
                                payWork={this.payWork}
                                activePage={this.state.activePageContacts}
                            />
                        </GridContainer>
                        <Clearfix/>
                    </div>

                </div>

                <div>
                    <Dialog
                        classes={{
                            root: classes.modalRoot,
                            paper: classes.modal
                        }}
                        open={this.state.jobUpdatingError}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => this.handleClose("jobUpdatingError")}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                        className={
                            classes.serverModalDiv
                        }
                    >
                        <DialogContent
                            id="classic-modal-slide-description"
                            className={
                                classes.modalBody + " "
                                + classes.errorModalText
                            }>
                            <p>{this.state.jobUpdatingErrorMessage}</p>
                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>
                            <Button
                                onClick={() => this.handleClose("jobUpdatingError")}
                                color="secondary"
                                className={
                                    classes.marginAuto
                                }
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        classes={{
                            root: classes.modalRoot,
                            paper: classes.modal
                        }}
                        open={this.state.jobUpdatingSuccess}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => this.handleClose("jobUpdatingSuccess")}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                        className={
                            classes.serverModalDiv
                        }
                    >
                        <DialogContent
                            id="classic-modal-slide-description"
                            className={
                                classes.modalBody + " "
                                + classes.successModalText
                            }>
                            <p>{this.state.jobUpdatingSuccessMessage}</p>
                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>
                            <Button
                                onClick={() => this.handleClose("jobUpdatingSuccess")}
                                color="secondary"
                                className={
                                    classes.marginAuto
                                }
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        classes={{
                            root: classes.modalRoot,
                            paper: classes.modal
                        }}
                        open={this.state.serverMessage}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => this.handleClose("serverMessage")}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                        className={
                            classes.serverModalDiv
                        }
                    >
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <h4 className={
                                classes.modalTitle + " "
                                + classes.serverModalHeader + " "
                                + (this.state.serverError ? classes.errorModal : '')
                            }>
                                {this.state.serverMessageTitle}
                            </h4>
                        </DialogTitle>
                        <DialogContent
                            id="classic-modal-slide-description"
                            className={classes.modalBody}
                        >
                            <p>{this.state.serverMessageText}</p>
                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>
                            <Button
                                onClick={() => this.handleClose("serverMessage")}
                                color="secondary"
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        classes={{
                            root: classes.modalRoot,
                            paper: classes.modal
                        }}
                        open={this.state.confirmDeletion}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => this.handleClose("serverMessage")}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                        className={
                            classes.serverModalDiv
                        }
                    >
                        <DialogContent
                            id="classic-modal-slide-description"
                            className={
                                classes.modalBody + " "
                                + classes.deletionConfirmation
                            }
                        >
                            <p>Are you sure you want to delete this job?</p>
                        </DialogContent>
                        <DialogActions className={
                            classes.modalFooter + " "
                            + classes.deletionConfirmationActions
                        }>
                            <Button
                                onClick={() => this.deleteJobButtonClick()}
                                color="danger"
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => this.handleClose("confirmDeletion")}
                                color="primary"
                            >
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user,
            jobs: store.jobs,
            myJobs: store.myJobs,
            messages: store.messages,
            allJobsCount: store.jobs.allJobsCount
        }
    }, {
        getUserJobsPaginated: jobActions.getUserJobsPaginated,
        deleteJobById: jobActions.deleteJobById,
        getUserContractsJobsPaginated: jobActions.getUserContractsJobsPaginated,
        executePayment: paymentActions.executePayment,
        updateUserJob: jobActions.updateUserJob,
        findPayment: paymentActions.findPayment,
        getOvertimeById: overtimeActions.getOvertimeById,
        geOrderById: orderActions.geOrderById,
        geJobById: jobActions.geJobById,
        sendPayment: paymentActions.sendPayment
    }
)(withStyles(customerDashboardStyle)(CustomerDashboard));
