/* eslint-disable */
import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import jobPostingPageStyle from "assets/jss/material-kit-pro-react/views/jobPostingPageStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../../actions";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import EstimateModal from "components/Modal/Modal.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from '@material-ui/icons/Check';
import CustomTextArea from "../../../components/CustomTextArea/CustomTextArea";
import Grid from "@material-ui/core/Grid";

class Step1Card extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            percent: 10,
            hours: 0,
            bags: 0,
            lawnSize: 0,
            lawnMoving: "",
            calculateHourly: "",
            easyAccess: "",
            selectIsOpen: false
        };
    }

    componentDidMount() {
        this.setState({
            lawnMoving: this.props.lawnMoving,
            easyAccess: this.props.easyAccess,
            calculateHourly: this.props.calculateHourly,
        });

        if (this.props.lawnSize > 0) this.hoursEstimate(this.props.lawnSize);
    }

    clearData(e) {
        this.setState({hours: 0, bags: 0, lawnSize: 0});
        this.props.handleChange(e, 'easyAccess');
    }

    changeCustomInput = e => {
        if (e.target.value >= 0 && e.target.value <= 10000) {
            if (e.target.id === 'lawn-size') {
                this.hoursEstimate(e.target.value);
                this.props.handleChange(e, 'lawnSize')
            }

            if (e.target.id === 'hours') this.props.handleChange(e, 'hours');
            if (e.target.id === 'bags') this.props.handleChange(e, 'bags');
        }
    };

    hoursEstimate = (e) => {
        this.setState({lawnSize: null}, () => {
            if (e > 0 && e <= 100) {
                this.setState({hours: 1});
            } else if (e > 100 && e <= 250) {
                this.setState({hours: 2});
            } else if (e > 250 && e <= 500) {
                this.setState({hours: 3});
            }
        })
    };

    onKeyPress(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/[e+./-]/.test(keyValue)) {
            event.preventDefault();
        }
    }

    handleSimple = (event) => {
        this.setState({[event.target.name]: event.target.value}, () => {
            if (event.target.name === 'lawnMoving') {
                this.setState({lawnMoving: this.state.lawnMoving, calculateHourly: '', easyAccess: ""});
                this.props.handleChange(event, 'lawnMoving');
                this.props.handleSimple(event, "lawnMoving");
                this.props.clearEasyAccess();
            }
            if (event.target.name === 'calculateHourly') {
                this.setState({calculateHourly: this.state.calculateHourly, easyAccess: ""});
                this.props.handleChange(event, 'calculateHourly');
                this.props.handleSimple(event, "calculateHourly");
                this.props.clearEasyAccess();
            }
            if (event.target.name === 'easyAccess') {
                this.setState({easyAccess: this.state.easyAccess});
                this.props.handleChange(event, 'easyAccess');
            }
        });
    };

    handleTouch = (e) => {
        document.body.ontouchmove = function(e){return true; };
        if(this.state.selectIsOpen === false) {
            document.body.ontouchmove = function(e){ e.preventDefault(); };
            this.setState({selectIsOpen: true});
        } else {
            document.body.ontouchmove = function(e){return true; };
            this.setState({selectIsOpen: false});
        }
    };

    render() {
        const isDisabledInputs = (
            this.props.jobName !== ''
            && this.props.jobDescription !== ''
        );
        const isDisabledCheckbox =
            (
                this.props.lawnMoving
                || this.props.gardenClearance
                || this.props.hedgeTrimming
                || this.props.pruning
                || this.props.gardenCoaching
                || this.props.maintainingHerbaceousBorders
                // || this.props.vegetableGardening
                // || this.props.hedgeCutting
            );
        const isDisabled = isDisabledInputs && (this.props.lawnSize || this.props.hours) && (this.props.lawnSize >= 1 && this.props.lawnSize <= 500 || this.props.hours >= 1);
        const {classes, ...rest} = this.props;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid
        );
        const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
        return (
            <GridItem xs={12} sm={12} md={12}>
                <Card
                    className={classes.textCenter + " "
                    + classes.marginAuto + " "
                    + classes.cardWidth + " "
                    + classes.step1Card
                    }>
                    <CardBody>
                        <h3 className={classes.cardTitle}>
                            <p
                                className={classes.cardTitleText}
                            >Post a job</p>
                            <p
                                className={classes.cardTitleText}
                            >step 1 of 3</p>
                        </h3>
                        <div className={
                            classes.marginBottom20
                        }> </div>
                        <div
                            className={
                                classes.checkboxAndRadio + " "
                                + classes.checkboxAndRadioHorizontal + " "
                                + classes.jobNameWidth
                            }>
                            <FormControlLabel
                                control={
                                    <CustomInput
                                        labelText="Job Name *"
                                        id="job-name"
                                        inputProps={{
                                            fullWidth: true,
                                            value: this.props.jobName,
                                            onChange: e => this.props.handleChange(e, 'jobName'),
                                            onBlur: e => this.props.handleBlur(e, 'jobName'),
                                            error: this.props.jobNameError,
                                            errormessage: this.props.jobNameErrorMessage
                                        }}
                                    />
                                }
                            />
                        </div>
                        <div
                            className={
                                classes.checkboxAndRadio + " "
                                + classes.checkboxAndRadioHorizontal + " "
                                + classes.jobDescriptionWidth
                            }>
                            <FormControlLabel
                                control={
                                    <CustomTextArea
                                        labelText="Job Description *"
                                        rowsCount={Infinity}
                                        id="job-description"
                                        onChange={e => this.props.handleChange(e, 'jobDescription')}
                                        onBlur={e => this.props.handleBlur(e, 'jobDescription')}
                                        value={this.props.jobDescription}
                                        inputProps={{
                                            fullWidth: true,
                                            error: this.props.jobDescriptionError,
                                            errormessage: this.props.jobDescriptionErrorMessage
                                        }}
                                    />
                                }
                            />
                        </div>

                        <div>
                            <EstimateModal />
                        </div>
                        {
                        (<div>
                            <Grid container
                                  direction="row"
                                  justify="center"
                                  alignItems="center">
                                <GridItem xs={12} sm={12} md={7}>
                                    <p
                                        className={
                                            classes.setrviceTypesText
                                        }>
                                        {
                                            ("How many hours do you wish to book?")
                                        }
                                    </p>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    {
                                      (
                                        <div
                                            className={classes.easyAccessInput}>
                                            <FormControlLabel
                                                control={
                                                    <CustomInput
                                                        id="hours"
                                                        inputProps={{
                                                            type: "number",
                                                            placeholder: "Working hours (hours)",
                                                            fullWidth: true,
                                                            value: this.props.hours,
                                                            onChange: this.changeCustomInput,
                                                            onKeyPress: this.onKeyPress.bind(this),
                                                            error: this.props.lawnSizeError,
                                                            errormessage: this.props.lawnSizeErrorMessage
                                                        }}
                                                    />
                                                }
                                            /></div>)}
                                </GridItem>
                            </Grid>
                        </div>)}
                        <GridItem xs={12} sm={12} md={12}>
                            {this.props.lawnSize > 0 ? (
                              <div>
                                  <b><p>Estimated work time: {this.state.hours} h</p></b>
                              </div>
                            ) : null}

                        </GridItem>


                        {
                        (<div>
                            <Grid container
                                  direction="row"
                                  justify="center"
                                  alignItems="center">
                                <GridItem xs={12} sm={12} md={7}>
                                    <p
                                      className={
                                          classes.setrviceTypesText
                                      }>
                                        {
                                            ("Green waste collection")
                                        }
                                    </p>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    {
                                        (
                                          <div
                                            className={classes.easyAccessInput}>
                                              <FormControlLabel
                                                control={
                                                    <CustomInput
                                                      id="bags"
                                                      inputProps={{
                                                          type: "number",
                                                          placeholder: "Number of bags",
                                                          fullWidth: true,
                                                          value: this.props.bags,
                                                          onChange: this.changeCustomInput,
                                                          onKeyPress: this.onKeyPress.bind(this),
                                                          error: this.props.lawnSizeError,
                                                          errormessage: this.props.lawnSizeErrorMessage
                                                      }}
                                                    />
                                                }
                                              /></div>)}
                                </GridItem>
                            </Grid>
                        </div>)}


                        {
                            (
                            <div>
                                <GridItem xs={12} sm={12} md={12}>
                                    <p
                                        className={
                                            classes.setrviceTypesText
                                        }>
                                        Please select the services you require:
                                    </p>
                                </GridItem>


                                <GridItem xs={12} sm={12} md={6}
                                          className={
                                              classes.checkboxGroupWidth
                                          }>

                                    <div
                                        className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={e => this.props.handleToggle(e, 'gardenClearance')}
                                                    checked={this.props.checked.indexOf('gardenClearance') !== -1 ? true : false}
                                                    checkedIcon={<Check
                                                        className={classes.checkedIcon}/>}
                                                    icon={<Check
                                                        className={classes.uncheckedIcon}/>}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{label: classes.label}}
                                            label="Garden Clearance"
                                        />
                                    </div>
                                    <div
                                        className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={e => this.props.handleToggle(e, 'hedgeTrimming')}
                                                    checked={this.props.checked.indexOf('hedgeTrimming') !== -1 ? true : false}
                                                    checkedIcon={<Check
                                                        className={classes.checkedIcon}/>}
                                                    icon={<Check
                                                        className={classes.uncheckedIcon}/>}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{label: classes.label}}
                                            label="Hedge Trimming"
                                        />
                                    </div>
                                    <div
                                        className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={e => this.props.handleToggle(e, 'gardenCoaching')}
                                                    checked={this.props.checked.indexOf('gardenCoaching') !== -1 ? true : false}
                                                    checkedIcon={<Check
                                                        className={classes.checkedIcon}/>}
                                                    icon={<Check
                                                        className={classes.uncheckedIcon}/>}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{label: classes.label}}
                                            label="Garden Coaching"
                                        />
                                    </div>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6}
                                          className={
                                              classes.checkboxGroupWidth
                                          }>
                                    <div
                                        className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={e => this.props.handleToggle(e, 'pruning')}
                                                    checked={this.props.checked.indexOf('pruning') !== -1 ? true : false}
                                                    checkedIcon={<Check
                                                        className={classes.checkedIcon}/>}
                                                    icon={<Check
                                                        className={classes.uncheckedIcon}/>}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{label: classes.label}}
                                            label="Pruning"
                                        />
                                    </div>
                                    <div
                                        className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={e => this.props.handleToggle(e, 'maintainingHerbaceousBorders')}
                                                    checked={this.props.checked.indexOf('maintainingHerbaceousBorders') !== -1 ? true : false}
                                                    checkedIcon={<Check
                                                        className={classes.checkedIcon}/>}
                                                    icon={<Check
                                                        className={classes.uncheckedIcon}/>}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{label: classes.label}}
                                            label="Maintaining herbaceous borders"
                                        />
                                    </div>
                                    <div
                                        className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={e => this.props.handleToggle(e, 'lawnMoving')}
                                                    checked={this.props.checked.indexOf('lawnMoving') !== -1 ? true : false}
                                                    checkedIcon={<Check
                                                        className={classes.checkedIcon}/>}
                                                    icon={<Check
                                                        className={classes.uncheckedIcon}/>}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{label: classes.label}}
                                            label="Lawn mowing"
                                        />
                                    </div>
                                    {/* <div
                                        className={classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={e => this.props.handleToggle(e, 'hedgeCutting')}
                                                    checked={this.props.checked.indexOf('hedgeCutting') !== -1 ? true : false}
                                                    checkedIcon={<Check
                                                        className={classes.checkedIcon}/>}
                                                    icon={<Check
                                                        className={classes.uncheckedIcon}/>}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            classes={{label: classes.label}}
                                            label="Hedge cutting - below 6ft"
                                        />
                                    </div> */}
                                </GridItem>
                            </div>
                        )}

                        <div
                            className={
                                classes.checkboxAndRadio + " "
                                + classes.checkboxAndRadioHorizontal + " "
                                + classes.lawnMaintenancewidth + " "
                                + classes.movingFrequency
                            }>

                        </div>

                        {this.props.total > 0 && (this.props.lawnSize !== '' || this.props.hours !== '') ? (
                            <div>
                                <b><p>Amount: £{this.props.total}</p></b>
                                <b><p>Service fee: {this.state.percent}%</p></b>
                                <b><p>Total
                                    amount: £{(this.props.total + this.props.total * this.state.percent / 100).toFixed(2)}</p></b>
                            </div>
                        ) : null}

                        <div className={
                            classes.marginBottom20
                        }> </div>

                        {
                            !this.props.reviewFlag &&
                            <Button onClick={e => this.props.handleNextClick(e, 'step')}
                                    disabled={!isDisabled}
                                    color="primary">Next</Button>
                        }
                        {
                            this.props.reviewFlag &&
                            <Button onClick={e => this.props.handleUpdateClick(e)}
                                    disabled={!isDisabled}
                                    color="primary">Update</Button>
                        }
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user
        }
    }, {
        getUser: userActions.getUser
    }
)(withStyles(jobPostingPageStyle)(Step1Card));
