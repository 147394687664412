import { orderConstants } from "../constants/order.constants";
import { orderService } from "../services/order.service";
import { alertActions } from "./";
import { history } from "../helpers";
import {jobConstants} from "../constants";
import {jobService} from "../services";

export const orderActions = {
  createOrder,
  findOrder,
  geOrderById
};

function createOrder(orderObj) {
  return async dispatch => {
    try {
      dispatch(request(orderObj));
      const order = await orderService.createOrder(orderObj);
      dispatch(success(order));
      return order;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(order) {
    return { type: orderConstants.POST_ORDER_REQUEST, order };
  }
  function success(order) {
    return { type: orderConstants.POST_ORDER_SUCCESS, order };
  }
  function failure(error) {
    return { type: orderConstants.POST_ORDER_FAILURE, error };
  }
}
function findOrder(jobId) {
  return async dispatch => {
    try {
      dispatch(request(jobId));
      const order = await orderService.getUserOrderById(jobId);
      dispatch(success(order));
      return order;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(order) {
    return { type: orderConstants.GET_ORDER_REQUEST, order };
  }
  function success(order) {
    return { type: orderConstants.GET_ORDER_SUCCESS, order };
  }
  function failure(error) {
    return { type: orderConstants.GET_ORDER_FAILURE, error };
  }
}

function geOrderById(id) {
  return async dispatch => {
    try {
      dispatch(request(id));
      const order = await orderService.geOrderById(id);
      dispatch(success(order));
      return order;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(order) {
    return { type: orderConstants.GET_ORDER_REQUEST, order };
  }
  function success(order) {
    return { type: orderConstants.GET_ORDER_SUCCESS, order };
  }
  function failure(error) {
    return { type: orderConstants.GET_ORDER_FAILURE, error };
  }
}
