export const orderConstants = {
  POST_ORDER_REQUEST: "ORDERS_POST_REQUEST",
  POST_ORDER_SUCCESS: "ORDERS_POST_SUCCESS",
  POST_ORDER_FAILURE: "ORDERS_POST_FAILURE",

  GET_ORDER_REQUEST: "ORDERS_GET_REQUEST",
  GET_ORDER_SUCCESS: "ORDERS_GET_SUCCESS",
  GET_ORDER_FAILURE: "ORDERS_GET_FAILURE",

  GET_ORDER_BY_ID_REQUEST: "ORDERS_GET_BY_ID_REQUEST",
  GET_ORDER_BY_ID_SUCCESS: "ORDERS_GET_BY_ID_SUCCESS",
  GET_ORDER_BY_ID_FAILURE: "ORDERS_GET_BY_ID_FAILURE"
};
