import React from "react";
import ReactDOM from "react-dom";
import RouterComponent from "components/RouterComponent/RouterComponent";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
	gtmId: 'GTM-NGXMQNG'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <RouterComponent />
    ,
    document.getElementById("root")
);
