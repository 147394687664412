/* eslint-disable */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import jobPostingPageStyle
    from "assets/jss/material-kit-pro-react/views/jobPostingPageStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../../actions";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import CustomDateTimeComponent from 'components/CustomDateTimeComponent/CustomDateTimeComponent'
import CustomDateTimeEndComponent from 'components/CustomDateTimeComponent/CustomDateTimeEndComponent'

class Step2Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    render() {
        const inputsErrors = (
            this.props.dateTimeError
            || this.props.dateTimeEndError
            || this.props.cityError
            || this.props.zipCodeError
            || this.props.addressError
        );

        const inputsEmpty = (
            this.props.dateTime === ""
            || this.props.dateTimeEnd === ""
            || this.props.city === ""
            || this.props.zipCode === ""
            || this.props.address === ""
        );
        const inputsDisabled = !inputsErrors && !inputsEmpty;
        const {classes} = this.props;
        return (
            <GridItem xs={12} sm={12} md={12}>
                <Card
                    className={classes.textCenter + " "
                    + classes.marginAuto + " "
                    + classes.cardWidth + " "
                    + classes.step2Card
                    }>
                    <CardBody>
                        <h3 className={classes.cardTitle}>
                            <p
                                className={classes.cardTitleText}
                            >Address</p>
                            <p
                                className={classes.cardTitleText}
                            >step 2 of 3</p>
                        </h3>
                        <div className={
                            classes.marginBottom20
                        }></div>

                        <label
                            className={classes.labelForCalendars}
                        >{'Please specify a range that will work for you.*'}</label><br/>

                        <div className={
                            classes.datepickerContainer
                        }>
                            <CustomDateTimeComponent
                                onChange={e => this.props.handleDateChange(e, 'dateTime')}
                                onBlur={e => this.props.handleBlur(e, 'dateTime')}
                                placeholder="Date Start *"
                                value={this.props.dateTime}
                                inputProps={{
                                    error: this.props.dateTimeError,
                                    errormessage: this.props.dateTimeErrorMessage,
                                }}
                            />
                        </div>
                        <div className={
                            classes.datepickerContainer
                        }>
                            <CustomDateTimeComponent
                                onChange={e => this.props.handleDateChange(e, 'dateTimeEnd')}
                                onBlur={e => this.props.handleBlur(e, 'dateTimeEnd')}
                                placeholder="Date End *"
                                value={this.props.dateTimeEnd}
                                dateTime={this.props.dateTime}
                                inputProps={{
                                    error: this.props.dateTimeEndError,
                                    errormessage: this.props.dateTimeEndErrorMessage,
                                }}
                            />
                        </div>
                        <div className={
                            classes.marginBottom20
                        }></div>
                        <label
                            className={classes.labelForCalendars}
                        >{"If you've booked a job within 14 days of today's date by continuing you confirm you understand that you are not entitled to a cooling off period described in the terms and conditions"}</label><br/>
                        <div
                            className={
                                classes.checkboxAndRadio + " "
                                + classes.checkboxAndRadioHorizontal + " "
                                + classes.addressWidth
                            }>
                            <FormControlLabel
                                control={
                                    <CustomInput
                                        labelText="Address *"
                                        id="address"
                                        inputProps={{
                                            fullWidth: true,
                                            value: this.props.address,
                                            onChange: e => this.props.handleChange(e, 'address'),
                                            onBlur: e => this.props.handleBlur(e, 'address'),
                                            error: this.props.addressError,
                                            errormessage: this.props.addressErrorMessage
                                        }}
                                    />
                                }
                            />
                        </div>
                        <div
                            className={
                                classes.checkboxAndRadio + " "
                                + classes.checkboxAndRadioHorizontal + " "
                                + classes.cityWidth
                            }>
                            <FormControlLabel
                                control={
                                    <CustomInput
                                        labelText="City *"
                                        id="city"
                                        inputProps={{
                                            fullWidth: true,
                                            value: this.props.city,
                                            onChange: e => this.props.handleChange(e, 'city'),
                                            onBlur: e => this.props.handleBlur(e, 'city'),
                                            error: this.props.cityError,
                                            errormessage: this.props.cityErrorMessage
                                        }}
                                    />
                                }
                            />
                        </div>
                        <div
                            className={
                                classes.checkboxAndRadio + " "
                                + classes.checkboxAndRadioHorizontal + " "
                                + classes.zipCodeWidth
                            }>
                            <FormControlLabel
                                control={
                                    <CustomInput
                                        labelText="Postcode *"
                                        id="zip-code"
                                        inputProps={{
                                            fullWidth: true,
                                            value: this.props.zipCode,
                                            onChange: e => this.props.handleChange(e, 'zipCode'),
                                            onBlur: e => this.props.handleBlur(e, 'zipCode'),
                                            error: this.props.zipCodeError,
                                            errormessage: this.props.zipCodeErrorMessage
                                        }}
                                    />
                                }
                            />
                        </div>
                        

                        <div className={
                            classes.marginBottom20
                        }></div>


                        <Button onClick={e => this.props.handleUpdateClickStepTwo(e)}
                                disabled={!inputsDisabled}
                                color="primary">Update</Button>


                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user
        }
    }, {
        getUser: userActions.getUser
    }
)(withStyles(jobPostingPageStyle)(Step2Card));
