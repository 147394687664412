import React from "react";
import {urlConstants} from "../../constants/api-url";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { connect } from "react-redux";
import { userActions } from "../../actions/user.actions.js";
import { accountTypeConstants } from "../../constants/acoount-types";
import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [1],
      multipleSelect: [],
      accountType: 1,
      email: "",
      emailError: false,
      emailErrorMessage: "",
      password: "",
      passwordError: false,
      passwordErrorMessage: "",
      passwordConfirm: "",
      passwordConfirmError: false,
      passwordConfirmErrorMessage: "",
      firstName: "",
      firstNameError: false,
      firstNameErrorMessage: "",
      lastName: "",
      lastNameError: false,
      lastNameErrorMessage: "",
      houseNumber: "",
      houseNumberError: false,
      houseNumberErrorMessage: "",
      address: "",
      addressError: false,
      addressErrorMessage: "",
      city: "",
      cityError: false,
      cityErrorMessage: "",
      phone: "",
      phoneError: false,
      phoneErrorMessage: "",
      postcode: "",
      postcodeError: false,
      postcodeErrorMessage: "",
      bestTimeToCall: 1,
      registered: false,
      userRegister: false,
      modalTitle: "",
      modalText: "",
      registerDisabled: false,
      selectIsOpen: false
    };
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleMultiple = event => {
    this.setState({ multipleSelect: event.target.value });
  };
  handleChangeEnabled(event) {
    this.setState({ accountType: parseInt(event.target.value) });
  }

    changeCustomInput = e => {
        const numberReg = /^\d*$/;
        if (numberReg.test(e.target.value)) {
            if(e.target.id === 'phone') this.handleChange(e, 'phone');
        }
    };

    onKeyPress(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/[e+./-]/.test(keyValue)) {
            event.preventDefault();
        }
    }

  handleChange = (event, name) => {
    this.setState({ [name]: event.target.value }, () => {
      const value = this.state[name];
    });
  };
  handleClickOpen(modal, title, text) {
    var x = [];
    x[modal] = true;
    this.setState(x);
    this.setState({ modalTitle: title });
    this.setState({ modalText: text });

  }
    handleBlur = (event, name) => {
      const value = this.state[name];

        switch (name) {
        case 'phone':
            if ( this.state.phone === "")
            {
              this.setState({[name + 'Error']: true});
              this.setState({[name + 'ErrorMessage']: 'Phone is mandatory field'});
            } else if(this.state.phone.length !== 11 && this.state.phone !== '') {
              this.setState({[name + 'Error']: true});
              this.setState({[name + 'ErrorMessage']: 'Please enter a valid phone number'});
            } else {
              this.setState({[name + 'Error']: false});
          }
          break;
        case "email":
          let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (emailReg.test(value)) {
            this.setState({ [name + "Error"]: false });
          } else {
            this.setState({ [name + "Error"]: true });
            this.setState({ [name + "ErrorMessage"]: "Email is not valid" });
          }
          break;
        case "password":
          if(this.state.password !== this.state.passwordConfirm && this.state.passwordConfirm !== '') {
            this.setState({ ["passwordConfirmError"]: true });
            this.setState({
              ["passwordConfirmErrorMessage"]:
                  "Confirm Password and Password do not match"
            });
          } else {
            this.setState({ ["passwordConfirmError"]: false });
            this.setState({ ["passwordConfirmErrorMessage"]: "" });
          }
          if(value === "") {
            this.setState({ ["passwordError"]: true });
            this.setState({
              ["passwordErrorMessage"]: "Password is mandatory field"
            });
          } else if (value.length < 6) {
            this.setState({ ["passwordError"]: true });
            this.setState({
              ["passwordErrorMessage"]: "Password too short"
            });
          } else if (value.length >= 6) {
            this.setState({ ["passwordError"]: false });
          }
          break;
        case "passwordConfirm":
          const password = this.state.password;
          const passwordConfirm = this.state.passwordConfirm;

          if(value === "") {
            this.setState({ ["passwordConfirmError"]: true });
            this.setState({
              ["passwordConfirmErrorMessage"]: "Confirm password is mandatory field"
            });
          } else if (
              passwordConfirm !== "" &&
              passwordConfirm !== password
          ) {
            this.setState({ ["passwordConfirmError"]: true });
            this.setState({
              ["passwordConfirmErrorMessage"]:
                  "Confirm Password and Password do not match"
            });
          } else {
            this.setState({ ["passwordConfirmError"]: false });
          }
          break;

        case "postcode":
          if(value === "") {
            this.setState({ [name + "Error"]: true });
            this.setState({
              [name + "ErrorMessage"]: "Postcode is mandatory field"
            });
          } else {
            const numberReg = /^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
            if (numberReg.test(value)) {
              this.setState({ [name + "Error"]: false });
            } else {
              this.setState({ [name + "Error"]: true });
              this.setState({
                [name + "ErrorMessage"]: "Postcode is not valid"
              });
            }
          } 
          break;
        case "houseNumber":
          if (value === "") {
            this.setState({ [name + "Error"]: true });
            this.setState({
              [name + "ErrorMessage"]: "House number is mandatory field"
            });
          } else {
            const numberReg = /^\d*$/;
            if (numberReg.test(value)) {
              this.setState({ [name + "Error"]: false });
            } else {
              this.setState({ [name + "Error"]: true });
              this.setState({
                [name + "ErrorMessage"]: "House number is not valid"
              });
            }
          }
          break;
        default:
          if (value === "") {
            switch (name) {
              case "firstName":
                this.setState({ [name + "Error"]: true });
                this.setState({
                  [name + "ErrorMessage"]: "First name must not be blank"
                });
                break;

              case "lastName":
                this.setState({ [name + "Error"]: true });
                this.setState({
                  [name + "ErrorMessage"]: "Last name must not be blank"
                });
                break;

              case "city":
                this.setState({ [name + "Error"]: true });
                this.setState({
                  [name + "ErrorMessage"]: "City is mandatory field"
                });
                break;

              case "address":
                this.setState({ [name + "Error"]: true });
                this.setState({
                  [name + "ErrorMessage"]: "Address is mandatory field"
                });
                break;

              default:
                break;
            }
          } else {
            this.setState({ [name + "Error"]: false });
          }
          break;
      }
    };
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleClick() {
    if(!this.checkValidData()) return;

    if (this.state.accountType === 1) {
      const user = {
        email: this.state.email,
        password: this.state.password,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        phone: this.state.phone,
        houseNumber: this.state.houseNumber,
        address: this.state.address,
        city: this.state.city,
        postcode: this.state.postcode,
        time_to_call: this.state.phone === "" ? 0 : this.bestTimeToCall,
        account_type: this.state.accountType
      };
      this.setState({ registerDisabled: true });
      this.props.register(user).then(
        res => {
          this.setState({ registerDisabled: false });
          this.setState({ registered: true });
        },
        err => {
          this.setState({ emailError: true });
          this.setState({ emailErrorMessage: err });
          this.setState({ registerDisabled: false });
        }
      );
    } else if (this.state.accountType === 2) {
      const user = {
        email: this.state.email,
        password: this.state.password,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        phone: this.state.phone,
        house_number: this.state.houseNumber,
        address: this.state.address,
        city: this.state.city,
        postcode: this.state.postcode,
        time_to_call: this.state.phone === "" ? 0 : this.bestTimeToCall,
        account_type: this.state.accountType
      };
      this.setState({ registerDisabled: true });
      this.props.register(user).then(
        res => {
          this.setState({ registerDisabled: false });
          this.setState({ registered: true });
        },
        err => {
          this.setState({ emailError: true });
          this.setState({ emailErrorMessage: err });
          this.setState({ registerDisabled: false });
        }
      );
    }
  }

  checkValidData() {
      if(this.state.accountType === 1) {
          return this.state.email !== "" &&
              this.state.password !== "" &&
              this.state.passwordConfirm !== "" &&
              this.state.firstName !== "" &&
              this.state.lastName !== "" &&
              this.state.phone !== "" &&
              !this.state.emailError &&
              !this.state.passwordError &&
              !this.state.passwordConfirmError &&
              !this.state.firstNameError &&
              !this.state.lastNameError &&
              !this.state.phoneError
      } else if(this.state.accountType === 2){
          return this.state.email !== "" &&
              this.state.password !== "" &&
              this.state.passwordConfirm !== "" &&
              this.state.firstName !== "" &&
              this.state.lastName !== "" &&
              this.state.phone !== "" &&
              this.state.houseNumber !== "" &&
              this.state.address !== "" &&
              this.state.city !== "" &&
              this.state.postcode !== "" &&
              !this.state.emailError &&
              !this.state.passwordError &&
              !this.state.passwordConfirmError &&
              !this.state.firstNameError &&
              !this.state.lastNameError &&
              !this.state.houseNumberError &&
              !this.state.addressError &&
              !this.state.cityError &&
              !this.state.postcodeError &&
              !this.state.phoneError
      }
  }

  handleTouch = (e) => {
    document.body.ontouchmove = function(e){return true; };
    if(this.state.selectIsOpen === false) {
      document.body.ontouchmove = function(e){ e.preventDefault(); };
      this.setState({selectIsOpen: true});
    } else {
      document.body.ontouchmove = function(e){return true; };
      this.setState({selectIsOpen: false});
    }
  };

  render() {
    const {classes, ...rest} = this.props;
    let isDisabled = this.checkValidData();

    return (
        <div>
          <Header
              absolute
              logoLink={urlConstants.LANDING_URL}
              color="transparent"
              brand="TEC MOW"
              links={<HeaderLinks dropdownHoverColor="rose"/>}
              {...rest}
          />
          <div className={classes.pageHeader}>
            {!this.state.registered && (
                <div className={classes.container}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={10} lg={8}>
                      <Card className={classes.cardSignup}>
                        <h2
                            className={
                              classes.cardTitle + " " + classes.cardHeaderText
                            }
                        >
                          Sign up page
                        </h2>
                        <CardBody className={classes.marginTop30}>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={10} lg={8}>
                              <div className={classes.textCenter}>
                                <span>Already have an account? </span>
                                <span>
                              <a
                                  className={
                                    classes.greenText + " " + classes.font700
                                  }
                                  href="/login-page"
                              >
                                Login
                              </a>
                            </span>
                              </div>
                              <form className={classes.form}>
                                <FormControlLabel
                                    control={
                                      <Radio
                                          checked={
                                            this.state.accountType ===
                                            accountTypeConstants.customer
                                          }
                                          onChange={this.handleChangeEnabled}
                                          value={accountTypeConstants.customer}
                                          name="customer-account"
                                          aria-label="A"
                                          icon={
                                            <FiberManualRecord
                                                className={classes.radioUnchecked}
                                            />
                                          }
                                          checkedIcon={
                                            <FiberManualRecord
                                                className={classes.radioChecked}
                                            />
                                          }
                                          classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                          }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label
                                    }}
                                    className={
                                      classes.noLeftMargin + " " + classes.marginTop20
                                    }
                                    label="I'm a customer"
                                />
                                <FormControlLabel
                                    control={
                                      <Radio
                                          checked={
                                            this.state.accountType ===
                                            accountTypeConstants.contractor
                                          }
                                          onChange={this.handleChangeEnabled}
                                          value={accountTypeConstants.contractor}
                                          name="contractor-account"
                                          aria-label="B"
                                          icon={
                                            <FiberManualRecord
                                                className={classes.radioUnchecked}
                                            />
                                          }
                                          checkedIcon={
                                            <FiberManualRecord
                                                className={classes.radioChecked}
                                            />
                                          }
                                          classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                          }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label
                                    }}
                                    className={
                                      classes.noLeftMargin + " " + classes.marginTop20
                                    }
                                    label="I'm a contractor"
                                />
                                <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.customFormControlClasses
                                    }}
                                    inputProps={{
                                      startAdornment: (
                                          <InputAdornment
                                              position="start"
                                              className={classes.inputAdornment}
                                          >
                                            <Email
                                                className={classes.inputAdornmentIcon}
                                            />
                                          </InputAdornment>
                                      ),
                                      value: this.state.email,
                                      placeholder: "Email...",
                                      onChange: e => this.handleChange(e, "email"),
                                      onBlur: e => this.handleBlur(e, "email"),
                                      error: this.state.emailError,
                                      errormessage: this.state.emailErrorMessage
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.customFormControlClasses
                                    }}
                                    inputProps={{
                                      startAdornment: (
                                          <InputAdornment
                                              position="start"
                                              className={classes.inputAdornment}
                                          >
                                            <Icon
                                                className={classes.inputAdornmentIcon}
                                            >
                                              lock_outline
                                            </Icon>
                                          </InputAdornment>
                                      ),
                                      type: "password",
                                      value: this.state.password,
                                      placeholder: "Password...",
                                      onChange: e => this.handleChange(e, "password"),
                                      onBlur: e => this.handleBlur(e, "password"),
                                      error: this.state.passwordError,
                                      errormessage: this.state.passwordErrorMessage
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.customFormControlClasses
                                    }}
                                    inputProps={{
                                      startAdornment: (
                                          <InputAdornment
                                              position="start"
                                              className={classes.inputAdornment}
                                          >
                                            <Icon
                                                className={classes.inputAdornmentIcon}
                                            >
                                              lock_outline
                                            </Icon>
                                          </InputAdornment>
                                      ),
                                      type: "password",
                                      placeholder: "Confirm password...",
                                      value: this.state.passwordConfirm,
                                      onChange: e =>
                                          this.handleChange(e, "passwordConfirm"),
                                      onBlur: e =>
                                          this.handleBlur(e, "passwordConfirm"),
                                      error: this.state.passwordConfirmError,
                                      errormessage: this.state
                                          .passwordConfirmErrorMessage
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.customFormControlClasses
                                    }}
                                    inputProps={{
                                      startAdornment: (
                                          <InputAdornment
                                              position="start"
                                              className={classes.inputAdornment}
                                          >
                                            <Icon
                                                className={classes.inputAdornmentIcon}
                                            >
                                              face
                                            </Icon>
                                          </InputAdornment>
                                      ),
                                      placeholder: "First Name...",
                                      value: this.state.firstName,
                                      onChange: e =>
                                          this.handleChange(e, "firstName"),
                                      onBlur: e => this.handleBlur(e, "firstName"),
                                      error: this.state.firstNameError,
                                      errormessage: this.state.firstNameErrorMessage
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.customFormControlClasses
                                    }}
                                    inputProps={{
                                      startAdornment: (
                                          <InputAdornment
                                              position="start"
                                              className={classes.inputAdornment}
                                          >
                                            <Icon
                                                className={classes.inputAdornmentIcon}
                                            >
                                              face
                                            </Icon>
                                          </InputAdornment>
                                      ),
                                      placeholder: "Last Name...",
                                      value: this.state.lastName,
                                      onChange: e => this.handleChange(e, "lastName"),
                                      onBlur: e => this.handleBlur(e, "lastName"),
                                      error: this.state.lastNameError,
                                      errormessage: this.state.lastNameErrorMessage
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                      fullWidth: true,
                                      className: classes.customFormControlClasses
                                    }}
                                    id="phone"
                                    inputProps={{
                                      startAdornment: (
                                          <InputAdornment
                                              position="start"
                                              className={classes.inputAdornment}
                                          >
                                            <Icon
                                                className={classes.inputAdornmentIcon}
                                            >
                                              phone
                                            </Icon>
                                          </InputAdornment>
                                      ),
                                      placeholder: "Phone...",
                                      value: this.state.phone,
                                      onChange: this.changeCustomInput,
                                      onKeyPress: this.onKeyPress.bind(this),
                                      onBlur: e => this.handleBlur(e, 'phone'),
                                      error: this.state.phoneError,
                                      errormessage: this.state.phoneErrorMessage
                                    }}
                                />
                                {this.state.accountType == "2" ? (
                                    <CustomInput
                                        formControlProps={{
                                          fullWidth: true,
                                          className: classes.customFormControlClasses
                                        }}
                                        inputProps={{
                                          startAdornment: (
                                              <InputAdornment
                                                  position="start"
                                                  className={classes.inputAdornment}
                                              >
                                                <Icon
                                                    className={classes.inputAdornmentIcon}
                                                >
                                                  money
                                                </Icon>
                                              </InputAdornment>
                                          ),
                                          placeholder: "House number...",
                                          value: this.state.houseNumber,
                                          onChange: e =>
                                              this.handleChange(e, "houseNumber"),
                                          onBlur: e =>
                                              this.handleBlur(e, "houseNumber"),
                                          error: this.state.houseNumberError,
                                          errormessage: this.state
                                              .houseNumberErrorMessage
                                        }}
                                    />
                                ) : null}
                                {this.state.accountType == "2" ? (
                                    <CustomInput
                                        formControlProps={{
                                          fullWidth: true,
                                          className: classes.customFormControlClasses
                                        }}
                                        inputProps={{
                                          startAdornment: (
                                              <InputAdornment
                                                  position="start"
                                                  className={classes.inputAdornment}
                                              >
                                                <Icon
                                                    className={classes.inputAdornmentIcon}
                                                >
                                                  home
                                                </Icon>
                                              </InputAdornment>
                                          ),
                                          placeholder: "Street name...",
                                          value: this.state.address,
                                          onChange: e =>
                                              this.handleChange(e, "address"),
                                          onBlur: e => this.handleBlur(e, "address"),
                                          error: this.state.addressError,
                                          errormessage: this.state.addressErrorMessage
                                        }}
                                    />
                                ) : null}
                                {this.state.accountType == "2" ? (
                                    <CustomInput
                                        formControlProps={{
                                          fullWidth: true,
                                          className: classes.customFormControlClasses
                                        }}
                                        inputProps={{
                                          startAdornment: (
                                              <InputAdornment
                                                  position="start"
                                                  className={classes.inputAdornment}
                                              >
                                                <Icon
                                                    className={classes.inputAdornmentIcon}
                                                >
                                                  location_city
                                                </Icon>
                                              </InputAdornment>
                                          ),
                                          placeholder: "City...",
                                          value: this.state.city,
                                          onChange: e => this.handleChange(e, "city"),
                                          onBlur: e => this.handleBlur(e, "city"),
                                          error: this.state.cityError,
                                          errormessage: this.state.cityErrorMessage
                                        }}
                                    />
                                ) : null}
                                {this.state.accountType == "2" ? (
                                    <CustomInput
                                        formControlProps={{
                                          fullWidth: true,
                                          className: classes.customFormControlClasses
                                        }}
                                        inputProps={{
                                          startAdornment: (
                                              <InputAdornment
                                                  position="start"
                                                  className={classes.inputAdornment}
                                              >
                                                <Icon
                                                    className={classes.inputAdornmentIcon}
                                                >
                                                  code
                                                </Icon>
                                              </InputAdornment>
                                          ),
                                          placeholder: "Postcode...",
                                          value: this.state.postcode,
                                          onChange: e =>
                                              this.handleChange(e, "postcode"),
                                          onBlur: e => this.handleBlur(e, "postcode"),
                                          error: this.state.postcodeError,
                                          errormessage: this.state.postcodeErrorMessage
                                        }}
                                    />
                                ) : null}
                                {this.state.phone !== "" && (
                                    <FormControl
                                        fullWidth
                                        className={
                                          classes.selectFormControl +
                                          " " +
                                          classes.marginBottom50
                                        }
                                    >
                                      <InputLabel
                                          htmlFor="simple-select"
                                          className={classes.selectLabel}
                                      >
                                        Best time to call
                                      </InputLabel>
                                      <Select
                                          MenuProps={{
                                            className: classes.selectMenu
                                          }}
                                          classes={{
                                            select: classes.select
                                          }}
                                          value={this.state.bestTimeToCall}
                                          onChange={this.handleSimple}
                                          inputProps={{
                                            name: "bestTimeToCall",
                                            id: "best-time-to-call"
                                          }}
                                          onClick={e=>this.handleTouch(e)}
                                      >
                                        <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected
                                            }}
                                            value="1"
                                        >
                                          8AM - 10AM
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected
                                            }}
                                            value="2"
                                        >
                                          10AM - 12AM
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected
                                            }}
                                            value="3"
                                        >
                                          12AM - 2PM
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected
                                            }}
                                            value="4"
                                        >
                                          2PM - 4PM
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelected
                                            }}
                                            value="5"
                                        >
                                          4PM - 6PM
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                )}
                                <div className={classes.textCenter}>
                                  <Button
                                      round
                                      color="primary"
                                      onClick={e => this.handleClick()}
                                      disabled={!isDisabled}
                                  >
                                    Register
                                  </Button>
                                </div>
                              </form>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
            )}

            {this.state.registered && (
                <div className={classes.container}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={8} md={8}>
                      <Card className={classes.cardSignup}>
                        <h2 className={classes.cardTitle}>
                          Successful registration
                        </h2>
                        <CardBody className={classes.marginTop30}>
                          <GridContainer justify="center">
                            <GridItem xs={12} sm={6} md={6}>
                              <div>
                                Thank you! Please, click the confirmation link in
                                the email to complete your registration.
                              </div>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
            )}

            <div>
              <Dialog
                  classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                  }}
                  open={this.state.userRegister}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => this.handleClose("userRegister")}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
              >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                  <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
                </DialogTitle>
                <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                >
                  <p>{this.state.modalText}</p>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                  <Button
                      onClick={() => this.handleClose("userRegister")}
                      color="primary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

            <Footer
                className={
                  classes.footer +
                  " " +
                  (this.state.registered ? classes.positionAbsolute : "")
                }
                content={
                  <div>
                    <div className={classes.left}>
                      <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                          <a
                              href="https://www.creative-tim.com/"
                              className={classes.block}
                          >
                            Creative Tim
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                              href="https://www.creative-tim.com/presentation"
                              className={classes.block}
                          >
                            About us
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                              href="//blog.creative-tim.com/"
                              className={classes.block}
                          >
                            Blog
                          </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                              href="https://www.creative-tim.com/license"
                              className={classes.block}
                          >
                            Licenses
                          </a>
                        </ListItem>
                      </List>
                    </div>
                    <div className={classes.right}>
                      &copy; {1900 + new Date().getYear()} , made with{" "}
                      <Favorite className={classes.icon}/> by{" "}
                      <a href="https://www.creative-tim.com">Creative Tim</a> for a
                      better web.
                    </div>
                  </div>
                }
            />
          </div>

          {this.state.registerDisabled && <PreloaderComponent/>}
        </div>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    register: userActions.register
  }
)(withStyles(signupPageStyle)(SignupPage));
