/* eslint-disable */
import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import jobUpdatingPageStyle from "assets/jss/material-kit-pro-react/views/jobUpdatingPageStyle.jsx";
import {connect} from "react-redux";
import {jobActions} from "../../actions";
import Step1Card from "./Steps/Step1Card.jsx";
import Step2Card from "./Steps/Step2Card.jsx";
import Step3Card from "./Steps/Step3Card.jsx";
import Step4Card from "./Steps/Step4Card.jsx"
import Step5Card from "./Steps/Step5Card.jsx"
import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent"
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import Datetime from "react-datetime";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

class JobUpdatingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: [],

            jobId: "",
            jobName: "",
            jobNameError: false,
            jobNameErrorMessage: "",
            jobDescription: "",
            jobDescriptionError: false,
            jobDescriptionErrorMessage: "",
            lawnMoving: false,
            maintainingHerbaceousBorders: false,
            gardenCoaching: false,
            gardenClearance: false,
            hedgeTrimming: false,
            pruning: false,
            lawnSize: "",
            lawnSizeError: false,
            lawnSizeErrorMessage: "",
            maintenanceFrequency: "",
            easyAccess: '',
            hours: 0,
            bags: 0,
            multiplier: '1',
            calculateHourly: '',

            dateTime: "",
            dateTimeEnd: "",
            dateTimeError: false,
            dateTimeEndError: false,
            dateTimeErrorMessage: "",
            dateTimeEndErrorMessage: "",
            city: "",
            cityError: false,
            cityErrorMessage: "",
            zipCode: "",
            zipCodeError: false,
            zipCodeErrorMessage: "",
            address: "",
            addressError: false,
            addressErrorMessage: "",
            minPrice: "25",
            maxPrice: "100",

            orderPhone: "",
            orderPhoneError: false,
            orderPhoneErrorMessage: "",
            orderBestTimeToCall: "",

            reviewFlag: false,
            isBusy: true,
                        
            step: 5,
            total: 0,
            checkTotal: 0,
            lawnMetres: 0,
            pricePerMeter: 2,
            pricePerHour: 20,
            pricePerFirstHour: 25,

            servicesString: "",
            servicesStringFlag: true,
            
            errorDialog: false
        };
        this.handleSliderChange = this.handleSliderChange.bind(this);
    }

    componentDidMount() {
        this.setState({['isBusy']: true});
        
        let jobId = this.props.match.params.id;
        this.props.getUserJobById(jobId).then(
            res => {
                const jobObj = this.props.job;

                this.setState({
                    jobId: jobId,
                    jobName: jobObj.jobName,
                    jobDescription: jobObj.jobDescription,
                    lawnMoving: jobObj.lawnMoving,
                    maintainingHerbaceousBorders: jobObj.maintainingHerbaceousBorders,
                    gardenCoaching: jobObj.gardenCoaching,
                    easyAccess: jobObj.easyAccess,
                    multiplier: jobObj.multiplier,
                    calculateHourly: jobObj.calculateHourly,
                    gardenClearance: jobObj.gardenClearance,
                    hedgeTrimming: jobObj.hedgeTrimming,
                    pruning: jobObj.pruning,
                    lawnSize: jobObj.lawnSize,
                    hours: jobObj.hours,
                    bags: jobObj.bags,
                    lawnMetres: jobObj.lawnSize,
                    maintenanceFrequency: jobObj.maintenanceFrequency,
                    dateTime: jobObj.dateTime,
                    dateTimeEnd: jobObj.dateTimeEnd,
                    city: jobObj.city,
                    total: jobObj.total,
                    zipCode: jobObj.zipCode,
                    address: jobObj.address,
                    minPrice: jobObj.minPrice,
                    maxPrice: jobObj.maxPrice,
                    orderPhone: jobObj.orderPhone,
                    orderBestTimeToCall: jobObj.orderBestTimeToCall,
                }, () => {
                    this.checkboxesArray(jobObj);
                });
            },
            err => {
                this.setState({['isBusy']: false});
                this.props.history.push({
                    pathname: '/customer-dashboard',
                    state: {
                        jobError: err
                    }
                });
            }
        );

        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }

    timeCosting(){
        let lawnSize = this.state.lawnSize;
        if (lawnSize) {
            if (lawnSize > 0 && lawnSize <= 100) {
                return 1;
            } else if (lawnSize > 100 && lawnSize <= 250) {
                return 2;
            } else if (lawnSize > 250 && lawnSize <= 500) {
                return 3;
            }
        }
    }

    clearEasyAccess = () => {
        this.setState({easyAccess: false, lawnSize: "", hours: "", bags: "", multiplier: '1'})
    };

    handleClickOpen(modal) {
        let x = [];
        x[modal] = true;
        this.setState(x);
    }

    handleClose(modal) {
        let x = [];
        x[modal] = false;
        this.setState(x);
    }

    handleSimple = (event) => {
        this.setState({ [event.target.name]: event.target.value }, ()=>{
            if (event.target.name === 'multiplier') {
                let hours = this.timeCosting();
                let sum = this.state.pricePerFirstHour +  this.state.pricePerHour * Math.ceil(hours*this.state.multiplier-1);
                this.setState({ total: this.state.checkTotal + sum});
            }
            if (event.target.name === 'lawnMoving') {
                this.setState({calculateHourly: false});
                this.setState({easyAccess: false});
            }
            if (event.target.name === 'calculateHourly') {
                this.setState({easyAccess: false});
            }
            if (event.target.name === 'easyAccess') {
                this.setState({lawnSize: ''});
                this.setState({hours: ''});
                this.setState({bags: ''});
            }
        });
    };

    handleToggle = (event, value) => {
        if (value === 'lawnMoving') {
            this.setState({
                total: 0
            });
        }

        const {checked} = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            this.setState({ [value]: true });
            newChecked.push(value);
        } else {
            if (value === 'lawnMoving') {
                this.setState({['lawnSize']: ''});
                this.setState({['maintenanceFrequency']: ''});
            }
            this.setState({ [value]: false });
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    };

    handleChange = (event, name) => {
        this.setState({[name]: event.target.value}, () => {
            const value = this.state[name];
            switch (name) {
                case 'jobName':
                    if (this.state.jobName === "") {
                        this.setState({['jobNameError']: true});
                        this.setState({['jobNameErrorMessage']: 'Job name is mandatory field'});
                    } else if (this.state.jobName.length > 25) {
                        this.setState({['jobNameError']: true});
                        this.setState({['jobNameErrorMessage']: 'You should enter maximum 25 symbols'});
                    } else {
                        this.setState({['jobNameError']: false});
                    }
                    break;

                case 'jobDescription':
                    if (this.state.jobDescription !== "" && this.state.jobDescription.length >= 25 && this.state.jobDescription.length <= 500) {
                        this.setState({['jobDescriptionError']: false});
                    } else if (this.state.jobDescription.length > 500 && this.state.jobDescription !== "") {
                        this.setState({['jobDescriptionError']: true});
                        this.setState({['jobDescriptionErrorMessage']: 'You should enter maximum 500 characters'});
                    }
                    break;

                case 'orderPhone':
                    if (this.state.orderPhone.length !== 11 && this.state.orderPhone !== '') {
                        this.setState({[name + 'Error']: true});
                        this.setState({[name + 'ErrorMessage']: 'Please enter a valid phone number'});
                    } else {
                        this.setState({[name + 'Error']: false});
                    }
                    break;

                case 'zipCode':
                    if (value === "") {
                        this.setState({['zipCodeError']: true});
                        this.setState({['zipCodeErrorMessage']: 'Postcode is mandatory field'});
                    } else if (value !== '') {
                        const numberReg = /^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
                        if (numberReg.test(value)) {
                            this.setState({[name + 'Error']: false});
                        } else {
                            this.setState({[name + 'Error']: true});
                            this.setState({[name + 'ErrorMessage']: 'Postcode is not valid'});
                        }
                    } else {
                        this.setState({[name + 'Error']: false});
                    }
                    break;

                case 'city':
                    if (this.state.city === "") {
                        this.setState({[name + 'Error']: true});
                        this.setState({[name + 'ErrorMessage']: 'City is mandatory field'});
                    } else if (this.state.city.length > 0) {
                        this.setState({['cityError']: false});
                        this.setState({['cityErrorMessage']: ''});
                    }
                    break;

                case 'address':
                    if (this.state.address === "") {
                        this.setState({[name + 'Error']: true});
                        this.setState({[name + 'ErrorMessage']: 'Address is mandatory field'});
                    } else if (this.state.address.length > 0) {
                        this.setState({['addressError']: false});
                        this.setState({['addressErrorMessage']: ''});
                    }
                    break;

                case 'lawnSize':
                    if (value !== '') {
                        const numberReg = /^\d*$/;
                        if (numberReg.test(value)) {
                            this.setState({[name + 'Error']: false});
                        } else {
                            this.setState({[name + 'Error']: true});
                            this.setState({[name + 'ErrorMessage']: 'Lawn size is not valid'});
                        }
                    } else {
                        this.setState({[name + 'Error']: false});
                    }
                    if (value > 500) {
                        this.setState({[name + 'Error']: true});
                        this.setState({[name + 'ErrorMessage']: 'Larger than 500sqm should be charged hourly'});
                    } else {
                        this.setState({[name + 'Error']: false});
                    }
                    break;

                case 'easyAccess':
                    this.setState({['lawnSizeError']: false});
                    break;
                default:
                    break;
            }
            if (
                name === 'pruning' || name === 'gardenCoaching' || name === 'maintainingHerbaceousBorders' ||
                name === 'hedgeTrimming' || name === 'gardenClearance' || name === 'lawnSize' || name === 'multiplier') {
                let hours = this.timeCosting();
                let sum = this.state.pricePerFirstHour +  this.state.pricePerHour * Math.ceil(hours*this.state.multiplier-1);
                this.setState({ total: this.state.checkTotal + sum});
            }

            if (name === 'bags' || name === 'hours') {
                let pricePerBag = 20
                if (this.state.bags <= 4) {
                    pricePerBag = 20
                } else if (this.state.bags > 4 && this.state.bags < 10) {
                    pricePerBag = 18
                } else if (this.state.bags >= 10 && this.state.bags <= 20) {
                    pricePerBag = 15
                } else if (this.state.bags > 20) {
                    pricePerBag = 12.5
                }

                let bagsSum = pricePerBag * this.state.bags;

                let checkTotal = 0;
                if (this.state.hours > 0) {
                    checkTotal = this.state.pricePerFirstHour + this.state.pricePerHour * (this.state.hours - 1) + bagsSum;
                } else {
                    checkTotal = 0;
                }

                this.setState({checkTotal});
                this.setState({total: checkTotal});
            }

        });
    };

    handleBlur = (event, name) => {
            switch (name) {
                case 'dateTime':
                    if (this.state.dateTime === "") {
                        this.setState({['dateTimeError']: true});
                        this.setState({['dateTimeErrorMessage']: 'Date is mandatory field'});
                    } else if (this.state.dateTime !== ""
                        && this.state.dateTimeEnd !== ""
                        && !Datetime.moment(this.state.dateTimeEnd, "DD-MM-YYYY hh:mm A").isAfter(Datetime.moment(this.state.dateTime, "DD-MM-YYYY hh:mm A"))
                    ) {
                        this.setState({['dateTimeError']: true});
                        this.setState({['dateTimeErrorMessage']: 'Start Datetime must be lower then Date End.'});
                    } else if (this.state.dateTime !== ""
                        && this.state.dateTimeEnd !== ""
                        && Datetime.moment(this.state.dateTimeEnd, "DD-MM-YYYY hh:mm A").isAfter(Datetime.moment(this.state.dateTime, "DD-MM-YYYY hh:mm A"))) {
                        this.setState({['dateTimeError']: false});
                        this.setState({['dateTimeEndError']: false});
                    } else {
                        this.setState({['dateTimeError']: false});
                    }
                break;

                case 'dateTimeEnd':
                    if (this.state.dateTimeEnd === "") {
                        this.setState({['dateTimeEndError']: true});
                        this.setState({['dateTimeEndErrorMessage']: 'Date is mandatory field'});
                    } else if (this.state.dateTime !== ""
                        && this.state.dateTimeEnd !== ""
                        && !Datetime.moment(this.state.dateTimeEnd, "DD-MM-YYYY hh:mm A").isAfter(Datetime.moment(this.state.dateTime, "DD-MM-YYYY hh:mm A"))) {
                        this.setState({['dateTimeEndError']: true});
                        this.setState({['dateTimeEndErrorMessage']: 'End Datetime be higher then Start Datetime.'});
                    } else if (this.state.dateTime !== ""
                        && this.state.dateTimeEnd !== ""
                        && Datetime.moment(this.state.dateTimeEnd, "DD-MM-YYYY hh:mm A").isAfter(Datetime.moment(this.state.dateTime, "DD-MM-YYYY hh:mm A"))) {
                        this.setState({['dateTimeError']: false});
                        this.setState({['dateTimeEndError']: false});
                    } else {
                        this.setState({['dateTimeEndError']: false});
                    }
                    break;

                case 'city':
                    if (this.state.city === "") {
                        this.setState({['cityError']: true});
                        this.setState({['cityErrorMessage']: 'City is mandatory field'});
                    } else {
                        this.setState({['cityError']: false});
                    }
                break;

                case 'zipCode':
                    if (this.state.zipCode === "") {
                        this.setState({['zipCodeError']: true});
                        this.setState({['zipCodeErrorMessage']: 'Postcode is mandatory field'});
                    } else {
                        const numberReg = /^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
                        if (numberReg.test(this.state.zipCode)) {
                            this.setState({['zipCodeError']: false});
                        } else {
                            this.setState({['zipCodeErrorError']: true});
                            this.setState({['zipCodeErrorMessage']: 'Postcode is not valid'});
                        }
                    }
                    break;

                case 'jobDescription':
                    if (this.state.jobDescription === "") {
                        this.setState({['jobDescriptionError']: true});
                        this.setState({['jobDescriptionErrorMessage']: 'Job description is mandatory field'});
                    } else if ( this.state.jobDescription.length < 25 && this.state.jobDescription !== "") {
                        this.setState({['jobDescriptionError']: true});
                        this.setState({['jobDescriptionErrorMessage']: 'Please enter at least 25 characters'});
                    } else if ( this.state.jobDescription.length > 500 && this.state.jobDescription !== "") {
                        this.setState({['jobDescriptionError']: true});
                        this.setState({['jobDescriptionErrorMessage']: 'You should enter maximum 500 characters'});
                    } else {
                        this.setState({['jobDescriptionError']: false});
                    }
                    break;

                case 'address':
                    if (this.state.address === "") {
                        this.setState({['addressError']: true});
                        this.setState({['addressErrorMessage']: 'Address is mandatory field'});
                    } else {
                        this.setState({['addressError']: false});
                    }
                    break;

                case 'orderPhone':
                    if (this.state.orderPhone === "") {
                        this.setState({['orderPhoneError']: true});
                        this.setState({['orderPhoneErrorMessage']: 'Order phone is mandatory field'});
                    }
                    break;

                default:
                break;
            }
    };

    customValid = () => {
        const numberReg = /^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
        if (numberReg.test(this.state.zipCode)) {
            return true
        } else {
            return false;
        }
    };

    handleDateChange = (event, name, format="DD-MM-YYYY hh:mm A") => {
        typeof event === 'object' ?
            this.setState({[name]: event.format(format)})
            : this.setState({[name]: event});
    };

    handleNextClick = (event, name) => {
      this.setState({['step']: this.state.step+1}, () => {
          if (this.state.step === 5){
              this.setState({['reviewFlag']: true});
          }
      });
    };

    handleBackClick = (event, name) => {
        this.setState({['step']: this.state.step-1});
    };

    handleSliderChange() {
        const elementAttributes = document.getElementsByClassName('noUi-handle')[0].attributes;
        const minPrice = elementAttributes['aria-valuenow'].value;
        const maxPrice = elementAttributes['aria-valuemax'].value;
        this.setState({['minPrice']: parseInt(minPrice)});
        this.setState({['maxPrice']: parseInt(maxPrice)});
    }

    changeStep = (step) => {
        this.setState({['step']: step});
    };
    
    handleUpdateClick = (e) => {
        this.setState({['step']: 5});
    };
    handleUpdateClickStepTwo = (e) => {
        const valid = this.customValid();
        if (valid === true) {
            this.setState({
                ['zipCodeError']: false,
                ['zipCodeErrorMessage']: ''
            });
            this.setState({['step']: 5});
        } else {
            this.setState({
                ['zipCodeError']: true,
                ['zipCodeErrorMessage']: 'Postcode is not valid'
            });
        }
    };

    updateJob = (e) => {
        this.setState({['isBusy']: true});
        const jobObject = {
            jobName: this.state.jobName,
            jobDescription: this.state.jobDescription,
            easyAccess: this.state.easyAccess,
            hours: this.state.hours,
            bags: this.state.bags,
            multiplier: this.state.multiplier,
            lawnMoving: this.state.lawnMoving,
            calculateHourly: this.state.calculateHourly,
            maintainingHerbaceousBorders: this.state.maintainingHerbaceousBorders,
            gardenCoaching: this.state.gardenCoaching,
            gardenClearance: this.state.gardenClearance,
            hedgeTrimming: this.state.hedgeTrimming,
            pruning: this.state.pruning,
            lawnSize: this.state.lawnSize,
            maintenanceFrequency: this.state.maintenanceFrequency,
            dateTime: this.state.dateTime,
            dateTimeEnd: this.state.dateTimeEnd,
            city: this.state.city,
            total: this.state.total,
            zipCode: this.state.zipCode,
            address: this.state.address,
            minPrice: this.state.minPrice,
            maxPrice: this.state.maxPrice,
            orderPhone: this.state.orderPhone,
            orderBestTimeToCall: this.state.orderBestTimeToCall,
            orderProposals: 0
        };

        this.props.updateUserJob(this.state.jobId, jobObject).then(
            res => {
                this.setState({['isBusy']: false});
                // localStorage.setItem('jobSuccess', );

                this.props.history.push({
                    pathname: '/customer-dashboard',
                    state: {
                        jobSuccess: "Job has been updated"
                    }
                });
            },
            err => {
                this.setState({['isBusy']: false});
                this.setState({['modalText']: err}, () => {
                    this.setState({['modalTitle']: "Error"}, () => {
                        this.handleClickOpen("errorDialog");
                    });
                });
                console.log(err)
            }
        );
    };

    checkboxesArray(jobObj) {
        let checkedArr = [];
        jobObj.lawnMoving ? checkedArr.push('lawnMoving') : '';
        jobObj.gardenClearance ? checkedArr.push('gardenClearance') : '';
        jobObj.hedgeTrimming ? checkedArr.push('hedgeTrimming') : '';
        jobObj.maintainingHerbaceousBorders ? checkedArr.push('maintainingHerbaceousBorders') : '';
        jobObj.gardenCoaching ? checkedArr.push('gardenCoaching') : '';
        jobObj.pruning ? checkedArr.push('pruning') : '';
        this.setState({
            'checked': checkedArr,
            'isBusy': false
        });

        this.setState({
            'checked': checkedArr,
            'isBusy': false
        });
    }

    servicesStringCreation = () => {
        let tempString = '';
        if (this.state.lawnMoving) {
            tempString = tempString.concat(" Lawn Mowing, ")
        }
        if (this.state.gardenClearance) {
            tempString = tempString.concat(" Garden Clearance, ")
        }
        if (this.state.hedgeTrimming) {
            tempString = tempString.concat(" Hedge Trimming, ")
        }
        if (this.state.pruning) {
            tempString = tempString.concat(" Pruning, ")
        }
        if (this.state.maintainingHerbaceousBorders) {
            tempString = tempString.concat(" Maintaining herbaceous borders, ")
        }
        if (this.state.gardenCoaching) {
            tempString = tempString.concat(" Garden Coaching, ")
        }
        tempString = tempString.substr(0, tempString.length - 2);

        this.setState({'servicesString': tempString}, () => {
            this.setState({'servicesStringFlag': true});
        });
    };

    render() {
        const {classes, ...rest} = this.props;
        return (
            <div>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <GridContainer justify="center" className={
                            classes.paddingTop20 + " "
                            + classes.divHeight + " " +
                            classes.paddingBottom20
                        }>

                            {
                                this.state.step === 1 &&

                                <Step1Card
                                checked={this.state.checked}
                                jobName={this.state.jobName}
                                jobNameError={this.state.jobNameError}
                                jobNameErrorMessage={this.state.jobNameErrorMessage}
                                jobDescription={this.state.jobDescription}
                                jobDescriptionError={this.state.jobDescriptionError}
                                jobDescriptionErrorMessage={this.state.jobDescriptionErrorMessage}
                                lawnMoving={this.state.lawnMoving}
                                maintainingHerbaceousBorders={this.state.maintainingHerbaceousBorders}
                                gardenCoaching={this.state.gardenCoaching}
                                multiplier={this.state.multiplier}
                                calculateHourly={this.state.calculateHourly}
                                easyAccess={this.state.easyAccess}
                                gardenClearance={this.state.gardenClearance}
                                hedgeTrimming={this.state.hedgeTrimming}
                                pruning={this.state.pruning}
                                lawnSize={this.state.lawnSize}
                                hours={this.state.hours}
                                bags={this.state.bags}
                                total={this.state.total}
                                lawnSizeError={this.state.lawnSizeError}
                                lawnSizeErrorMessage={this.state.lawnSizeErrorMessage}
                                maintenanceFrequency={this.state.maintenanceFrequency}
                                handleSimple={this.handleSimple}
                                handleToggle={this.handleToggle}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleNextClick={this.handleNextClick}
                                handleBackClick={this.handleBackClick}
                                clearEasyAccess={this.clearEasyAccess}
                                reviewFlag={this.state.reviewFlag}
                                handleUpdateClick={this.handleUpdateClick}
                            />
                            }
                            {
                                this.state.step === 2 &&
                                <Step2Card
                                dateTime={this.state.dateTime}
                                dateTimeEnd={this.state.dateTimeEnd}
                                dateTimeError={this.state.dateTimeError}
                                dateTimeEndError={this.state.dateTimeEndError}
                                dateTimeErrorMessage={this.state.dateTimeErrorMessage}
                                dateTimeEndErrorMessage={this.state.dateTimeEndErrorMessage}
                                city={this.state.city}
                                cityError={this.state.cityError}
                                cityErrorMessage={this.state.cityErrorMessage}
                                zipCode={this.state.zipCode}
                                zipCodeError={this.state.zipCodeError}
                                zipCodeErrorMessage={this.state.zipCodeErrorMessage}
                                address={this.state.address}
                                addressError={this.state.addressError}
                                addressErrorMessage={this.state.addressErrorMessage}
                                handleBlur={this.handleBlur}
                                handleSimple={this.handleSimple}
                                handleToggle={this.handleToggle}
                                handleChange={this.handleChange}
                                handleDateChange={this.handleDateChange}
                                handleNextClick={this.handleNextClick}
                                handleBackClick={this.handleBackClick}
                                reviewFlag={this.state.reviewFlag}
                                handleUpdateClick={this.handleUpdateClick}
                                handleUpdateClickStepTwo={this.handleUpdateClickStepTwo}
                            />
                            }
                            {
                                this.state.step === 3 &&
                                <Step3Card
                                    minPrice={this.state.minPrice}
                                    maxPrice={this.state.maxPrice}
                                    handleSliderChange={this.handleSliderChange}
                                    handleNextClick={this.handleNextClick}
                                    handleBackClick={this.handleBackClick}
                                    reviewFlag={this.state.reviewFlag}
                                    handleUpdateClick={this.handleUpdateClick}
                                />
                            }
                            {
                                this.state.step === 4 &&
                                <Step4Card
                                    orderPhone={this.state.orderPhone}
                                    orderPhoneError={this.state.orderPhoneError}
                                    orderPhoneErrorMessage={this.state.orderPhoneErrorMessage}
                                    orderBestTimeToCall={this.state.orderBestTimeToCall}
                                    handleSimple={this.handleSimple}
                                    handleChange={this.handleChange}
                                    handleBlur={this.handleBlur}
                                    handleNextClick={this.handleNextClick}
                                    handleBackClick={this.handleBackClick}
                                    reviewFlag={this.state.reviewFlag}
                                    handleUpdateClick={this.handleUpdateClick}
                                />
                            }
                            {
                                this.state.step === 5 &&
                                <Step5Card
                                    jobName={this.state.jobName}
                                    jobDescription={this.state.jobDescription}
                                    lawnMoving={this.state.lawnMoving}
                                    gardenClearance={this.state.gardenClearance}
                                    hedgeTrimming={this.state.hedgeTrimming}
                                    pruning={this.state.pruning}
                                    maintainingHerbaceousBorders={this.state.maintainingHerbaceousBorders}
                                    gardenCoaching={this.state.gardenCoaching}
                                    lawnSize={this.state.lawnSize}
                                    hours={this.state.hours}
                                    bags={this.state.bags}
                                    maintenanceFrequency={this.state.maintenanceFrequency}
                                    dateTime={this.state.dateTime}
                                    dateTimeEnd={this.state.dateTimeEnd}
                                    city={this.state.city}
                                    zipCode={this.state.zipCode}
                                    address={this.state.address}
                                    minPrice={this.state.minPrice}
                                    maxPrice={this.state.maxPrice}
                                    total={this.state.total}
                                    orderPhone={this.state.orderPhone}
                                    orderBestTimeToCall={this.state.orderBestTimeToCall}
                                    changeStep={this.changeStep}
                                    updateJob={this.updateJob}
                                    reviewFlag={this.state.reviewFlag}
                                    handleUpdateClick={this.handleUpdateClick}
                                    servicesString={this.state.servicesString}
                                    servicesStringFlag={this.servicesStringFlag}
                                    servicesStringCreation={this.servicesStringCreation}
                                />
                            }
                        </GridContainer>
                        <div
                            className={classNames(classes.description, classes.textCenter)}
                        >
                        </div>
                        <Clearfix/>
                    </div>
                </div>

                {
                    this.state.isBusy &&
                    <PreloaderComponent/>
                }

                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal
                    }}
                    open={this.state.errorDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("errorDialog")}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={
                            classes.modalHeader
                        }
                    >
                        <h4 className={classes.modalTitle + " "
                            + classes.errorModalTitle
                        }>{ this.state.modalTitle }</h4>
                    </DialogTitle>
                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classes.modalBody}
                    >
                        <p>{ this.state.modalText }</p>
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                        <Button
                            onClick={() => this.handleClose("errorDialog")}
                            color="danger"
                            className={
                                classes.marginAuto
                            }
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user,
            job: store.jobs.job
        }
    }, {
        getUserJobById: jobActions.getUserJobById,
        updateUserJob: jobActions.updateUserJob
    }
)(withStyles(jobUpdatingPageStyle)(JobUpdatingPage));
