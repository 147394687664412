/* eslint-disable */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import jobPostingPageStyle
    from "assets/jss/material-kit-pro-react/views/jobPostingPageStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../../actions";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import FormControlLabel from "@material-ui/core/FormControlLabel";

class Step4Card extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectIsOpen: false
        };
    }

    componentDidMount() {
    }

    changeCustomInput = e => {
        console.log('aaaa')
        const numberReg = /^\d*$/;
        if (numberReg.test(e.target.value)) {
            if(e.target.id === 'order-phone') this.props.handleChange(e, 'orderPhone');
        }
    };

    onKeyPress(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/[e+./-]/.test(keyValue)) {
            event.preventDefault();
        }
    }

    handleTouch = (e) => {
        document.body.ontouchmove = function(e){return true; };
        if(this.state.selectIsOpen === false) {
            document.body.ontouchmove = function(e){ e.preventDefault(); };
            this.setState({selectIsOpen: true});
        } else {
            document.body.ontouchmove = function(e){return true; };
            this.setState({selectIsOpen: false});
        }
    };

    render() {
        const isDisabled = this.props.orderPhoneError || !this.props.orderPhone;
        const {classes} = this.props;
        return (
            <GridItem xs={12} sm={12} md={12}>
                <Card
                    className={classes.textCenter + " "
                    + classes.marginAuto + " "
                    + classes.cardWidth + " "
                    + classes.step4Card
                    }>
                    <CardBody>
                        <h3 className={classes.cardTitle}>
                            <p
                                className={classes.cardTitleText}
                            >Contact</p>
                            <p
                                className={classes.cardTitleText}
                            >step 3 of 3</p>
                        </h3>
                        <div className={
                            classes.marginBottom20
                        }></div>

                        <div
                            className={
                                classes.checkboxAndRadio + " "
                                + classes.checkboxAndRadioHorizontal + " "
                                + classes.orderPhone
                            }>
                            <FormControlLabel
                                control={
                                    <CustomInput
                                        labelText="Contact phone"
                                        id="order-phone"
                                        inputProps={{
                                            fullWidth: true,
                                            value: this.props.orderPhone,
                                            onChange: this.changeCustomInput,
                                            onKeyPress: this.onKeyPress.bind(this),
                                            onBlur: e => this.props.handleBlur(e, 'orderPhone'),
                                            error: this.props.orderPhoneError,
                                            errormessage: this.props.orderPhoneErrorMessage
                                        }}
                                    />
                                }
                            />
                        </div>

                        <FormControl
                            fullWidth
                            className={
                                classes.selectFormControl + " "
                                + classes.orderBestTimeToCall
                            }>
                            <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}>
                                Best time to call
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}
                                value={this.props.orderBestTimeToCall}
                                onChange={e => this.props.handleSimple(e)}
                                inputProps={{
                                    name: "orderBestTimeToCall",
                                    id: "order-best-time-to-call"
                                }}
                                onClick={e=>this.handleTouch(e)}>
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    value="8AM - 10AM">
                                    8AM - 10AM
                                </MenuItem>
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    value="10AM - 12PM">
                                    10AM - 12PM
                                </MenuItem>
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    value="12PM - 2PM">
                                    12PM - 2PM
                                </MenuItem>
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    value="2PM - 4PM">
                                    2PM - 4PM
                                </MenuItem>
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    value="4PM - 6PM">
                                    4PM - 6PM
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <div className={
                            classes.marginBottom20
                        }></div>
                        {/*{*/}
                        {/*    !this.props.reviewFlag &&*/}
                        {/*    <div>*/}
                        {/*        <Button onClick={e => this.props.handleBackClick(e, 'step')}*/}
                        {/*                color="primary">Back</Button>*/}
                        {/*        <Button onClick={e => this.props.handleNextClick(e, 'step')}*/}
                        {/*                disabled={isDisabled}*/}
                        {/*                color="primary">Next</Button>*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {/*{*/}
                        {/*    this.props.reviewFlag &&*/}
                            <Button onClick={e => this.props.handleUpdateClick(e)}
                                    disabled={isDisabled}
                                    color="primary">Update</Button>
                        {/*}*/}
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user
        }
    }, {
        getUser: userActions.getUser
    }
)(withStyles(jobPostingPageStyle)(Step4Card));
