import React from "react";
import {urlConstants} from "../../constants/api-url";
import withStyles from "@material-ui/core/styles/withStyles";
import Favorite from "@material-ui/icons/Favorite";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../actions/user.actions.js";
import {Link} from "react-router-dom";

class Components extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    this.props.registerConfirm(this.props.match.params.userId);
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
        <div>
            <Header
				logoLink = {urlConstants.LANDING_URL}
                absolute
                color="transparent"
                brand="TEC MOW"
                links={
                    <HeaderLinks dropdownHoverColor="rose"/>
                }
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{}}
            >
                <div className={classes.container}>
                    <GridContainer justify="center"
                                   className={
                                       classes.height75vh
                                   }>
                        <GridItem xs={12} sm={8} md={8} className={
                            classes.transform20
                        }>
                            <Card className={classes.cardSignup}>
                                <h2 className={classes.cardTitle}>Registration confirmed</h2>
                                <CardBody
                                    className={
                                        classes.marginTop30
                                    }
                                >
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={6} md={6}
                                                  className={
                                                      classes.textCenter + " "
                                                      + classes.font20
                                                  }>
                                            <div>
                                                <span>You can now </span>
                                                <Link to={'/login-page'}
                                                      className={
                                                          classes.font700
                                                      }>
                                                    login
                                                </Link>
                                                <span>.</span>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                }

                <Footer className={
                    classes.footer + " "
                    + classes.positionAbsolute
                }
                        content={
                            <div>
                                <div className={classes.right}>
                                    &copy; {1900 + new Date().getYear()} , made with{" "}
                                    <Favorite className={classes.icon}/> by{" "}
                                    <a href="https://www.creative-tim.com">Creative Tim</a> for a
                                    better web.
                                </div>
                            </div>
                        }
                />
            </div>
        </div>
    );
  }
}

export default connect(
    store => {
        return {
            user: store.users.user
        }
    }, {
        registerConfirm: userActions.registerConfirmed
    }
)(withStyles(signupPageStyle)(Components));
