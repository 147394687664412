import React from "react";
import Pagination from "react-js-pagination";

const PaginationComponent = (props) => {
    return (
        <Pagination
            activePage={props.activePage}
            itemsCountPerPage={props.itemsCountPerPage || 10}
            totalItemsCount={props.totalItemsCount}
            pageRangeDisplayed={10}
            nextPageText={"NEXT"}
            prevPageText={"PREVIOUS"}
            itemClassPrev={"prev-pagin"}
            itemClassNext={"next-pagin"}
            itemClassFirst={"first-pagin"}
            itemClassLast={"last-pagin"}
            onChange={props.onChange}
        />
    )
}

export default PaginationComponent;