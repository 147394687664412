/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";

import Footer from "components/Footer/Footer.jsx";
import Favorite from "@material-ui/icons/Favorite";
import ProposalDetail from "./ProposalJobDetail";
import ProposalAdditional from "./ProposalJobAdditionalDetails";

import "assets/css/style.css";

import jobDetailsPageStyle from "assets/jss/material-kit-pro-react/views/jobDetailsPageStyle.jsx";
import "assets/css/style.css";
import classNames from "classnames";
import { connect } from "react-redux";
import { jobActions, userActions } from "../../../actions";
// eslint-disable-next-line react/prop-types

class ProposalJobContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      preloader: false
    };
  }

  componentDidMount() {
    if (this.props.location.state.job) {
      this.setState({ job: this.props.location.state.job });
    }
  }

  render() {
    const { classes, rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="TEC MOW"
          links={
            <HeaderLinks
              history={this.props.history}
              dropdownHoverColor="primary"
            />
          }
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "primary"
          }}
          {...rest}
        />
        <Parallax
          image={require("assets/img/tec-mow/blade-of-grass-depth.jpg")}
          filter="dark"
          className={classes.parallax}
        />
        <div className={classNames(classes.main + " fix-height-conteiner", classes.mainRaised + " m-header" + " content-center")}>
          <div
            className={
              classes.container + " " + classes.customerDashboardContainer
            }
          >
            <GridContainer
              justify="center"
              className={classes.customerNameContainer + " m-cont"}
            >
              <GridItem xs={12} sm={12} md={8} className={classes.customerName}>
                <div className={classes.follow + " " + classes.editProfile} />
                <div className={classes.profile}>
                  <div
                    className={
                      classes.name + " " + classes.customerDashboardName
                    }
                  />
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <ProposalDetail job={this.state.job} />
              <ProposalAdditional job={this.state.job} />
            </GridContainer>
            <Clearfix />
          </div>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} , made with{" "}
                <Favorite className={classes.icon} /> by{" "}
                <a href="https://www.creative-tim.com">Creative Tim</a> for a
                better web.
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    getJobById: jobActions.getJobById
  }
)(withStyles(jobDetailsPageStyle)(ProposalJobContractor));
