import {
    container,
    cardTitle,
    title,
    mlAuto,
    mrAuto,
    main,
    whiteColor,
    successColor,
    mainRaised,
    grayColor
} from "assets/jss/material-kit-pro-react.jsx";
import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.jsx";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

const customerPageStyle = {
    container,
    ...imagesStyle,
    ...tooltipsStyle,
    ...modalStyle,
    cardTitleWhite: {
        ...cardTitle,
        color: whiteColor + "  !important",
    },
    customerDashboardContainer: {
        minHeight: "78vh"
    },
    cardTitle,
    mobileWrapTableItem: {
        width: "100%",
    },
    mobileTableItem: {
        display: "flex",
        alignItems: "center",
        minHeight: "60px",
    },
    mobileTableName: {
        width: "35%",
        fontSize: "16px",
        color: "#555555",
        fontWeight: "500"
    },
    mobileTableValue: {
        width: "65%",
        fontSize: "16px",
        color: "#3C4858",
        fontWeight: "300",
        paddingLeft: "10px"
    },
    valueWrap: {
        display: "flex",
        justifyContent: "space-around"
    },
    profile: {
        textAlign: "center",
        "& img": {
            maxWidth: "160px",
            width: "100%",
            margin: "0 auto",
            transform: "translate3d(0, -50%, 0)"
        }
    },
    description: {
        margin: "1.071rem auto 0",
        maxWidth: "600px",
        color: grayColor[0],
        "@media(max-width: 959.9px)": {
            marginTop: "0px !important"
        }
    },
    textCenter: {
        textAlign: "center !important"
    },
    name: {
        marginTop: "-80px"
    },

    main: {
        ...main
    },
    mainRaised: {
        ...mainRaised,
        margin: "0px !important",
        borderRadius: "0px",
        backgroundColor: grayColor[2]
    },
    title: {
        ...title,
        position: "relative",
        marginTop: "30px",
        marginLeft: "30px",
        minHeight: "32px",
        textDecoration: "none"
    },
    follow: {
        position: "absolute",
        top: "0",
        right: "0"
    },
    followIcon: {
        width: "20px",
        height: "20px"
    },
    followButton: {
        marginTop: "-28px !important"
    },
    gridItem: {
        ...mlAuto,
        ...mrAuto
    },
    collections: {
        marginTop: "20px"
    },
    cardBody: {
        display: "flex",
        boxOrient: "vertical",
        boxDirection: "normal",
        flexDirection: "column",
        boxPack: "center",
        justifyContent: "center"
    },
    badge: {
        display: "inline-table",
        margin: "0 auto"
    },
    listUnstyled: {
        paddingLeft: "0",
        listStyle: "none",
        "& > li": {
            padding: "5px 0px",
            fontSize: "1em"
        }
    },
    profileTabs: {
        marginTop: "4.284rem",
        marginBottom: "50px"
    },
    card: {
        textAlign: "left !important"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    customerNameContainer: {
        display: "block",
        "& div": {
            "& div:nth-child(1)": {
                textAlign: "left",
                marginLeft: "30px",
            },
            "& div:nth-child(2)": {
                textAlign: "left",
                marginLeft: "30px",

                "@media(max-width:959.9px)": {
                    textAlign: "center",
                    marginLeft: "-60px",
                },

                "@media(max-width:767px)": {
                    textAlign: "center",
                    marginLeft: "-10px",
                }
            }
        }
    },
    customerName: {
        margin: "0 auto",
        display: "inline-block"
    },
    postJobGridItem: {
        display: "inline-block"
    },
    customerDashboardName: {

        marginTop: "0px",
        display: "inline-block"
    },
    editProfile: {
        top: "28px",
        left: "0px",
        "& button": {
            marginTop: "0px !important",
            marginBottom: "0px",
            padding: "6px 8px"
        }
    },
    jobPostButton: {
        marginBottom: "9px"
    },
    customerCardTitle: {
        fontSize: "25px",
        paddingLeft: "15px",
        "@media(max-width:959.9px)": {
            textAlign: "center"
        }
    },
    pagination: {
        justifyContent: "center!important",
        marginTop: "1rem"
    },
    serverModalDiv: {
        "& div:nth-child(2)>div": {
            minWidth: "200px",
            textAlign: "center",

            "& div:nth-child(3) button": {
                margin: "0 auto"
            }
        }
    },
    emptyListMessage: {
        paddingLeft: "15px"
    },
    serverModalHeader: {
      fontWeight: "800"
    },
    errorModal: {
        color: "#ff0000"
    },
    deletionConfirmation: {
        "& p": {
            fontWeight: "500",
            fontSize: "16px"
        }
    },
    deletionConfirmationActions: {
        margin: "0 auto",
        paddingBottom: "30px"
    },
    errorModalText: {
        color: "#ff0000",
        fontSize: "16px",
        fontWeight: 600
    },
    successModalText: {
        color: successColor[0],
        fontSize: "16px",
        fontWeight: 600
    },
    marginAuto: {
        margin: "0 auto"
    }
};

export default customerPageStyle;
