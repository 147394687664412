import { proposalConstants } from "../constants/proposal.constants";
import { proposalService } from "../services/proposal.service";
import { alertActions } from "./";
import { history } from "../helpers";

export const proposalActions = {
  createProposal,
  getUserJobsPaginated
};

function createProposal(proposalObj) {
  return async dispatch => {
    try {
      dispatch(request(proposalObj));
      const user = await proposalService.createProposal(proposalObj);
      dispatch(success(user));
      console.log(user)
      // history.push("/contractor-all-jobs");
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(proposal) {
    return { type: proposalConstants.POST_PROPOSAL_REQUEST, proposal };
  }
  function success(proposal) {
    return { type: proposalConstants.POST_PROPOSAL_SUCCESS, proposal };
  }
  function failure(error) {
    return { type: proposalConstants.POST_PROPOSAL_FAILURE, error };
  }
}

function getUserJobsPaginated(page) {
  return async dispatch => {
    try {
      dispatch(request());
      const userProposals = await proposalService.getJobsPaginated(page);
      dispatch(success(userProposals));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(proposal) {
    return { type: proposalConstants.GET_PROPOSAL_PAGINATED_REQUEST, proposal };
  }
  function success(proposal) {
    return { type: proposalConstants.GET_PROPOSAL_PAGINATED_SUCCESS, proposal };
  }
  function failure(error) {
    return { type: proposalConstants.GET_PROPOSAL_PAGINATED_FAILURE, error };
  }
}
