import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiAccordion from "@material-ui/core/ExpansionPanel";
import MuiAccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiAccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import Button from "../../../components/CustomButtons/Button";
import {Link} from "react-router-dom";
import Check from "@material-ui/icons/Check";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: "12px"
  }
}))(MuiAccordionDetails);

export default function MyContractAccordions(props) {
  const { jobs, submitWork, classes } = props;
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      { jobs && jobs.length > 0
          ? jobs.map((job, index) => (
              <Accordion
                  key={index}
                  square
                  expanded={expanded === `panel${index+1}`}
                  onChange={handleChange(`panel${index+1}`)}
              >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>
                    Job - {' '}
                    <Link
                        className={
                          "job-link all-jobs-name job-title job-fix-wiight"
                        }
                        to={"/my-contracts/job/" + job._id}
                    >
                      {job.jobName}
                    </Link>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.mobileWrapTableItem}>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Client
                      </div>
                      <div className={classes.mobileTableValue}>
                        {job.userId.first_name} {job.userId.last_name}
                      </div>
                    </div>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Date
                      </div>
                      <div className={classes.mobileTableValue}>
                        {job.dateTime}
                      </div>
                    </div>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Status
                      </div>
                      <div className={classes.mobileTableValue}>
                        {job.status}
                      </div>
                    </div>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Status Work
                      </div>
                      {job.status === "Active" ? (
                          <div className={classes.mobileTableValue}>
                            <Button className={"btn-submit-work"} color={"success"}
                                    onClick={() => submitWork(job._id)}>
                              Submit work
                            </Button>
                          </div>
                      ) : (
                          <div className={classes.mobileTableValue}>
                            <Check className={"verifide-color"} />
                          </div>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
          )) : null }
    </div>
  );
}
