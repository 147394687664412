/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import Edit from "@material-ui/icons/Edit";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Button from "components/CustomButtons/Button.jsx";
import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent";
import customerDashboardStyle
    from "assets/jss/material-kit-pro-react/views/customerDashboardStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import MyContractorJobs from "./MyContractor/MyContractorJobs";

import {jobActions, userActions} from "../../actions";
import {connect} from "react-redux";


class ContractorMyJobs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            activePage: 1,
            preloader: false,
            jobsLoading: true
        };
    }

    componentDidMount() {
        this.fetchJobs(this.state.activePage);
    }

    fetchJobs = (page) => {
        this.setState({ preloader: true, jobsLoading: true });
        this.props.getContractorJobsPaginated(page).then(
            res => {
                this.setState({ preloader: false, jobs: res.jobs, jobsLoading: false });
            },
            err => {
                this.setState({ preloader: false , jobsLoading: false});
            }
        );
    }

    submitWork = (id) => {
        this.props.history.push("/complete-work/"+id);
    };

    editProfileDetails(e) {
        this.props.history.push("/contractor-page");
    };

    changeActivePage = pageNumber => {
        this.setState({ activePage: pageNumber });
    }

    render() {
        const { classes, ...rest } = this.props;

        return (
            <div>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={
                        classes.container + " "
                        + classes.customerDashboardContainer
                    }>
                        <GridContainer justify="center"
                                       className={
                                           classes.customerNameContainer
                                       }>
                            <GridItem xs={12} sm={12} md={8}
                                      className={
                                          classes.customerName
                                      }
                            >
                                <div className={
                                    classes.follow + " "
                                    + classes.editProfile + " title-edit"
                                }>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="Edit profile details"
                                        placement="top"
                                        onClick={e => this.editProfileDetails(e)}
                                        classes={{tooltip: classes.tooltip + " title-edit"}}
                                    >
                                        <Button
                                            justIcon
                                            round
                                            color="primary"
                                            className={classes.followButton}
                                        >
                                            <Edit
                                                className={classes.followIcon}/>
                                        </Button>
                                    </Tooltip>
                                </div>
                                <div className={classes.profile}>
                                    <div className={
                                        classes.name + " "
                                        + classes.customerDashboardName
                                    }>
                                        <h3 className={classes.title}>
                                            {
                                                this.props.user &&

                                                this.props.user.first_name + " "
                                                + this.props.user.last_name

                                            }
                                        </h3>
                                    </div>
                                </div>

                            </GridItem>
                        </GridContainer>

                        <GridContainer>

                            <MyContractorJobs
                                jobCount={this.props.allJobsCount}
                                call={this.fetchJobs}
                                changeActivePage={this.changeActivePage}
                                jobs={this.state.jobs}
                                activePage={this.state.activePage}
                                jobsLoading={this.state.jobsLoading}
                                submitWork={this.submitWork}
                            />

                        </GridContainer>
                        <Clearfix/>
                    </div>

                </div>
               
                {this.state.loginDisabled && <PreloaderComponent />}
            </div>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user,
            allJobsPaginated: store.jobs.allJobsPaginated,
            allJobsCount: store.jobs.allJobsCount
        }
    }, {
        getUser: userActions.getUser,
        getContractorJobsPaginated: jobActions.getContractorJobsPaginated,
    }
)(withStyles(customerDashboardStyle)(ContractorMyJobs));
