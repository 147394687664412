import {
    container,
    cardTitle,
    title,
    mlAuto,
    mrAuto,
    main,
    whiteColor,
    mainRaised,
    grayColor
} from "assets/jss/material-kit-pro-react.jsx";

import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.jsx";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";

const editProfilePageStyle = {
    container,
    ...imagesStyle,
    ...tooltipsStyle,
	customerProfileContainer: {
        minHeight: "78vh"
    },
    cardTitleWhite: {
        ...cardTitle,
        color: whiteColor + "  !important"
    },
	userNamePhotoContainer:{
		textAlign:'center'
	},
	userPhotoContainer:{
		transform:"translate3d(0, -40%, 0)"
	},
	userPhoto:{
		maxWidth:'160px'
	},
	userPhotoSelector:{
		textAlign:'center',
		paddingTop: '10px'
	},
	userPhotoSelectorLabel:{
		boxShadow: '0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
		backgroundColor: '#4caf50',
		color: '#FFF !important',
		border: 'none',
		margin: '0 auto',
		cursor: 'pointer',
		padding: '12px 30px',
		position: 'relative',
		width: '160px',
		fontSize: '12px',
		height: '40px',
		transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
		textAlign: 'center',
		fontWeight: '400',
		lineHeight: '1.42857143',
		whiteSpace: 'nowrap',
		touchAction: 'manipulation',
		borderRadius: '3px',
		textTransform: 'uppercase',
		letterSpacing: '0',
		verticalAlign: 'middle'
	},
    cardTitle,
    profile: {
        textAlign: "center",
        "& img": {
            maxWidth: "160px",
            width: "100%",
            margin: "0 auto",
            transform: "translate3d(0, -50%, 0)"
        }
    },
    description: {
        margin: "1.071rem auto 0",
        maxWidth: "600px",
        color: grayColor[0]
    },
    textCenter: {
        textAlign: "center !important"
    },
    name: {
        marginTop: "-80px"
    },

    main: {
        ...main
    },
    mainRaised: {
        ...mainRaised,
        margin: "0px !important",
        borderRadius: "0px",
        backgroundColor: grayColor[2]
    },
    title: {
        ...title,
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none"
    },
    follow: {
        position: "absolute",
        top: "0",
        right: "0"
    },
    followIcon: {
        width: "20px",
        height: "20px"
    },
    followButton: {
        marginTop: "-28px !important"
    },
    gridItem: {
        ...mlAuto,
        ...mrAuto
    },
    collections: {
        marginTop: "20px"
    },
    cardBody: {
        display: "flex",
        boxOrient: "vertical",
        boxDirection: "normal",
        flexDirection: "column",
        boxPack: "center",
        justifyContent: "center"
    },
    badge: {
        display: "inline-table",
        margin: "0 auto"
    },
    listUnstyled: {
        paddingLeft: "0",
        listStyle: "none",
        "& > li": {
            padding: "5px 0px",
            fontSize: "1em"
        }
    },
    profileTabs: {
        marginTop: "4.284rem",
        marginBottom: "50px"
    },
    card: {
        textAlign: "left !important"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block"
    },
	saveButton:{
		boxShadow: '0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
		backgroundColor: '#4caf50',
		color: '#FFF !important',
		border: 'none',
		margin: '0 auto',
		cursor: 'pointer',
		padding: '12px 30px',
		position: 'relative',
		width: '160px',
		fontSize: '12px',
		height: '40px',
		textAlign: 'center',
		fontWeight: '400',
		lineHeight: '1.42857143',
		whiteSpace: 'nowrap',
		touchAction: 'manipulation',
		borderRadius: '3px',
		textTransform: 'uppercase',
		letterSpacing: '0',
		verticalAlign: 'middle'
	}
};

export default editProfilePageStyle;
