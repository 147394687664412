/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import Search from "@material-ui/icons/Search";
import Card from "components/Card/Card.jsx";
import Edit from "@material-ui/icons/Edit";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Button from "components/CustomButtons/Button.jsx";
import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent";
import customerDashboardStyle
    from "assets/jss/material-kit-pro-react/views/customerDashboardStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import AllJobsContractor from "./AllJobs/AllJobsContractor";

import {jobActions, userActions} from "../../actions";
import {connect} from "react-redux";

class ContractorPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
           
            errorDialog: false,
            searchInput: '',
            searchNotFount: '',
            searchFlag: false,
            activePage: 1,
            preloader: false,
            jobsLoading: true,

            errorActivation:{},
        };
    }

    componentDidMount() {
        this.fetchAllJobs(this.state.activePage);
    }

    fetchAllJobs = (page) => {
        this.setState({ preloader: true, jobsLoading: true });
        this.props.getAllJobsPaginated(page).then(
            res => {
                this.setState({ preloader: false, jobs: res.jobs, jobsLoading: false });
            },
            err => {
                this.setState({ preloader: false, jobsLoading: false , errorActivation: err.statusCode === 403 ? err : {} });
            }
        );
    }

    fetchJobsSeach = (page) => {
        this.setState({ preloader: true, jobsLoading: true });
        this.props.searchJobsPaginated(this.state.searchInput, page).then(
            res => {
                this.setState({ preloader: false, jobsLoading: false, jobs: res.jobs, searchFlag: true });
            },
             err => { this.setState({ preloader: false, jobsLoading: false, searchNotFount: err , searchFlag: true , jobs:[] });}
        );
    }

    editProfileDetails(e) {
        this.props.history.push("/contractor-page");
    };

    submitSearchForm = e => {
        if (e) e.preventDefault();
        this.setState({ activePage: 1 });
        if (this.state.searchInput) {
            this.fetchJobsSeach(1)
        } else {
            this.setState({searchFlag: false})
            this.fetchAllJobs(1)
        }
    };

    changeActivePage = pageNumber => {
        this.setState({ activePage: pageNumber });
    }

    render() {
        const { classes, ...rest } = this.props;

        return (
            <div>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={
                        classes.container + " "
                        + classes.customerDashboardContainer
                    }>
                        <GridContainer justify="center"
                                       className={
                                           classes.customerNameContainer
                                       }>
                            <GridItem xs={12} sm={12} md={8}
                                      className={
                                          classes.customerName
                                      }
                            >
                                <div className={
                                    classes.follow + " "
                                    + classes.editProfile + " title-edit"
                                }>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="Edit profile details"
                                        placement="top"
                                        onClick={e => this.editProfileDetails(e)}
                                        classes={{tooltip: classes.tooltip + " title-edit"}}
                                    >
                                        <Button
                                            justIcon
                                            round
                                            color="primary"
                                            className={classes.followButton}
                                        >
                                            <Edit
                                                className={classes.followIcon}/>
                                        </Button>
                                    </Tooltip>
                                </div>
                                <div className={classes.profile}>
                                    <div className={
                                        classes.name + " "
                                        + classes.customerDashboardName
                                    }>
                                        <h3 className={classes.title}>
                                            {
                                                this.props.user && this.props.user.first_name + " " + this.props.user.last_name
                                            }
                                        </h3>
                                    </div>
                                </div>

                            </GridItem>


                            <GridItem xs={12} sm={12} md={4}
                                      className={
                                          classes.postJobGridItem + " div-search"
                                      }>


                                <Card raised className={classes.card  + " form-search-cart"}>
                                    <form className={"form-search"} onSubmit={this.submitSearchForm}>
                                        <label className={"label-input-search"}>
                                            <input type="text" name="name" placeholder="Search for jobs" className={"input-search-jobs"} onChange={(e) => {
                                                this.setState({searchInput:e.target.value})
                                            }}/>
                                        </label>

                                        <Button color="success" className={"btn-search"} type={'submit'}>
                                            <Search />
                                        </Button>
                                    </form>
                                </Card>



                            </GridItem>


                        </GridContainer>

                        <GridContainer>

                            <AllJobsContractor
                                searchNotFount = {this.state.searchNotFount}
                                errorActivationByAdmin = {this.state.errorActivation}
                                jobCount={this.props.allJobsCount}
                                call={this.fetchAllJobs}
                                changeActivePage={this.changeActivePage}
                                jobs={this.state.jobs}
                                searchPagin={this.fetchJobsSeach}
                                searchFlag={this.state.searchFlag}
                                activePage={this.state.activePage}
                                jobsLoading={this.state.jobsLoading}
                            />

                        </GridContainer>
                        <Clearfix/>
                    </div>

                </div>
                {this.state.loginDisabled && <PreloaderComponent />}
            </div>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user,
            allJobsPaginated: store.jobs.allJobsPaginated,
            allJobsCount: store.jobs.allJobsCount
        }
    }, {
        getUser: userActions.getUser,
        getAllJobsPaginated: jobActions.getAllJobsPaginated,
        searchJobsPaginated: jobActions.searchJobsPaginated,
    }
)(withStyles(customerDashboardStyle)(ContractorPage));
