import { paymentConstants } from "../constants/payment.constants";
import { paymentService } from "../services/payment.service";
import { alertActions } from "./";
import { history } from "../helpers";
import { jobService } from "../services";
import { jobConstants } from "../constants";

export const paymentActions = {
  createJobPayment,
  createJobPaymentOvertime,
  executePayment,
  createBatchPayouts,
  findPayment,
  sendPayment
};

function createJobPayment(paymentObj) {
  return async dispatch => {
    try {
      dispatch(request());
      const payment = await paymentService.createJobPayment(paymentObj);
      dispatch(success(payment));
      return payment;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(payment) {
    return { type: paymentConstants.POST_JOB_PAYMENT_REQUEST, payment };
  }
  function success(payment) {
    return { type: paymentConstants.POST_JOB_PAYMENT_SUCCESS, payment };
  }
  function failure(error) {
    return { type: paymentConstants.POST_JOB_PAYMENT_FAILURE, error };
  }
}
function sendPayment(paymentObj) {
  return async dispatch => {
    try {
      dispatch(request());
      const payment = await paymentService.sendPayment(paymentObj);
      dispatch(success(payment));
      return payment;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(payment) {
    return { type: paymentConstants.POST_JOB_PAYMENT_REQUEST, payment };
  }
  function success(payment) {
    return { type: paymentConstants.POST_JOB_PAYMENT_SUCCESS, payment };
  }
  function failure(error) {
    return { type: paymentConstants.POST_JOB_PAYMENT_FAILURE, error };
  }
}

function createJobPaymentOvertime(paymentObj) {
  return async dispatch => {
    try {
      dispatch(request());
      const payment = await paymentService.createJobPaymentOvertime(paymentObj);
      dispatch(success(payment));
      return payment;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(payment) {
    return {
      type: paymentConstants.POST_JOB_PAYMENT_OVERTIME_REQUEST,
      payment
    };
  }
  function success(payment) {
    return {
      type: paymentConstants.POST_JOB_PAYMENT_OVERTIME_SUCCESS,
      payment
    };
  }
  function failure(error) {
    return { type: paymentConstants.POST_JOB_PAYMENT_OVERTIME_FAILURE, error };
  }
}

function executePayment(paymentObj) {
  return async dispatch => {
    try {
      dispatch(request());
      const payment = await paymentService.createJobPaymentExecute(paymentObj);
      dispatch(success(payment));
      return payment;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(payment) {
    return { type: paymentConstants.POST_JOB_EXECUTE_PAYMENT_REQUEST, payment };
  }
  function success(payment) {
    return { type: paymentConstants.POST_JOB_PAYMENT_EXECUTE_SUCCESS, payment };
  }
  function failure(error) {
    return { type: paymentConstants.POST_JOB_PAYMENT_EXECUTE_FAILURE, error };
  }
}

function createBatchPayouts(paymentObj) {
  return async dispatch => {
    try {
      const payment = await paymentService.batchPayouts(paymentObj);
      dispatch(success(payment));
      return payment;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(payment) {
    return { type: paymentConstants.POST_JOB_EXECUTE_PAYMENT_REQUEST, payment };
  }
  function success(payment) {
    return { type: paymentConstants.POST_JOB_PAYMENT_EXECUTE_SUCCESS, payment };
  }
  function failure(error) {
    return { type: paymentConstants.POST_JOB_PAYMENT_EXECUTE_FAILURE, error };
  }
}

function findPayment(id) {
  return async dispatch => {
    try {
      dispatch(request());
      const payment = await paymentService.getPaymentById(id);
      dispatch(success(payment));
      return payment;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(payment) {
    return { type: jobConstants.GET_PAYMENT_REQUEST, payment };
  }
  function success(payment) {
    return { type: jobConstants.GET_PAYMENT_SUCCESS, payment };
  }
  function failure(error) {
    return { type: jobConstants.GET_PAYMENT_FAILURE, error };
  }
}

