import React, { useState, useEffect } from "react";
import { Router, Route, Switch } from "react-router";
import SignupPage from "../../views/SignupPage/SignupPage";
import RegistrationConfirmed from "../../views/RegistrationConfirmed/RegistrationConfirmedPage";
import LoginPage from "../../views/LoginPage/LoginPage";
import ForgotPasswordPage from "../../views/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "../../views/ResetPasswordPage/ResetPasswordPage";
import CustomerPage from "../../views/CustomerPage/CustomerPage";
import Profile from "../../views/ProfilePage/ContractorPage";
import { Redirect } from "react-router-dom";
import CustomerDashboard from "../../views/CustomerDashboard/CustomerDashboard";
import JobPostingPage from "../../views/JobPostingPage/JobPostingPage";
import JobUpdatingPage from "../../views/JobUpdatingPage/JobUpdatingPage";
import JobDetailsPage from "../../views/JobDetailsPage/JobDetailsPage";
import FinalizePage from "../../views/PayPalPage/FinalizePage";
import PayJobPage from "../../views/PayPalPage/PayJobPage";
import CompleteWorkPage from "../../views/CompleteWork/CompleteWorkPage";
import ContractorPage from "../../views/ContractorPage/ContractorPage";
import ContractorPageAllJobs from "../../views/ContractorPage/ContractorAllJobs";
import ContractorPageMyJobs from "../../views/ContractorPage/ContractorMyJobs";
import EditProfilePage from "../../views/EditProfilePage/EditProfilePage";
import JobPage from "../../views/ContractorPage/Job/JobContractor";
import MyContractorJobPage from "../../views/ContractorPage/Job/MyContractorJob";
import ProposalJobPage from "../../views/ContractorPage/Proposal/ProposalJobContractor";
import CalculationPage from "../../views/CalculationPage/CalculationPage";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import PreloaderComponent from "../PreloaderComponent/PreloaderComponent";
import { urlConstants } from '../../constants/api-url'
import ProtectedRoute from "./ProtectedRoute";

class SwitchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusy: true
    };
  }

  componentDidMount() {
    this.props.getUser().finally(() => {
      this.setState({ isBusy: false });
    });

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    if (this.state.isBusy) return <PreloaderComponent />;
    return (
      <Switch>
        <ProtectedRoute path="/customer-page" component={CustomerPage} accountType="customer"/>
        <ProtectedRoute exact path="/customer-dashboard" component={CustomerDashboard} accountType="customer"/>
        <ProtectedRoute path="/job-posting-page" component={JobPostingPage} accountType="customer"/>
        <ProtectedRoute path="/job-updating-page/:id" component={JobUpdatingPage} accountType="customer"/>
        <ProtectedRoute path="/job-details-page/:id" component={JobDetailsPage} accountType="customer"/>
        <ProtectedRoute path="/finalize/:id" component={FinalizePage} accountType="customer"/>
        <ProtectedRoute path="/payment-job/:id" component={PayJobPage}/>
        <ProtectedRoute path="/complete-work/:id" component={CompleteWorkPage} accountType="contractor"/>
        <ProtectedRoute path="/job/:id" component={JobPage} />
        <ProtectedRoute path="/my-contracts/job/:id" component={MyContractorJobPage}/>
        
        <ProtectedRoute path="/contractor-info/:id" component={Profile}/>
        //unused routes
        {/* <ProtectedRoute path="/proposal/job/:id" component={ProposalJobPage}/> */}
        {/* <ProtectedRoute path="/contractor-page" component={ContractorPage}/> */}
        <ProtectedRoute path="/contractor-page" component={CustomerPage} accountType="contractor"/>
        <ProtectedRoute path="/contractor-all-jobs" component={ContractorPageAllJobs} accountType="contractor"/>
        <ProtectedRoute path="/my-contracts" component={ContractorPageMyJobs} accountType="contractor"/>
        <ProtectedRoute path="/edit-profile" component={EditProfilePage}/>
        <Route path="/login-page" render={props => <LoginPage {...props} />} />
        <Route
          path="/signup-page"
          render={props => <SignupPage {...props} />}
        />
        <Route
          path="/signup-confirmed/:userId"
          render={props => <RegistrationConfirmed {...props} />}
        />
        <Route
          path="/forgot-password"
          render={props => <ForgotPasswordPage {...props} />}
        />
        <Route
          path="/reset-password/:token"
          render={props => <ResetPasswordPage {...props} />}
        />
        <Route
          path="/calculation-page"
          render={props => <CalculationPage {...props} />}
        />
        <Route 
          path="/" 
          render={props => <Redirect to={{ pathname: "/login-page" }} />} 
        />        
      </Switch>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    getUser: userActions.getUser
  }
)(SwitchComponent);
