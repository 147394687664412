export const jobConstants = {
  POST_JOB_REQUEST: "JOBS_POST_REQUEST",
  POST_JOB_SUCCESS: "JOBS_POST_SUCCESS",
  POST_JOB_FAILURE: "JOBS_POST_FAILURE",

  POST_JOB_PAYMENT_REQUEST: "POST_JOB_PAYMENT_REQUEST",
  POST_JOB_PAYMENT_SUCCESS: "POST_JOB_PAYMENT_SUCCESS",
  POST_JOB_PAYMENT_FAILURE: "POST_JOB_PAYMENT_FAILURE",

  POST_JOB_EXECUTE_PAYMENT_REQUEST: "POST_JOB_EXECUTE_PAYMENT_REQUEST",
  POST_JOB_PAYMENT_EXECUTE_SUCCESS: "POST_JOB_PAYMENT_EXECUTE_SUCCESS",
  POST_JOB_PAYMENT_EXECUTE_FAILURE: "POST_JOB_PAYMENT_EXECUTE_FAILURE",

  GET_JOBS_PAGINATED_REQUEST: "JOBS_GET_PAGINATED_REQUEST",
  GET_JOBS_PAGINATED_SUCCESS: "JOBS_GET_PAGINATED_SUCCESS",
  GET_JOBS_PAGINATED_FAILURE: "JOBS_GET_PAGINATED_FAILURE",

  GET_PAYMENT_REQUEST: "PAYMENT_GET_REQUEST",
  GET_PAYMENT_SUCCESS: "PAYMENT_GET_SUCCESS",
  GET_PAYMENT_FAILURE: "PAYMENT_GET_FAILURE",

  GET_JOBS_CONTRACTOR_PAGINATED_REQUEST:
    "JOBS_GET_CONTRACTOR_PAGINATED_REQUEST",
  GET_JOBS_CONTRACTOR_PAGINATED_SUCCESS:
    "JOBS_GET_CONTRACTOR_PAGINATED_SUCCESS",
  GET_JOBS_CONTRACTOR_PAGINATED_FAILURE:
    "JOBS_GET_CONTRACTOR_PAGINATED_FAILURE",

  DELETE_JOB_BY_ID_REQUEST: "JOBS_DELETE_BY_ID_REQUEST",
  DELETE_JOB_BY_ID_SUCCESS: "JOBS_DELETE_BY_ID_SUCCESS",
  DELETE_JOB_BY_ID_FAILURE: "JOBS_DELETE_BY_ID_FAILURE",

  GET_USER_JOB_BY_ID_REQUEST: "JOBS_GET_JOB_BY_ID_REQUEST",
  GET_USER_JOB_BY_ID_SUCCESS: "JOBS_GET_JOB_BY_ID_SUCCESS",
  GET_USER_JOB_BY_ID_FAILURE: "JOBS_GET_JOB_BY_ID_FAILURE",

  GET_JOB_BY_ID_REQUEST: "JOBS_GET_JOB_BY_ID_REQUEST",
  GET_JOB_BY_ID_SUCCESS: "JOBS_GET_JOB_BY_ID_SUCCESS",
  GET_JOB_BY_ID_FAILURE: "JOBS_GET_JOB_BY_ID_FAILURE",

  UPDATE_JOB_BY_ID_REQUEST: "JOBS_UPDATE_BY_ID_REQUEST",
  UPDATE_JOB_BY_ID_SUCCESS: "JOBS_UPDATE_BY_ID_SUCCESS",
  UPDATE_JOB_BY_ID_FAILURE: "JOBS_UPDATE_BY_ID_FAILURE",

  GET_ALL_USER_JOBS_REQUEST: "GET_ALL_USER_JOBS_REQUEST",
  GET_ALL_USER_JOBS_SUCCESS: "GET_ALL_USER_JOBS_SUCCESS",
  GET_ALL_USER_JOBS_FAILURE: "GET_ALL_USER_JOBS_FAILURE",

  SEARCH_USER_JOBS_REQUEST: "GET_ALL_USER_JOBS_REQUEST",
  SEARCH_USER_JOBS_SUCCESS: "GET_ALL_USER_JOBS_SUCCESS",
  SEARCH_USER_JOBS_FAILURE: "GET_ALL_USER_JOBS_FAILURE"
};
