/* eslint-disable */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import customerDashboardStyle
    from "assets/jss/material-kit-pro-react/views/customerDashboardStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../../actions";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {cardTitle} from "assets/jss/material-kit-pro-react.jsx";

import Pagination from "components/Pagination/PaginationComponent";

import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VerifiedUser from "@material-ui/icons/VerifiedUser";

import moment from "moment";
import Button from "components/CustomButtons/Button.jsx";
import JobAccordions from "./AccordionsCardJob";

const roundButtonsObj = () => {
    return [
        { class: "dashboard-button", color: "success", icon: Edit  },
        { class: "dashboard-button", color: "danger", icon: Close },
    ]
};


class JobsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            percent: 10,
            activePage: 1,
            resizeOption: window.innerWidth > 480
        };
    }

    handlePageChange = pageNumber => {
        this.props.changeActivePage(pageNumber);
        this.props.call(pageNumber);
    };

    componentDidMount() {
        window.addEventListener('resize', () => {
            if(window.innerWidth < 480) {
                this.setState({resizeOption: false});
            } else {
                this.setState({resizeOption: true});
            }
        })
    };

    render() {
        const { classes, jobs, jobsLoading } = this.props;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <h3 className={classes.cardTitle + " all-jobs-height"}>
                            <p
                                className={
                                    classes.cardTitleText +
                                    " " +
                                    classes.customerCardTitle +
                                    " all-jobs-height" +
                                    " title-pages"
                                }
                            >
                                My jobs
                            </p>
                        </h3>

                        {jobs && jobs.length > 0 ? (
                            <div className={"table-div"}>
                                { this.state.resizeOption === true
                                    ? (
                                        <table className={"table-contracts"}>
                                            <thead className={"v-j-variable v-j-variable-center"}>
                                            <tr>
                                                <th className={"table-th"}>#</th>
                                                <th className={"table-th"}>Title</th>
                                                <th className={"table-th"}>Description</th>
                                                <th className={"table-th"}>Due date</th>
                                                <th className={"table-th"}>Price</th>
                                                <th className={"table-th"}>Actions</th>
                                                <th className={"table-th"}>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody className={"v-j-variable-center"}>

                                            {jobs && jobs.length > 0
                                                ? jobs.map((job, index) => (
                                                    <tr key={job._id} className={"table-tr"}>
                                                        <td className={"table-td"}>{(this.props.activePage - 1 ) * 10 + index + 1}</td>
                                                        <td className={"table-td"}>{
                                                            job.jobName.length < 30 ? job.jobName : job.jobName.substring(0,30) + '...'
                                                        }</td>
                                                        <td className={"table-td"}>{
                                                            job.jobDescription.length < 30 ? job.jobDescription : job.jobDescription.substring(0,30) + '...'
                                                        }</td>
                                                        <td className={"table-td"}>{moment(job.dateTime, "DD-MM-YYYY hh:mm A").format("DD-MM-YYYY")}</td>
                                                        <td className={"table-td"}>£{(job.total + job.total * this.state.percent / 100).toFixed(2)}</td>
                                                        <td className={"table-td"}>
                                                            {(job.order && job.order.payment && job.order.payment.status !== 'execute') || !job.order || !job.order.payment? (
                                                                <Button round justIcon size="sm" color={"success"}
                                                                        onClick={(e) => this.props.editJobButtonClick(job._id)}
                                                                >
                                                                    <Edit />
                                                                </Button>
                                                            ) : null }
                                                            {(job.order && job.order.payment && job.order.payment.status !== 'execute') || !job.order || !job.order.payment ? (
                                                                <Button round justIcon size="sm" color={"danger"}
                                                                        onClick={(e) => this.props.openDeletionConfirmation(job._id)}
                                                                >
                                                                    <Close />
                                                                </Button>
                                                            ) : null }
                                                            <Button round justIcon size="sm" color={"info"}
                                                                    onClick={(e) => this.props.showDeletionJob(job._id)}
                                                            >
                                                                <Visibility />
                                                            </Button>
                                                        </td>
                                                        <td className={"table-td"}>
                                                            { (job.order && job.order.payment && job.order.payment.status !== 'execute') || !job.order || !job.order.payment ? (
                                                                    <Button className={"btn-finalize"} color={"success"}
                                                                            onClick={(e) => this.props.payJob(job._id)}
                                                                    >
                                                                        Finalise
                                                                    </Button>
                                                                ) :
                                                                <VerifiedUser className={"verifide-color"} />

                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                                : null}
                                            </tbody>
                                        </table>
                                    ) : <JobAccordions
                                        activePage={this.props.activePage}
                                        editJobButtonClick={this.props.editJobButtonClick}
                                        jobs={jobs}
                                        classes={classes}
                                        percent={this.state.percent}
                                        payJob={this.props.payJob}
                                        showDeletionJob={this.props.showDeletionJob}
                                        openDeletionConfirmation={this.props.openDeletionConfirmation}
                                    />
                                }


                            </div>
                        ) : (
                            <h4 className={"empty-msg"}>No jobs yet...</h4>
                        )}

                        {jobs && jobs.length !== 0 && (
                            <div className={"pagination-container-hire"}>
                                <Pagination
                                    activePage={this.props.activePage}
                                    totalItemsCount={this.props.jobCount}
                                    onChange={this.handlePageChange}
                                />
                            </div>
                        )}
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user
        }
    }, {
        getUser: userActions.getUser
    }
)(withStyles(customerDashboardStyle)(JobsCard));
