import React from "react";
import {urlConstants} from "../../constants/api-url";
import classNames from "classnames";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import photoPlaceholder from "assets/img/faces/photoPlaceholder.jpg";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomTextArea from "../../components/CustomTextArea/CustomTextArea";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import editProfilePageStyle from "assets/jss/material-kit-pro-react/views/editProfilePageStyle.jsx";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent";
import PhotoPreloaderComponent from "../../components/PhotoPreloaderComponent/PhotoPreloaderComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import urlParse from 'url-parse';

class EditProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  ...this.props.user,
      checked: [1],
      multipleSelect: [],
      emailError: false,
      emailErrorMessage: "",
      passwordError: false,
      passwordErrorMessage: "",
      passwordConfirmError: false,
      passwordConfirmErrorMessage: "",
      firstNameError: false,
      firstNameErrorMessage: "",
      lastNameError: false,
      lastNameErrorMessage: "",
      houseNumberError: false,
      houseNumberErrorMessage: "",
      addressError: false,
      addressErrorMessage: "",
      cityError: false,
      cityErrorMessage: "",
      phoneError: false,
      phoneErrorMessage: "",
      postcodeError: false,
      postcodeErrorMessage: "",
      bestTimeToCall: 1,
      updated: false,
      userRegister: false,
      modalTitle: "",
      modalText: "",
      updateDisabled: false,
	    photoLoading: false,
      selectIsOpen: false
    };
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  changeCustomInput = e => {
    const numberReg = /^\d*$/;
    if (numberReg.test(e.target.value)) {
      if(e.target.id === 'phone') this.handleChange(e, 'phone');
    }
  };

  onKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[e+./-]/.test(keyValue)) {
      event.preventDefault();
    }
  }

  handleChange = (event, name) => {
    this.setState({ [name]: event.target.value }, () => {
      const value = this.state[name];
      switch (name) {
        case "email":
          let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (emailReg.test(value)) {
            this.setState({ [name + "Error"]: false });
			return true;
          } else {
            this.setState({ [name + "Error"]: true });
            this.setState({ [name + "ErrorMessage"]: "Email is not valid" });
          }
          break;
        case "password":
          if (value < 6 && name === "password") {
            this.setState({ ["passwordError"]: true });
            this.setState({
              ["passwordErrorMessage"]: "Password too short"
            });
          } else if (value.length >= 6 && name === "password") {
            this.setState({ ["passwordError"]: false });
          }
          break;
        case "passwordConfirm":
          const password = this.state.password;
          const passwordConfirm = this.state.passwordConfirm;

          if (
            password !== "" &&
            passwordConfirm !== "" &&
            passwordConfirm !== password
          ) {
            this.setState({ ["passwordError"]: true });
            this.setState({ ["passwordConfirmError"]: true });
            this.setState({
              ["passwordErrorMessage"]:
                "Confirm Password and Password do not match"
            });
            this.setState({
              ["passwordConfirmErrorMessage"]:
                "Confirm Password and Password do not match"
            });
          } else {
            this.setState({ ["passwordError"]: false });
            this.setState({ ["passwordConfirmError"]: false });
          }
          break;
        case 'phone':
          if(this.state.phone.length !== 11 && this.state.phone !== '') {
            this.setState({[name + 'Error']: true});
            this.setState({[name + 'ErrorMessage']: 'Please enter a valid phone number'});
          } else {
            this.setState({[name + 'Error']: false});
          }
          break;

        case "postcode":
          if (value !== "") {
            const numberReg = /^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
            if (numberReg.test(value)) {
              this.setState({ [name + "Error"]: false });
            } else {
              this.setState({ [name + "Error"]: true });
              this.setState({
                [name + "ErrorMessage"]: "Postcode is not valid"
              });
            }
          } else {
            this.setState({ [name + "Error"]: false });
          }
          break;
        case "house_number":
          if (value !== "") {
            const numberReg = /^\d*$/;
            if (numberReg.test(value)) {
              this.setState({ [name + "Error"]: false });
            } else {
              this.setState({ [name + "Error"]: true });
              this.setState({
                [name + "ErrorMessage"]: "House number is not valid"
              });
            }
          } else {
            this.setState({ [name + "Error"]: false });
          }
          break;
        default:
          if (value === "") {
            switch (name) {
              case "first_name":
                this.setState({ [name + "Error"]: true });
                this.setState({
                  ["firstNameErrorMessage"]: "First name must not be blank"
                });
                break;

              case "last_name":
                this.setState({ [name + "Error"]: true });
                this.setState({
                  ["lastNameErrorMessage"]: "Last name must not be blank"
                });
                break;

              default:
                break;
            }
          } else {
            this.setState({ [name + "Error"]: false });
          }
          break;
      }
    });
  };

  handleBlur = (event, name) => {
    switch (name) {
      case 'orderPhone':
        if ( this.state.phone === "")
        {
          this.setState({['orderPhoneError']: true});
          this.setState({['orderPhoneErrorMessage']: 'Order phone is mandatory field'});
        }
        break;

      default:
        break;
    }
  };

  handleClickOpen(modal, title, text) {
    var x = [];
    x[modal] = true;
    this.setState(x);
    this.setState({ modalTitle: title });
    this.setState({ modalText: text });
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleClick() {
    if (this.state.email === "") {
      this.setState({ emailError: true });
      this.setState({ ["emailErrorMessage"]: "Email is mandatory field" });
    }
    if (this.state.first_name === "") {
      this.setState({ firstNameError: true });
      this.setState({
        ["firstNameErrorMessage"]: "First name is mandatory field"
      });
    }
    if (this.state.last_name === "") {
      this.setState({ lastNameError: true });
      this.setState({
        ["lastNameErrorMessage"]: "Last name is mandatory field"
      });
    }
    if (this.state.city === "") {
      this.setState({ cityError: true });
      this.setState({
        ["cityErrorMessage"]: "City is mandatory field"
      });
    }
    if (this.state.house_number === "") {
      this.setState({ houseNumberError: true });
      this.setState({
        ["houseNumberErrorMessage"]: "House number is mandatory field"
      });
    }
    if (this.state.address === "") {
      this.setState({ addressError: true });
      this.setState({
        ["addressErrorMessage"]: "Address is mandatory field"
      });
    }
    if (this.state.city === "") {
      this.setState({ cityError: true });
      this.setState({
        ["cityErrorMessage"]: "City is mandatory field"
      });
    }
    if (this.state.postcode === "") {
      this.setState({ postcodeError: true });
      this.setState({
        ["postcodeErrorMessage"]: "Postcode is mandatory field"
      });
    }

    if (this.state.account_type === 1) {
      if (
        this.state.email !== "" &&
        this.state.first_name !== "" &&
        this.state.last_name !== "" &&
        !this.state.emailError &&
        !this.state.firstNameError &&
        !this.state.lastNameError &&
        !this.state.phoneError
      ) {
        const user = {
		      id:this.state._id,
		      photo_url: this.state.photo_url,
          email: this.state.email,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          phone: this.state.phone,
          houseNumber: this.state.house_number,
          address: this.state.address,
          city: this.state.city,
          postcode: this.state.postcode,
          time_to_call: this.state.phone === "" ? 0 : this.state.time_to_call,
          account_type: this.state.account_type,
		      about_myself: this.state.about_myself
        };
        this.setState({ updateDisabled: true });
        this.props.update(user).then(
          res => {
            this.setState({ updateDisabled: false });
            this.setState({ updated: true });
			toast.success("User info updated");
			this.props.history.push("/customer-page");
          },
          err => {
            this.setState({ emailError: true });
            this.setState({ emailErrorMessage: err });
            this.setState({ updateDisabled: false });
			toast.error("Error updating user info");
          }
        );
      }
    } else if (this.props.user.account_type === 2) {
      if (
        this.state.email !== "" &&
        this.state.first_name !== "" &&
        this.state.last_name !== "" &&
        this.state.house_number !== "" &&
        this.state.address !== "" &&
        this.state.city !== "" &&
        this.state.postcode !== "" &&
        !this.state.emailError &&
        !this.state.firstNameError &&
        !this.state.lastNameError &&
        !this.state.houseNumberError &&
        !this.state.addressError &&
        !this.state.cityError &&
        !this.state.postcodeError &&
        !this.state.phoneError
      ) {
        const user = {
          id:this.state._id,
          photo_url: this.state.photo_url,
          email: this.state.email,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          phone: this.state.phone,
          house_number: this.state.house_number,
          address: this.state.address,
          city: this.state.city,
          postcode: this.state.postcode,
          time_to_call: this.state.phone === "" ? 0 : this.state.time_to_call,
          account_type: this.state.account_type,
          about_myself: this.state.about_myself
        };
        this.setState({ updateDisabled: true });
        this.props.update(user).then(
          res => {
            this.setState({ updateDisabled: false });
            this.setState({ updated: true });
        toast.success("User info updated");
        this.props.history.push("/contractor-page");
            },
            err => {
              console.log('Update profile error', err)
              this.setState({ emailError: true });
              this.setState({ emailErrorMessage: err });
              this.setState({ updateDisabled: false });
        toast.error("Error updating user info");
          }
        );
      }
    }
  }
uploadPhoto = (e) => {
    if(!e.target.files.length) return;
	this.setState({'photoLoading':true});
	this.setState({'photo_url':false});
	const file = e.target.files[0];
	const imageUploadRequest = new XMLHttpRequest();
	imageUploadRequest.open('GET',urlConstants.BACKEND_URL+'/user/awssignedurl?userID='+this.props.user._id+'&contentType='+file.type,true);
	imageUploadRequest.send();
	imageUploadRequest.onreadystatechange = function(){
		if(imageUploadRequest.readyState == 4){
			if(imageUploadRequest.status == 200){
				const awsSignedUrl = imageUploadRequest.responseText;
				const objectUrl = urlParse(awsSignedUrl, true);
				const imageUrl = objectUrl.origin + objectUrl.pathname;
				const key = objectUrl.pathname.substr(1);
				imageUploadRequest.open('PUT',awsSignedUrl,true);
				imageUploadRequest.send(file);
				imageUploadRequest.onreadystatechange = function(){
					if(imageUploadRequest.readyState == 4){
						if(imageUploadRequest.status == 200){
							imageUploadRequest.open('GET',urlConstants.BACKEND_URL+'/user/awssetobjectaccess?key='+key);
							imageUploadRequest.send();
							imageUploadRequest.onreadystatechange = function(){
								if(imageUploadRequest.readyState == 4){
									if(imageUploadRequest.status == 200){
										this.setState({'photoLoading':false});
										this.setState({photo_url:imageUrl});
									}
								}
							}.bind(this);
						}
					}
				}.bind(this);
			}
		}
	}.bind(this);
}

  handleTouch = (e) => {
    document.body.ontouchmove = function(e){return true; };
    if(this.state.selectIsOpen === false) {
      document.body.ontouchmove = function(e){ e.preventDefault(); };
      this.setState({selectIsOpen: true});
    } else {
      document.body.ontouchmove = function(e){return true; };
      this.setState({selectIsOpen: false});
    }
  };

  render() {
	const { classes, ...rest } = this.props;
	const imageClasses = classNames(
		classes.imgRoundedCircle,
		classes.userPhoto);
    return (
 <div>
		<ToastContainer />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.userNamePhotoContainer}>
                  <div className={classes.userPhotoContainer}>
					<div>
						{this.state.photoLoading?<PhotoPreloaderComponent/>:null}
						<img src={this.state.photo_url ? this.state.photo_url : photoPlaceholder} alt="..." className={imageClasses} />
					</div>
					<div className={classes.userPhotoSelector}>
						<p><label htmlFor="files" className={classes.userPhotoSelectorLabel}>Select photo</label></p>
  						<input id="files" style={{visibility:'hidden'}} type="file" onChange={this.uploadPhoto}/>
					</div>
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>
                      {
                        this.props.user &&

                        this.props.user.first_name + " "
                        + this.props.user.last_name

                      }
                    </h3>
                  </div>
                </div>
				<Card className={classes.pixel2XL}>
                  <form className={classes.form}>
                    <CardHeader
                      className={classes.cardHeader}
                    >
                    </CardHeader>
                    <CardBody>
					  <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon
                                      className={classes.inputAdornmentIcon}
                                    >
                                      face
                                    </Icon>
                                  </InputAdornment>
                                ),
                                placeholder: "First Name...",
                                value: this.state.firstName,
                                onChange: e =>
                                  this.handleChange(e, "first_name"),
                                onBlur: e => this.handleBlur(e, "first_name"),
								value: this.state.first_name,
                                error: this.state.firstNameError,
                                errormessage: this.state.firstNameErrorMessage
                              }}
                            />
							<CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon
                                      className={classes.inputAdornmentIcon}
                                    >
                                      face
                                    </Icon>
                                  </InputAdornment>
                                ),
                                placeholder: "Last Name...",
                                value: this.state.lastName,
                                onChange: e => this.handleChange(e, "last_name"),
                                onBlur: e => this.handleBlur(e, "lastName"),
								value: this.state.last_name,
                                error: this.state.lastNameError,
                                errormessage: this.state.lastNameErrorMessage
                              }}
                            />
                      <CustomInput
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          placeholder: "Email...",
                          type: "email",
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          onChange: e => this.handleChange(e, "email"),
                          onBlur: e => this.handleBlur(e, "email"),
						  value: this.state.email,
                          error: this.state.emailError,
                          errormessage: this.state.emailErrorMessage
                        }}
                      />
					  <CustomInput
                              id="phone"
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Icon
                                      className={classes.inputAdornmentIcon}
                                    >
                                      phone
                                    </Icon>
                                  </InputAdornment>
                                ),
                                placeholder: "Phone...",
                                value: this.state.phone,
                                onChange: this.changeCustomInput,
                                onKeyPress: this.onKeyPress.bind(this),
                                onBlur: e => this.handleBlur(e, 'phone'),
                                error: this.state.phoneError,
                                errormessage: this.state.phoneErrorMessage
                              }}/>
							  {this.state.phone !== "" && (
                              <FormControl
                                fullWidth
                                className={
                                  classes.selectFormControl +
                                  " " +
                                  classes.marginBottom50
                                }
                              >
                                <InputLabel
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                >
                                  Best time to call
                                </InputLabel>
                                <Select
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={this.state.time_to_call}
                                  onChange={this.handleSimple}
                                  inputProps={{
                                    name: "time_to_call",
                                    id: "best-time-to-call"
                                  }}
                                  onClick={e=>this.handleTouch(e)}
                                >
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value="1"
                                  >
                                    8AM - 10AM
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value="2"
                                  >
                                    10AM - 12AM
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value="3"
                                  >
                                    12AM - 2PM
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value="4"
                                  >
                                    2PM - 4PM
                                  </MenuItem>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value="5"
                                  >
                                    4PM - 6PM
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            {this.props.user.account_type == "2" ? (
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses
                                }}
                                inputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                    >
                                      <Icon
                                        className={classes.inputAdornmentIcon}
                                      >
                                        money
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                  placeholder: "House number...",
                                  value: this.state.house_number,
                                  onChange: e =>
                                    this.handleChange(e, "house_number"),
                                  onBlur: e =>
                                    this.handleBlur(e, "houseNumber"),
                                  error: this.state.houseNumberError,
                                  errormessage: this.state
                                    .houseNumberErrorMessage
                                }}
                              />
                            ) : null}
                            {this.props.user.account_type == "2" ? (
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses
                                }}
                                inputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                    >
                                      <Icon
                                        className={classes.inputAdornmentIcon}
                                      >
                                        home
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                  placeholder: "Street name...",
                                  value: this.state.address,
                                  onChange: e =>
                                    this.handleChange(e, "address"),
                                  onBlur: e => this.handleBlur(e, "address"),
                                  error: this.state.addressError,
                                  errormessage: this.state.addressErrorMessage
                                }}
                              />
                            ) : null}
                            {this.props.user.account_type == "2" ? (
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses
                                }}
                                inputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                    >
                                      <Icon
                                        className={classes.inputAdornmentIcon}
                                      >
                                        location_city
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                  placeholder: "City...",
                                  value: this.state.city,
                                  onChange: e => this.handleChange(e, "city"),
                                  onBlur: e => this.handleBlur(e, "city"),
                                  error: this.state.cityError,
                                  errormessage: this.state.cityErrorMessage
                                }}
                              />
                            ) : null}
                            {this.props.user.account_type == "2" ? (
                              <CustomInput
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControlClasses
                                }}
                                inputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                    >
                                      <Icon
                                        className={classes.inputAdornmentIcon}
                                      >
                                        code
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                  placeholder: "Postcode...",
                                  value: this.state.postcode,
                                  onChange: e =>
                                    this.handleChange(e, "postcode"),
                                  onBlur: e => this.handleBlur(e, "postcode"),
                                  error: this.state.postcodeError,
                                  errormessage: this.state.postcodeErrorMessage
                                }}
                              />
                            ) : null}
							  <CustomTextArea
                                        labelText="About myself"
                                        rowsCount={Infinity}
                                        id="about-myself"
                                        onChange={e => this.handleChange(e, 'about_myself')}
                                        //onBlur={e => this.props.handleBlur(e, 'aboutMyself')}
                                        value={this.state.about_myself}
										formControlProps={{
                                			fullWidth: true,
                                			className: classes.customFormControlClasses
                              			}}
                                        inputProps={{
                                            error: this.state.aboutMyselfError,
                                            errormessage: this.state.aboutMyselfErrorMessage
                                        }}
                                    />
                    </CardBody>
                    <div
                      className={
                        classes.textCenter +
                        " " +
                        classes.marginTop20 +
                        " " +
                        classes.marginBottom20 +
                        " " +
                        classes.marginBottom25
                      }
                    >
                      <Button
                        size="lg"
                        className={classes.saveButton}
                        onClick={e => this.handleClick()}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
            <Clearfix />
          </div>
        </div>

        {this.state.isBusy && <PreloaderComponent/>}
      </div>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    update: userActions.update
  }
)(withStyles(editProfilePageStyle)(EditProfilePage));
