import { userActions } from "../actions";
import  store  from "../store";

export function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (response.status !== 200) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        store.dispatch(userActions.logout())
      }
      if (response.status === 302) {
        // 302 response  - FOUND
      }
      if (response.status === 203) {
        // 203 response - NON_AUTHORITATIVE_INFORMATION
      }
      if (response.status === 403) {
        const error = data;
        return Promise.reject(error);
      }
      const error = (data && data.message) || (data && data.error) || response.statusText;

      return Promise.reject(error);
    }
    return data;
  });
}
