import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import resetPasswordStyle from "assets/jss/material-kit-pro-react/views/resetPasswordStyle.jsx";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import {userActions} from "../../actions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import {Link} from "react-router-dom";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ResetPasswordPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      password: '',
      passwordError: false,
      passwordErrorMessage: '',
      passwordConfirm: '',
      passwordConfirmError: false,
      passwordConfirmErrorMessage: '',
      resetDisabled: false,
      resetPassword: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  async toggleInput() {
    this.setState({
      isHidden: !this.state.isHidden
    });
  };

  secondStep = () => {
    this.setState({ step: 2});
  };

  thirdStep = () => {
    this.setState({ step: 3});
  };

  handleClickOpen(modal, title, text) {
    var x = [];
    x[modal] = true;
    this.setState(x);
    this.setState({'modalTitle': title});
    this.setState({'modalText': text});
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleChange = (event, name) => {
    this.setState({[name]: event.target.value}, () => {
      const password = this.state.password;
      const passwordConfirm = this.state.passwordConfirm;
      if (password !== ''
          && passwordConfirm !== ''
          && passwordConfirm !== password
      ) {
        this.setState({['passwordError']: true});
        this.setState({['passwordConfirmError']: true});
        this.setState({['passwordErrorMessage']: 'Confirm Password and Password do not match'});
        this.setState({['passwordConfirmErrorMessage']: 'Confirm Password and Password do not match'});
      } else {
        this.setState({['passwordError']: false});
        this.setState({['passwordConfirmError']: false});
      }
      if (passwordConfirm !== '' && passwordConfirm.length < 6) {
        this.setState({['passwordConfirmError']: true});
        this.setState({['passwordConfirmErrorMessage']: 'Password too short'});
      }
      if (password.length < 6) {
        this.setState({['passwordError']: true});
        this.setState({['passwordErrorMessage']: 'Password too short'});
      }
    });
  };

  handleBlur = (event, name) => {};

  handleClick() {
    if (this.state.password === '') {
      this.setState({'passwordError': true});
      this.setState({['passwordErrorMessage']: 'Password is mandatory field'});
    }
    if (this.state.passwordConfirm === '') {
      this.setState({'passwordConfirmError': true});
      this.setState({['passwordConfirmErrorMessage']: 'Password confirm is mandatory field'});
    }

    if (
        !this.state.passwordError
        && !this.state.passwordConfirmError
    ) {
      this.setState({'resetDisabled': true});
      const urlSlug = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
      this.props.resetPassConfirm(urlSlug, this.state.password).then(
          res => {
            this.thirdStep();
            this.setState({'resetDisabled': false});
          },
          err => {
            this.handleClickOpen(
                'resetPassword',
                'ERROR',
                err
            );
            this.setState({'resetDisabled': false});
          }
      );
    }

  }

  render() {
    const { classes } = this.props;
    const currentStep = this.state.step;
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="TEC MOW"
          links={<HeaderLinks dropdownHoverColor="info" />}
        />
        <div
          className={
            classes.pageHeader
          }
          style={{}}
        >
          <div className={classes.container + " "
            + classes.smallScreen60vh
          }>
            <GridContainer justify="center"
                           className={
                             classes.transform5

            }>
              <GridItem xs={12} sm={12} md={4}
                className={
                  classes.height76vh
              }>
                <Card
                    className={
                      (this.state.step === 3 ? classes.transform3 : classes.transform15) + " "
                      + (this.state.step === 2 ? classes.transform2 : classes.transform15) + " "
                }>

                  {
                    currentStep === 1 &&

                    <form className={classes.form}>
                      <CardHeader
                          color="primary"
                          signup
                          className={classes.cardHeader}
                      >
                        <h4 className={
                          classes.cardTitle + " "
                          + classes.cardHeaderText
                        }>
                          Reset your password
                        </h4>
                      </CardHeader>
                      <CardBody signup>
                        <p className={
                          classes.marginTop30 + " "
                          + classes.textCenter + " "
                          + classes.font20
                        }>
                          Hello!
                        </p>
                        <p>
                          We have received a request to reset your password.
                        </p>
                        <div className={
                          classes.textCenter + " "
                          + classes.marginBottom10
                        }>
                          <Button simple color="success" size="lg"
                                  className={classes.loginButton}
                                  onClick={this.secondStep}
                          >
                            Reset your password
                          </Button>
                          <p className={
                            classes.fontItalic + " "
                            + classes.marginTop20
                          }>
                            If you did not request this password change,
                            feel free to ignore it. If you have any
                            comments or questions,
                            please don't hesitate to reach us at
                            <a className={
                              classes.fontNormal
                            }
                               href="#"> support</a>
                          </p>
                          <p>
                            Your TECmow Team
                          </p>
                        </div>
                      </CardBody>
                    </form>
                  }

                  {
                    currentStep === 2 &&

                    <form className={classes.form}>
                      <CardHeader
                          color="primary"
                          signup
                          className={classes.cardHeader}
                      >
                        <h4 className={classes.cardTitle}>Reset your password</h4>
                      </CardHeader>
                      <CardBody signup>
                        <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            inputProps={{
                              startAdornment: (
                                  <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                  >
                                    <Icon
                                        className={classes.inputAdornmentIcon}>
                                      lock_outline
                                    </Icon>
                                  </InputAdornment>
                              ),
                              type: "password",
                              value: this.state.password,
                              placeholder: "Password...",
                              onChange: e => this.handleChange(e, 'password'),
                              onBlur: e => this.handleBlur(e, 'password'),
                              error: this.state.passwordError,
                              errormessage: this.state.passwordErrorMessage
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            inputProps={{
                              startAdornment: (
                                  <InputAdornment
                                      position="start"
                                      className={classes.inputAdornment}
                                  >
                                    <Icon
                                        className={classes.inputAdornmentIcon}>
                                      lock_outline
                                    </Icon>
                                  </InputAdornment>
                              ),
                              type: "password",
                              placeholder: "Confirm password...",
                              value: this.state.passwordConfirm,
                              onChange: e => this.handleChange(e, 'passwordConfirm'),
                              onBlur: e => this.handleBlur(e, 'passwordConfirm'),
                              error: this.state.passwordConfirmError,
                              errormessage: this.state.passwordConfirmErrorMessage
                            }}
                        />
                      </CardBody>
                      <div className={
                        classes.textCenter + " "
                        + classes.marginBottom10
                      }>
                        <Button simple color="success" size="lg"
                                className={classes.loginButton}
                                onClick={e => this.handleClick()}
                                disabled={this.state.resetDisabled}
                        >
                          Continue
                        </Button>
                      </div>
                    </form>
                  }

                  {
                    currentStep === 3 &&

                    <form className={ classes.form }>
                    <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                    >
                    <h4 className={classes.cardTitle}>Reset your password confirmation</h4>
                    </CardHeader>
                    <CardBody signup>
                    <div
                      className={
                        classes.marginBottom123
                      }
                    >
                    <p className={
                    classes.textCenter + " "
                    + classes.font30 + " "
                    + classes.marginTop30
                    }>
                    </p>
                    <p className={
                    classes.textLeft + ""
                    }>
                    Your password has been changed successfully.
                    </p>
                    </div>
                    <div className={
                    classes.textCenter + " "
                    + classes.marginBottom40
                    }>
                      <Link to={'/login-page'}
                            color={window.innerWidth < 960 ? "info" : "white"}
                            className={
                              classes.loginButton + " "
                              + classes.paddingVertical18 + " "
                              + classes.paddingHorizontal36 + " "
                              + classes.font14 + ' '
                              + classes.fontWeight400
                            }
                      >
                        Login and continue
                      </Link>
                    </div>
                    </CardBody>
                    </form>
                  }

                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer
            className={classes.footer}
          />
        </div>

        <div>
          <Dialog
              className={
                  classes.noRightPadding
              }
              classes={{
                root: classes.modalRoot,
                paper: classes.modal
              }}
              open={this.state.resetPassword}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => this.handleClose("resetPassword")}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
              <h4 className={classes.modalTitle + " "
                + classes.textCenter + " "
                + classes.errorHeader
              }>
                { this.state.modalTitle }
              </h4>
            </DialogTitle>
            <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
            >
              <p className={
                classes.errorModalMessageText
              }>
                { this.state.modalText }
              </p>
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              <Button className={
                    classes.modalWindowButton
                }
                  onClick={() => this.handleClose("resetPassword")}
                  color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default connect(
    store => {
      return {
        user: store.users.user
      }
    }, {
      resetPassConfirm: userActions.resetPassConfirm
    }
)(withStyles(resetPasswordStyle)(ResetPasswordPage));
