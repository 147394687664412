/* eslint-disable */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import customerDashboardStyle
    from "assets/jss/material-kit-pro-react/views/customerDashboardStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../../actions";
import GridItem from "components/Grid/GridItem.jsx";
import SmallPreloaderComponent from "../../../components/PreloaderComponent/SmallPreloaderComponent";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Pagination from "components/Pagination/PaginationComponent";
import {cardTitle} from "assets/jss/material-kit-pro-react.jsx";
// core components
import Button from "components/CustomButtons/Button.jsx";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ContractAccordions from "./AccordionsCardContract";

class ContractsCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resizeOption: window.innerWidth > 480
        };
    }

    handlePageChange = pageNumber => {
        this.props.changeActivePage(pageNumber);
        this.props.call(pageNumber);
    };

    componentDidMount() {
        window.addEventListener('resize', () => {
            if(window.innerWidth < 480) {
                this.setState({resizeOption: false});
            } else {
                this.setState({resizeOption: true});
            }
        })
    };

    render() {
        const { classes, jobs, jobsLoading } = this.props;
        return (
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <h3 className={classes.cardTitle + " all-jobs-height"}>
                            <p
                                className={
                                    classes.cardTitleText +
                                    " " +
                                    classes.customerCardTitle +
                                    " all-jobs-height" +
                                    " title-pages"
                                }
                            >
                                My Contracts
                            </p>
                        </h3>
                        {jobsLoading && <SmallPreloaderComponent />}
                        {jobs && jobs.length > 0 ? (
                            <div className={"table-div"}>
                                {
                                    this.state.resizeOption === true
                                    ? (
                                            <table className={"table-contracts"}>
                                                <thead className={"v-j-variable"}>
                                                <tr>
                                                    <th className={"table-th"}>Job</th>
                                                    <th className={"table-th"}>Contractor</th>
                                                    <th className={"table-th"}>Date</th>
                                                    <th className={"table-th"}>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {jobs && jobs.length > 0
                                                    ? jobs.map(job => (
                                                        <tr key={job._id} className={"table-tr"}>
                                                            <td className={"table-td"}>
                                                                <Link
                                                                    className={"job-link client-name-contracts job-client-font"}
                                                                    to={"/job/" + job._id}
                                                                >
                                                                    {job.jobName.length < 30 ? job.jobName : job.jobName.substring(0,30) + '...'}
                                                                </Link>
                                                            </td>
                                                            <td className={"table-td"}>
                                                                <Link
                                                                    className={
                                                                        "job-link client-name-contracts"
                                                                    }
                                                                    to={"/contractor-info/" + job.contractorId._id}
                                                                >
                                                                    {job.contractorId.first_name} {job.contractorId.last_name}
                                                                </Link>
                                                            </td>
                                                            <td className={"table-td"}>{job.dateTime}</td>
                                                            { job.status === 'Pending approval' ? (
                                                                <td className={"table-td tb-submit-work"}>
                                                                    <Button
                                                                        className={"btn-submit-work-client"}
                                                                        color={"success"}
                                                                        onClick={(e) => this.props.payWork(job._id)}
                                                                    >
                                                                        View Payment Request
                                                                    </Button>
                                                                </td>
                                                            ) : (
                                                                <td className={"table-td"}>{job.status}</td>
                                                            ) }

                                                        </tr>
                                                    ))
                                                    : null}
                                                </tbody>
                                            </table>
                                        ) : <ContractAccordions
                                                jobs={this.props.jobs}
                                                payWork={this.props.payWork}
                                                classes={classes}
                                            />
                                }

                            </div>
                        ) : (
                            <h4 className={"empty-msg"}>No contracts yet...</h4>
                        )}


                        {jobs && jobs.length !== 0 && (
                            <div className={"pagination-container-hire"}>
                                <Pagination
                                    activePage={this.props.activePage}
                                    totalItemsCount={this.props.jobCount}
                                    onChange={this.handlePageChange}
                                />
                            </div>
                        )}
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}

export default connect(
    store => {
        return {
            user: store.users.user
        }
    }, {
        getUser: userActions.getUser
    }
)(withStyles(customerDashboardStyle)(ContractsCard));
