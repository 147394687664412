import React from "react";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import MainLayout from "../../views/Layouts/MainLayout";
import { accountTypeConstants } from '../../constants/acoount-types';

class ProtectedRoute extends React.Component {
    render() {
      const { component: Component, ...rest } = this.props;
      return (
        <Route {...rest} render={(props) => (
          this.props.user && (this.props.accountType ? this.props.user.account_type === accountTypeConstants[this.props.accountType] : true)
            ? <MainLayout><Component {...props} /></MainLayout>
            : <Redirect to={{pathname: '/login-page',}} />
        )} />
      )
    }
  } 

  const mapStateToProps = (state) => {
    return {
        user : state.users.user
    };
};

export default connect(mapStateToProps, null)(ProtectedRoute);