import { jobConstants } from "../constants";
import { jobService } from "../services";
import { alertActions } from "./";
import { history } from "../helpers";

export const jobActions = {
  createJob,
  getUserJobsPaginated,
  getUserContractsJobsPaginated,
  deleteJobById,
  getUserJobById,
  getJobById,
  updateUserJob,
  updateContractorJob,
  cancelContractorJob,
  getAllJobsPaginated,
  getContractorJobsPaginated,
  searchJobsPaginated,
  getJobByselfId
};

function createJob(jobObj) {
  return async dispatch => {
    try {
      dispatch(request(jobObj));
      const user = await jobService.createJob(jobObj);
      dispatch(success(user));
      history.push("/customer-dashboard");
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.POST_JOB_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.POST_JOB_SUCCESS, job };
  }
  function failure(error) {
    return { type: jobConstants.POST_JOB_FAILURE, error };
  }
}

function getUserJobsPaginated(page) {
  return async dispatch => {
    try {
      dispatch(request());
      const userJobs = await jobService.getJobsPaginated(page);
      dispatch(success(userJobs));
      return userJobs;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.GET_JOBS_PAGINATED_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.GET_JOBS_PAGINATED_SUCCESS, job };
  }
  function failure(error) {
    return { type: jobConstants.GET_JOBS_PAGINATED_FAILURE, error };
  }
}

function getUserContractsJobsPaginated(page) {
  return async dispatch => {
    try {
      dispatch(request());
      const userJobs = await jobService.getJobsPaginatedClientContracts(page);
      dispatch(success(userJobs));
      return userJobs;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.GET_JOBS_CONTRACTOR_PAGINATED_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.GET_JOBS_CONTRACTOR_PAGINATED_SUCCESS, job };
  }
  function failure(error) {
    return { type: jobConstants.GET_JOBS_CONTRACTOR_PAGINATED_FAILURE, error };
  }
}

function deleteJobById(jobId) {
  return async dispatch => {
    try {
      dispatch(request());
      const userJobs = await jobService.deleteJobById(jobId);
      dispatch(success(userJobs));
      return userJobs;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.DELETE_JOB_BY_ID_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.DELETE_JOB_BY_ID_SUCCESS, job };
  }
  function failure(error) {
    return { type: jobConstants.DELETE_JOB_BY_ID_FAILURE, error };
  }
}

function getUserJobById(jobId) {
  return async dispatch => {
    try {
      dispatch(request());
      const userJobs = await jobService.getUserJobById(jobId);
      dispatch(success(userJobs));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.GET_USER_JOB_BY_ID_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.GET_USER_JOB_BY_ID_SUCCESS, job };
  }
  function failure(error) {
    return { type: jobConstants.GET_USER_JOB_BY_ID_FAILURE, error };
  }
}
function getJobByselfId(jobId) {
  return async dispatch => {
    try {
      dispatch(request());
      const userJobs = await jobService.getJobByselfId(jobId);
      dispatch(success(userJobs));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.GET_USER_JOB_BY_ID_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.GET_USER_JOB_BY_ID_SUCCESS, job };
  }
  function failure(error) {
    return { type: jobConstants.GET_USER_JOB_BY_ID_FAILURE, error };
  }
}

function getJobById(jobId) {
  return async dispatch => {
    try {
      dispatch(request());
      const userJobs = await jobService.getJobById(jobId);
      dispatch(success(userJobs));
      return userJobs;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.GET_JOB_BY_ID_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.GET_JOB_BY_ID_SUCCESS, job };
  }
  function failure(error) {
    return { type: jobConstants.GET_JOB_BY_ID_FAILURE, error };
  }
}

function updateUserJob(jobId, jobObject) {
  return async dispatch => {
    try {
      dispatch(request());
      const userJobs = await jobService.updateUserJobById(jobId, jobObject);
      dispatch(success(userJobs));
      return userJobs;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.UPDATE_JOB_BY_ID_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.UPDATE_JOB_BY_ID_REQUEST, job };
  }
  function failure(job) {
    return { type: jobConstants.UPDATE_JOB_BY_ID_REQUEST, job };
  }
}

function updateContractorJob(jobId, jobObject) {
  return async dispatch => {
    try {
      dispatch(request());
      const userJobs = await jobService.updateContractorJobById(
        jobId,
        jobObject
      );
      dispatch(success(userJobs));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.UPDATE_JOB_BY_ID_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.UPDATE_JOB_BY_ID_REQUEST, job };
  }
  function failure(job) {
    return { type: jobConstants.UPDATE_JOB_BY_ID_REQUEST, job };
  }
}

function cancelContractorJob(jobId, jobObject) {
  return async dispatch => {
    try {
      dispatch(request());
      const userJobs = await jobService.cancelContractorJobById(
        jobId,
        jobObject
      );
      dispatch(success(userJobs));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.UPDATE_JOB_BY_ID_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.UPDATE_JOB_BY_ID_REQUEST, job };
  }
  function failure(job) {
    return { type: jobConstants.UPDATE_JOB_BY_ID_REQUEST, job };
  }
}

function getAllJobsPaginated(page) {
  return async dispatch => {
    try {
      dispatch(request());
      const allJobs = await jobService.getAllJobsPaginated(page);
      dispatch(success(allJobs));
      return allJobs;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.GET_ALL_USER_JOBS_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.GET_ALL_USER_JOBS_SUCCESS, job };
  }
  function failure(job) {
    return { type: jobConstants.GET_ALL_USER_JOBS_FAILURE, job };
  }
}
function getContractorJobsPaginated(page) {
  return async dispatch => {
    try {
      dispatch(request());
      const allJobs = await jobService.getContractorJobsPaginated(page);
      dispatch(success(allJobs));
      return allJobs;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.GET_ALL_USER_JOBS_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.GET_ALL_USER_JOBS_SUCCESS, job };
  }
  function failure(job) {
    return { type: jobConstants.GET_ALL_USER_JOBS_FAILURE, job };
  }
}
function searchJobsPaginated(text, page) {
  return async dispatch => {
    try {
      dispatch(request());
      const allJobs = await jobService.searchJobsPaginated(text, page);
      dispatch(success(allJobs));
      return allJobs;
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(job) {
    return { type: jobConstants.SEARCH_USER_JOBS_REQUEST, job };
  }
  function success(job) {
    return { type: jobConstants.SEARCH_USER_JOBS_SUCCESS, job };
  }
  function failure(job) {
    return { type: jobConstants.SEARCH_USER_JOBS_FAILURE, job };
  }
}
