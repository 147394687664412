import React from 'react'
import {urlConstants} from "../../constants/api-url";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import calculationPageStyle
    from "assets/jss/material-kit-pro-react/views/calculationPageStyle.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import stripeLogo from "assets/img/Stripe-Payment-Logo.png";
import paypalLogo from "assets/img/Paypal-Payment-Logo.png";
import Button from "components/CustomButtons/Button.jsx";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {userActions} from "../../actions";

class CalculationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHidden: false,
            checked: [1],
            simpleSelect: "",
            multipleSelect: [],
            selectedEnabled: "a",
            sizeMeter: 1,
            sizeMeterError: false,
            meterCost: 0,
            meterCostError: false,
            minimumPrice: 0,
            minimumPriceError: false,
            obstructionFee: 0,
            obstructionFeeError: false,
            additionalCost: 0,
            additionalCostError: false,
            finalCost: 0,
            finalCostError: false,
            emailError: false,
            firstNameError: false,
            lastNameError: false
        };
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle(value) {
        const {checked} = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    }
    handleSimple = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleChangeEnabled(event) {
        this.setState({selectedEnabled: event.target.value});
    };

    handleChange = (event, name) => {
        this.setState({[name]: event.target.value},
            () => {
            const finalCost = ( parseInt(this.state.sizeMeter) *  parseInt(this.state.meterCost) )
                + parseInt(this.state.minimumPrice) + parseInt(this.state.obstructionFee) + parseInt(this.state.additionalCost);
            this.setState({finalCost: parseInt(finalCost)});
        });
    };

    handleBlur = (event, name) => {
        switch (name) {
            case 'emailStatus':
                let emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (emailRex.test(event.target.value)) {
                    this.setState({[name+'Error']: false});
                } else {
                    this.setState({[name+'Error']: true});
                }
            break;
            default:
                if (event.target.value === '') {
                    this.setState({[name+'Error']: true});
                } else {
                    this.setState({[name+'Error']: false});
                }
            break;
        }
    };

    async toggleInput() {
        this.setState({
            isHidden: !this.state.isHidden
        });
    }

    sendEmail = () => {
        this.toggleInput().then( () => {
        });
    };

    render() {
        const {
        classes, ...rest
        } = this.props;
        return (
            <div>
                <Header
                    absolute
					logoLink = {urlConstants.LANDING_URL}
                    color="transparent"
                    brand="Tec Mow"
                    links={<HeaderLinks dropdownHoverColor="rose"/>}
                    {...rest}
                />
                <div
                    className={classes.pageHeader}
                    style={{}}
                >
                    {
                        !this.state.isHidden &&
                        <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={8} md={8}>
                                    <Card className={classes.cardSignup}>
                                        <h2 className={classes.cardTitle}>Lawn
                                            Mowing Calculator</h2>
                                        <CardBody
                                            className={
                                                classes.marginTop30
                                            }
                                        >
                                            <GridContainer justify="center">
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <form
                                                        className={classes.form}>
                                                        <div>
                                                            <div>
                                                                <CustomInput
                                                                    labelText="Lawn Size in Square Meter"
                                                                    id="float"
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                        className: [
                                                                            classes.customFormControlClasses,
                                                                            classes.marginBottom0
                                                                        ]
                                                                    }}
                                                                    inputProps={{
                                                                        value: this.state.sizeMeter,
                                                                        onChange: e => this.handleChange(e, 'sizeMeter'),
                                                                        onBlur: e => this.handleBlur(e, 'sizeMeter'),
                                                                        error: this.state.sizeMeterError,
                                                                        endAdornment: (
                                                                            <InputAdornment
                                                                                position="end">
                                                                                <p className={
                                                                                    classes.marginBottom0
                                                                                }>
                                                                                    &#x33a1;
                                                                                </p>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </div>
                                                            <p>
                                                                (Acre = 4046.85
                                                                m2)
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <CustomInput
                                                                labelText="Price Per Square Meter"
                                                                id="float"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    className: [
                                                                        classes.customFormControlClasses,
                                                                        classes.marginBottom0
                                                                    ]
                                                                }}
                                                                inputProps={{
                                                                    value: this.state.meterCost,
                                                                    onChange: e => this.handleChange(e, 'meterCost'),
                                                                    onBlur: e => this.handleBlur(e, 'meterCost'),
                                                                    error: this.state.meterCostError,
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start">
                                                                            <p className={
                                                                                classes.marginBottom0
                                                                            }>
                                                                                $
                                                                            </p>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <p>
                                                                (example 0.04)
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <CustomInput
                                                                labelText="Minimum Price"
                                                                id="float"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    className: [
                                                                        classes.customFormControlClasses,
                                                                        classes.marginBottom0
                                                                    ]
                                                                }}
                                                                inputProps={{
                                                                    value: this.state.minimumPrice,
                                                                    onChange: e => this.handleChange(e, 'minimumPrice'),
                                                                    onBlur: e => this.handleBlur(e, 'minimumPrice'),
                                                                    error: this.state.minimumPriceError,
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start">
                                                                            <p className={
                                                                                classes.marginBottom0
                                                                            }>
                                                                                $
                                                                            </p>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <p className={
                                                                classes.paddingLeft15
                                                            }>
                                                                (put 0 if not
                                                                applicable)
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <CustomInput
                                                                labelText="Obstruction Fee"
                                                                id="float"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    className: [
                                                                        classes.customFormControlClasses,
                                                                        classes.marginBottom0
                                                                    ]
                                                                }}
                                                                inputProps={{
                                                                    value: this.state.obstructionFee,
                                                                    onChange: e => this.handleChange(e, 'obstructionFee'),
                                                                    onBlur: e => this.handleBlur(e, 'obstructionFee'),
                                                                    error: this.state.obstructionFeeError,
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start">
                                                                            <p className={
                                                                                classes.marginBottom0
                                                                            }>
                                                                                $
                                                                            </p>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <p className={
                                                                classes.paddingLeft15
                                                            }>
                                                                (put 0 n/a,
                                                                example
                                                                trees, fire
                                                                pits,
                                                                pools, hills)
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <CustomInput
                                                                labelText="Additional cost"
                                                                id="float"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    className: [
                                                                        classes.customFormControlClasses,
                                                                        classes.marginBottom0
                                                                    ]
                                                                }}
                                                                inputProps={{
                                                                    value: this.state.additionalCost,
                                                                    onChange: e => this.handleChange(e, 'additionalCost'),
                                                                    onBlur: e => this.handleBlur(e, 'additionalCost'),
                                                                    error: this.state.obstructionFeeError,
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start">
                                                                            <p className={
                                                                                classes.marginBottom0
                                                                            }>
                                                                                $
                                                                            </p>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <p className={
                                                                classes.paddingLeft15
                                                            }>
                                                                (example:
                                                                edging,
                                                                blowing,
                                                                trimming)
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <CustomInput
                                                                labelText="Calculated Cost"
                                                                id="float"
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    className: [
                                                                        classes.customFormControlClasses,
                                                                        classes.marginBottom0
                                                                    ]
                                                                }}
                                                                inputProps={{
                                                                    value: this.state.finalCost || 0,
                                                                    disabled: true,
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start">
                                                                            <p className={
                                                                                classes.marginBottom0
                                                                            }>
                                                                                $
                                                                            </p>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                classes.marginTop25 + " "
                                                                + classes.textCenter
                                                            }>
                                                            <Link to={'/login-page'}
                                                                  color={window.innerWidth < 960 ? "info" : "white"}
                                                                  className={
                                                                      classes.font700 + " "
                                                                      + classes.font20
                                                                  }
                                                            >
                                                                Login and post a job
                                                            </Link>
                                                        </div>
                                                    </form>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>
                    }

                    {
                        this.state.isHidden &&
                        <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={8} md={8}>
                                    <Card className={classes.cardSignup}>
                                        <h2 className={classes.cardTitle}>Payment
                                            Gateway</h2>
                                        <CardBody
                                            className={
                                                classes.marginTop30
                                            }
                                        >
                                            <GridContainer justify="center">
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <form className={classes.form}>
                                                        <div>
                                                            <CustomInput
                                                                id="email"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    placeholder: "Email...",
                                                                    type: "email",
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <Email className={classes.inputIconsColor} />
                                                                        </InputAdornment>
                                                                    ),
                                                                    onBlur: e => this.handleBlur(e, 'emailStatus'),
                                                                    error: this.state.emailStatusError
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <CustomInput
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    className: classes.customFormControlClasses
                                                                }}
                                                                inputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start"
                                                                            className={classes.inputAdornment}
                                                                        >
                                                                            <Icon className={classes.inputAdornmentIcon}>
                                                                                face
                                                                            </Icon>
                                                                        </InputAdornment>
                                                                    ),
                                                                    placeholder: "First Name...",
                                                                    onBlur: e => this.handleBlur(e, 'firstName'),
                                                                    error: this.state.firstNameError
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <CustomInput
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                    className: classes.customFormControlClasses
                                                                }}
                                                                inputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start"
                                                                            className={classes.inputAdornment}
                                                                        >
                                                                            <Icon className={classes.inputAdornmentIcon}>
                                                                                face
                                                                            </Icon>
                                                                        </InputAdornment>
                                                                    ),
                                                                    placeholder: "Last Name...",
                                                                    onBlur: e => this.handleBlur(e, 'lastName'),
                                                                    error: this.state.lastNameError
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className={
                                                                classes.displayInlineBlock + " "
                                                                + classes.width48 + " "
                                                                + classes.textCenter
                                                            }>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Radio
                                                                            checked={this.state.selectedEnabled === "a"}
                                                                            onChange={this.handleChangeEnabled}
                                                                            value="a"
                                                                            name="radio button enabled"
                                                                            aria-label="A"
                                                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                                            checkedIcon={<FiberManualRecord className={classes.radioChecked}/>}
                                                                            classes={{
                                                                                checked: classes.radio,
                                                                                root: classes.radioRoot
                                                                            }}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classes.label
                                                                    }}
                                                                    className={
                                                                        classes.noLeftMargin
                                                                    }
                                                                    label="PayPal"
                                                                />
                                                                <img src={paypalLogo} height="80px" alt="..." />
                                                            </div>
                                                            <div className={
                                                                classes.displayInlineBlock + " "
                                                                + classes.width48 + " "
                                                                + classes.textCenter
                                                            }>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Radio
                                                                            checked={this.state.selectedEnabled === "b"}
                                                                            onChange={this.handleChangeEnabled}
                                                                            value="b"
                                                                            name="radio button enabled"
                                                                            aria-label="B"
                                                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                                            checkedIcon={<FiberManualRecord className={classes.radioChecked}/>}
                                                                            classes={{
                                                                                checked: classes.radio,
                                                                                root: classes.radioRoot
                                                                            }}
                                                                        />
                                                                    }
                                                                    classes={{
                                                                        label: classes.label
                                                                    }}
                                                                    className={
                                                                        classes.noLeftMargin
                                                                    }
                                                                    label="Stripe"
                                                                />
                                                                <img src={stripeLogo} height="80px" alt="..." />
                                                            </div>
                                                            <div className={classes.textCenter}>
                                                                <Button round color="primary"
                                                                        className={
                                                                            classes.marginTop20
                                                                        }
                                                                >
                                                                    Make Payment
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </div>
                    }

                    <Footer
                        content={
                            <div>
                            </div>
                        }
                    />
                </div>
            </div>
        );
    }
}

function loginUser() {
    return async (dispatch, getStore) => {
        const user = {
            name: 'test',
            email: 'test'
        };
        dispatch({type: "USERS_LOGIN_REQUEST", user})
    }
}

export default connect(
    store => {
        return {
            user: store.users.user
        }
    }, {
        login: userActions.login
    }
)(withStyles(calculationPageStyle)(CalculationPage));
