/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Pagination from "components/Pagination/PaginationComponent";
import ShowMore from "react-show-more";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import SmallPreloaderComponent from "../../../components/PreloaderComponent/SmallPreloaderComponent";

import "assets/css/style.css";

import jobDetailsPageStyle from "assets/jss/material-kit-pro-react/views/jobDetailsPageStyle.jsx";
import "assets/css/style.css";
// eslint-disable-next-line react/prop-types

class AllJobsContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobsTableData: []
    };
  }

  handlePageChange = pageNumber => {
    this.props.changeActivePage(pageNumber);
    if (this.props.searchFlag === false) {
      this.props.call(pageNumber);
    } else {
      this.props.searchPagin(pageNumber);
    }
  };

  componentDidMount() {

  }

  render() {
    const { classes, jobs, jobsLoading, errorActivationByAdmin, searchNotFount } = this.props;
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardBody>
            <h3 className={classes.cardTitle + " all-jobs-height"}>
              <p
                className={
                  classes.cardTitleText +
                  " " +
                  classes.customerCardTitle +
                  " all-jobs-height" + " title-pages"
                }
              >
                All Jobs
              </p>
            </h3>

            {jobsLoading && (
                <SmallPreloaderComponent />
            )}

            {jobs ? jobs.length > 0
              ? jobs.map(job => (
                  <div key={job._id} custom-attribute={job._id}>
                    <span className={"v-j-variable"} />
                    <Link
                      className={"job-link all-jobs-name job-title"}
                      to={"/job/" + job._id}
                    >
                      {job.jobName}
                    </Link>
                    <ShowMore
                      lines={2}
                      more="Show more"
                      less="Show less"
                      anchorClass="react-show-more"
                    >
                      <div>{job.jobDescription}</div>
                    </ShowMore>
                    <hr/>
                  </div>
                )) || <h4 className={"empty-msg"}>No jobs found...</h4>
                : searchNotFount? <h4> {searchNotFount} </h4>: errorActivationByAdmin.error? <div>
                  <p >Thank you for signing up to Tec Mow as a contractor.  Great contractors are the core of our business, so we are very pleased to be welcoming you here.</p>
                  <p>We need to make sure our contractors meet our high standards so we will need to contact you and approve your account before sending you to do jobs.</p>
                  <p>Please make sure that:</p>
                  <ul>
                    <li>You have the right to work in the UK</li>
                    <li>Proof of identity</li>
                    <li>You have a clean criminal record</li>
                    <li>You have two references</li>
                    <li>You have Public Liability insurance</li>
                  </ul>
                </div> : <h4 className={"empty-msg"}>No jobs yet...</h4> : <></>}

            {jobs ? jobs.length !== 0 && (
              <div className={"pagination-container-hire"}>
                <Pagination
                  activePage={this.props.activePage}
                  totalItemsCount={this.props.jobCount}
                  onChange={this.handlePageChange}
                />
              </div>
            ) : <></>}
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

export default withStyles(jobDetailsPageStyle)(AllJobsContractor);
