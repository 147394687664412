import { authHeader, postHeader, history, handleResponse } from "../helpers";
import { urlConstants } from "../constants";

export const paymentService = {
  createJobPayment,
  createJobPaymentExecute,
  batchPayouts,
  createJobPaymentOvertime,
  getPaymentById,
  sendPayment,
  getOvertime
};

function getPaymentById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/payment-paypal/get-payment/` + id,
    requestOptions
  ).then(handleResponse);
}

// function checkPayment(id) {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader()
//   };
//
//   return fetch(
//     urlConstants.BACKEND_URL + `/payment-paypal/get-payment/` + id,
//     requestOptions
//   ).then(handleResponse);
// }
function getOvertime(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    urlConstants.BACKEND_URL + `/payment-paypal/get-overtime/` + id,
    requestOptions
  ).then(handleResponse);
}

function createJobPayment(payment) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(payment)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/payment-paypal/create`,
    requestOptions
  ).then(handleResponse);
}

function sendPayment(payment) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(payment)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/payment-paypal/send-payment`,
    requestOptions
  ).then(handleResponse);
}

function createJobPaymentOvertime(payment) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(payment)

  };

  return fetch(
    urlConstants.BACKEND_URL + `/payment-paypal/create/overtime`,
    requestOptions
  ).then(handleResponse);
}

function createJobPaymentExecute(payment) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(payment)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/payment-paypal/execute`,
    requestOptions
  ).then(handleResponse);
}
function batchPayouts(payment) {
  const requestOptions = {
    method: "POST",
    headers: postHeader(),
    body: JSON.stringify(payment)
  };

  return fetch(
    urlConstants.BACKEND_URL + `/payment-paypal/batch`,
    requestOptions
  ).then(handleResponse);
}
