import { jobConstants } from "../constants";

const initialState = {
  jobs: null,
  allJobsPaginated: [],
  myJobs: []
};

export function jobs(state = initialState, action) {
  // console.log(state, action);
  switch (action.type) {
    case jobConstants.POST_JOB_SUCCESS:
      return {
        ...state
      };
    case jobConstants.GET_JOBS_PAGINATED_SUCCESS:
      return {
        ...state,
        myJobs: action.job.jobs,
        jobsPaginated: action.job.jobsPaginated,
        // myJobs: action.job.jobs,
        jobsCount: action.job.jobsCount
      };
    case jobConstants.GET_USER_JOB_BY_ID_SUCCESS:
      return {
        job: action.job.job
      };

    case jobConstants.GET_ALL_USER_JOBS_SUCCESS:
      return {
        allJobsPaginated: action.job.jobs,
        allJobsCount: action.job.jobsCount
      };
    default:
      return state;
  }
}
