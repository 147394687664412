/* eslint-disable */
import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import photoPlaceholder from "assets/img/faces/photoPlaceholder.jpg";
import customerPageStyle from "assets/jss/material-kit-pro-react/views/customerPageStyle.jsx";
import {connect} from "react-redux";
import {userActions} from "../../actions";
import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent";

class CustomerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusy: false,
    };
  }

  componentDidMount() {
    this.setState({['isBusy']: true});
 	let userId = this.props.match.params.id;
    this.props.getUsersById(userId).then(
        res => {
          this.setState({...res.user});
          this.setState({['isBusy']: false});
        },
        err => {
          console.log(err);
          this.setState({['isBusy']: false});
          this.props.history.push("login-page");
        }
    );

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
	editProfileButtonClick = (id) =>  {
        this.props.history.push("/edit-profile/");
    };
  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container + " "
                        + classes.customerProfileContainer}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
					<img src={this.state.photo_url ? this.state.photo_url : photoPlaceholder} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>
                      {
                        this.props.user &&
                        this.state.first_name + " "
                        + this.state.last_name
                      }
                    </h3>
					<h6>Contractor</h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div
              className={classNames(classes.description, classes.textCenter)}
            >
              <p>
                Email: {this.state.email}
              </p>
			  <p>
				Phone: {this.state.phone}
			  </p>
			 {this.state.time_to_call === 1 ? <p>Best time to call: 8AM - 10AM</p> :
			 	this.state.time_to_call === 2 ? <p>Best time to call: 10AM - 12PM</p>:
				this.state.time_to_call === 3 ? <p>Best time to call: 12PM - 2PM</p>:
				this.state.time_to_call === 4 ? <p>Best time to call: 2PM - 4PM</p>:<p>Best time to call: 4PM - 6PM</p>
			 }
				<div>
					<p>City: {this.state.city}</p>
					<p>Address: {this.state.address}</p>
					<p>House number: {this.state.house_number}</p>
					{/* <p>Flat number: {this.state.flat}</p> */}
					<p>Post code: {this.state.postcode}</p>
				</div>
              {this.state.about_myself ? (
				<div>About me: <p dangerouslySetInnerHTML={{ __html: this.state.about_myself.replace(/\n/ig, '</p><p>').replace('<style', '') }}></p></div>
              ) : (<div>About me: - </div>)}
            </div>
            <Clearfix />
          </div>
        </div>
        {
          this.state.isBusy &&

          <PreloaderComponent/>
        }
      </div>
    );
  }
}

export default connect(
    store => {
      return {
        user: store.users.user
      }
    }, {
      getUsersById: userActions.getUsersById
    }
)(withStyles(customerPageStyle)(CustomerPage));
