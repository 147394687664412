import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiAccordion from "@material-ui/core/ExpansionPanel";
import MuiAccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiAccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Button from "../../../components/CustomButtons/Button";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import {Link} from "react-router-dom";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: "12px"
  }
}))(MuiAccordionDetails);

export default function ContractAccordions(props) {
  const { jobs, payWork, classes } = props;
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      { jobs && jobs.length > 0
          ? jobs.map((job, index) => (
              <Accordion
                  key={index}
                  square
                  expanded={expanded === `panel${index+1}`}
                  onChange={handleChange(`panel${index+1}`)}
              >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>
                    Job - {' '}
                    <Link
                      className={"job-link client-name-contracts job-client-font"}
                      to={"/job/" + job._id}
                    >
                      {job.jobName.length < 30 ? job.jobName : job.jobName.substring(0,30) + '...'}
                    </Link>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.mobileWrapTableItem}>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Contractor
                      </div>
                      <div className={classes.mobileTableValue}>
                        <Link
                            className={
                              "job-link client-name-contracts"
                            }
                            to={"/contractor-info/" + job.contractorId._id}
                        >
                          {job.contractorId.first_name} {job.contractorId.last_name}
                        </Link>
                      </div>
                    </div>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Date
                      </div>
                      <div className={classes.mobileTableValue}>
                        {job.dateTime}
                      </div>
                    </div>
                    <div className={classes.mobileTableItem}>
                      <div className={classes.mobileTableName}>
                        Status
                      </div>
                      {job.status === 'Pending approval' ? (
                          <div className={classes.mobileTableValue}>
                            <Button className={"btn-submit-work-client"} color={"success"}
                                    onClick={() => payWork(job._id)}>
                              View Payment Request
                            </Button>
                          </div>
                      ) : (
                          <div className={classes.mobileTableValue}>{job.status}</div>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
          )) : null }
    </div>
  );
}
