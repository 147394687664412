/* eslint-disable */
import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Button from "components/CustomButtons/Button.jsx";
import photoPlaceholder from "assets/img/faces/photoPlaceholder.jpg";
import customerPageStyle from "assets/jss/material-kit-pro-react/views/customerPageStyle.jsx";
import {connect} from "react-redux";
import PreloaderComponent
  from "../../components/PreloaderComponent/PreloaderComponent";

class CustomerPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isBusy: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
	editProfileButtonClick = (id) =>  {
        this.props.history.push("/edit-profile/");
    };
  render() {
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container  + " "
                        + classes.customerProfileContainer}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
			              <img src={this.props.user.photo_url ? this.props.user.photo_url : photoPlaceholder} alt="Photo" className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>
                      {
                        this.props.user &&

                        this.props.user.first_name + " "
                        + this.props.user.last_name

                      }
                    </h3>
					{this.props.user.account_type === 1?(
						<h6>Customer</h6>
					) : (<h6>Contractor</h6>)}
                  </div>
                </div>
                <div className={classes.follow}>
                  <Tooltip
                    id="tooltip-top"
                    title="Update profile details"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      justIcon
                      round
                      color="primary"
                      className={classes.followButton}
                      onClick={(e) => this.editProfileButtonClick(this.props.user._id)}
                    >
                      <Edit className={classes.followIcon} />
                    </Button>
                  </Tooltip>
                </div>
              </GridItem>
            </GridContainer>
			<GridContainer>
                                <div
              className={classNames(classes.description, classes.textCenter)}
            >
              <p>
                Email: {this.props.user.email}
              </p>
			  <p>
				Phone: {this.props.user.phone}
			  </p>
			 {this.props.user.time_to_call === 1 ? <p>Best time to call: 8AM - 10AM</p> :
			 	this.props.user.time_to_call === 2 ? <p>Best time to call: 10AM - 12AM</p>:
				this.props.user.time_to_call === 3 ? <p>Best time to call: 12AM - 2PM</p>:
				this.props.user.time_to_call === 4 ? <p>Best time to call: 2PM - 4PM</p>:<p>Best time to call: 4PM - 6PM</p>
			 }
				{this.props.user.account_type === 1?(
						null
					) : (<div>
					<p>City: {this.props.user.city}</p>
					<p>Address: {this.props.user.address}</p>
					<p>House number: {this.props.user.house_number}</p>
					<p>Post code: {this.props.user.postcode}</p>
				</div>)}
                {this.props.user.about_myself ? (
                    <div>About me: <p dangerouslySetInnerHTML={{ __html: this.props.user.about_myself.replace(/\n/ig, '</p><p>').replace('<style', '') }}></p></div>
                ) : (<div>About me: - </div>)}
            </div>
            <Clearfix />
                        </GridContainer>
                        <Clearfix/>
          </div>
        </div>
        
        {this.state.isBusy && <PreloaderComponent/>}
      </div>
    );
  }
}

export default connect(
    store => {
      return {
        user: store.users.user
      }
    }, null
)(withStyles(customerPageStyle)(CustomerPage));
