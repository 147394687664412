import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import customDateTimeComponent from "../../assets/jss/material-kit-pro-react/components/customDateTimeComponentStyle";
import Datetime from "react-datetime";

function CustomDateTimeEndComponent({ ...props }) {
  const {
    classes,
    formControlProps,
    inputProps,
    error,
    success,
    placeholder,
    value,
    dateTime
  } = props;

  let yesterday;
  if (dateTime !== "") {
    yesterday = Datetime.moment(dateTime, "DD-MM-YYYY hh:mm A").subtract(1, "day");
  } else {
    yesterday = Datetime.moment().subtract(1, "day");
  }
  const valid = function(current) {
    return current.isAfter(yesterday);
  };

  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }

  return (
    <FormControl
      fullWidth
      {...formControlProps}
      className={
        formControlClasses +
        " " +
        (inputProps.error ? classes.redColorUnderline : "")
      }
    >
      <Datetime
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={value}
        viewMode={"days"}
        dateFormat={"DD-MM-YYYY"}
        inputProps={{
          placeholder: placeholder,
          readOnly: true
        }}
        isValidDate={valid}
      />
      {inputProps.error && inputProps.errormessage && (
        <span className={classes.labelRootError}>
          {inputProps.errormessage}
        </span>
      )}
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomDateTimeEndComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  errormessage: PropTypes.string,
  success: PropTypes.bool,
  white: PropTypes.bool
};

export default withStyles(customDateTimeComponent)(CustomDateTimeEndComponent);
