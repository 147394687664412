import { userConstants } from "../constants";
import { userService } from "../services";
import { alertActions } from "./";
import { history } from "../helpers";

export const userActions = {
  login,
  logout,
  register,
  update,
  registerConfirmed,
  resetPass,
  resetPassConfirm,
  getAll,
  getUser,
  getUsersById,
  delete: _delete
};

function login(username, password) {
  return async dispatch => {
    try {
      dispatch(request({ username }));
      const user = await userService.login(username, password);
      // localStorage.setItem('user', JSON.stringify(user.user));
      dispatch(success(user));
    } catch (err) {
      dispatch(failure(err));
      dispatch(alertActions.error(err));
      throw err;
    }
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return dispatch => {
    userService.logout();
    dispatch({ type: userConstants.LOGOUT });
  }
}

function register(userObj) {
  return async dispatch => {
    try {
      dispatch(request(userObj));
      const user = await userService.register(userObj);
      dispatch(success(user));
      history.push("/login");
      dispatch(alertActions.success("Registration successful"));
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      throw error;
    }
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}
function update(userObj) {
	  return async dispatch => {
	    try {
	      dispatch(request(userObj));
	      const user = await userService.update(userObj);
        dispatch(success(user));
	    } catch (error) {
	      dispatch(failure(error));
	      dispatch(alertActions.error(error));
	      throw error;
	    }
	  };

	  function request(user) {
	    return { type: userConstants.UPDATE_REQUEST, user };
	  }
	  function success(user) {
	    return { type: userConstants.UPDATE_SUCCESS, user: user.user };
	  }
	  function failure(error) {
	    return { type: userConstants.UPDATE_FAILURE, error };
	  }
	}

function registerConfirmed(userID) {
  return dispatch => {
    dispatch(request(userID));
    userService.registerConfirm(userID).then(
      user => {
        dispatch(success());
        dispatch(alertActions.success("Registration successful"));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, userID };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, userID };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, userID };
  }
}

function resetPass(username) {
  return async dispatch => {
    try {
      dispatch(request(username));
      const user = await userService.resetPass(username);
      dispatch(success(user));
      dispatch(alertActions.success("Reset successful"));
    } catch (err) {
      dispatch(failure(err));
      dispatch(alertActions.error(err));
      throw err;
    }
  };

  function request(user) {
    return { type: userConstants.RESET_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESET_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESET_FAILURE, error };
  }
}

function resetPassConfirm(userID, password) {
  return async dispatch => {
    try {      
      dispatch(request(password));
      const user = await userService.resetPassConfirm(userID, password);
      dispatch(success(user));
      dispatch(alertActions.success("Reset confirm successful"));
    } catch (err) {
      dispatch(failure(err));
      dispatch(alertActions.error(err));
      throw err;
    }
  };

  function request(user) {
    return { type: userConstants.RESET_CONFIRM_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESET_CONFIRM_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESET_CONFIRM_FAILURE, error };
  }
}

function getUser() {
  return async dispatch => {
    if (!localStorage.getItem("token")) return;
    try {
      dispatch(request());
      const user = await userService.getUserData();
      dispatch(success(user));
      dispatch(alertActions.success("User data get request successful"));
      return user;
    } catch (err) {
      dispatch(failure(err));
      dispatch(alertActions.error(err));
      throw err;
    }
  };

  function request() {
    return { type: userConstants.GETUSER_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETUSER_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETUSER_FAILURE, error };
  }
}

function getUsersById(id) {
  return async dispatch => {
    try {
      // dispatch(success(user));
      // dispatch(alertActions.success("User data get request successful"));
      return await userService.getUserById(id);
    } catch (err) {
      // dispatch(failure(err));
      // dispatch(alertActions.error(err));
      throw err;
    }
  };

  function request() {
    return { type: userConstants.GETUSER_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETUSER_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETUSER_FAILURE, error };
  }
}

function getAll() {
  return dispatch => {
    dispatch(request());

    userService
      .getUserData()
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    userService.delete(id).then(
      user => {
        dispatch(success(id));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}
