export const overtimeConstants = {
  POST_OVERTIME_REQUEST: "OVERTIME_POST_REQUEST",
  POST_OVERTIME_SUCCESS: "OVERTIME_POST_SUCCESS",
  POST_OVERTIME_FAILURE: "OVERTIME_POST_FAILURE",

  GET_OVERTIME_REQUEST: "OVERTIME_GET_REQUEST",
  GET_OVERTIME_SUCCESS: "OVERTIME_GET_SUCCESS",
  GET_OVERTIME_FAILURE: "OVERTIME_GET_FAILURE",

  GET_OVERTIME_BY_ID_REQUEST: "OVERTIME_GET_BY_ID_REQUEST",
  GET_OVERTIME_BY_ID_SUCCESS: "OVERTIME_GET_BY_ID_SUCCESS",
  GET_OVERTIME_BY_ID_FAILURE: "OVERTIME_GET_BY_ID_FAILURE",
};
