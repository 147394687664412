/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import MyDropzone from "components/UploadFiles/MyDropzone.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import "assets/css/style.css";

import jobDetailsPageStyle from "assets/jss/material-kit-pro-react/views/jobDetailsPageStyle.jsx";
import "assets/css/style.css";
import { connect } from "react-redux";
import { jobActions, userActions } from "../../../actions";
import { proposalActions } from "../../../actions/proposal.actions";
// eslint-disable-next-line react/prop-types

class ProposalJobAdditionalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: "",
      preloader: false,
      value: "",
      filesArray: []
    };
  }

  componentDidMount() {
    console.log(this.state, this.props);
  }

  submitProposalForm = e => {
    e.preventDefault();
    // console.log(this.props.user._id, this.props.job._id, this.state.value, this.state.filesArray);

    const proposalObject = {
      user: this.props.user._id,
      job: this.props.job._id,
      coverLetter: this.state.value,
      img: this.state.filesArray,
      hire: "false"
    };

    this.props.createProposal(proposalObject).then(
      res => {
        console.log("good");
        // this.props.history.push("/customer-dashboard");
      },
      err => {
        console.log("error", err)
      }
    );
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  uploadFiles = files => {
    let base64Images = [];
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onloadend = () => {
        base64Images.push(reader.result);
        console.log( base64Images , "8789789798789798798789");
        this.setState({ filesArray: base64Images });
      };
    }
  };

  render() {
    const { classes, rest } = this.props;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card className={"cart-container-job-contractor-additional"}>
          <CardBody>
            <h3 className={"v-j-variable job-page-title"}>
              Additional details
            </h3>
            <hr />
            <p className={"v-j-variable"}>Cover letter</p>
            <form className={""} onSubmit={this.submitProposalForm}>
              <textarea
                className={"additional-textarea"}
                value={this.state.value}
                onChange={this.handleChange}
              />
              <p className={"v-j-variable"}>Attachments</p>

              <MyDropzone uploadFiles={this.uploadFiles} />

              <div className={"btn-additional-details"}>
                <Button
                  color="success"
                  className={"btn-drag-submit"}
                  type={"submit"}
                >
                  Submit a Proposal
                </Button>
                <Button
                  color="white"
                  className={"btn-drag-reset"}
                  type={"reset"}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    getJobById: jobActions.getJobById,
    createProposal: proposalActions.createProposal
  }
)(withStyles(jobDetailsPageStyle)(ProposalJobAdditionalDetails));
