import {
    container,
    cardTitle,
    title,
    mlAuto,
    mrAuto,
    main,
    whiteColor,
    mainRaised,
    grayColor,
    successColor
} from "assets/jss/material-kit-pro-react.jsx";

import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.jsx";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";
import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.jsx";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";

const jobPostingPageStyle = {
    container,
    ...imagesStyle,
    ...tooltipsStyle,
    ...customCheckboxRadioSwitchStyle,
    ...customSelectStyle,
    ...modalStyle,
    cardTitleWhite: {
        ...cardTitle,
        color: whiteColor + "  !important"
    },
    cardTitle,
    profile: {
        textAlign: "center",
        "& img": {
            maxWidth: "160px",
            width: "100%",
            margin: "0 auto",
            transform: "translate3d(0, -50%, 0)"
        }
    },
    description: {
        margin: "1.071rem auto 0",
        maxWidth: "600px",
        color: grayColor[0]
    },
    textCenter: {
        textAlign: "center !important"
    },
    name: {
        marginTop: "-80px"
    },

    main: {
        ...main,
        padding: "20px 0 !important",
    },
    mainRaised: {
        ...mainRaised,
        margin: "0px !important",
        // marginLeft: "0px",
        // marginRight: "0px !important",
        borderRadius: "0px !important",
        backgroundColor: grayColor[2]
    },
    title: {
        ...title,
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none"
    },
    follow: {
        position: "absolute",
        top: "0",
        right: "0"
    },
    followIcon: {
        width: "20px",
        height: "20px"
    },
    followButton: {
        marginTop: "-28px !important",
        marginRight: "15px",
    },
    gridItem: {
        ...mlAuto,
        ...mrAuto
    },
    collections: {
        marginTop: "20px"
    },
    cardBody: {
        display: "flex",
        boxOrient: "vertical",
        boxDirection: "normal",
        flexDirection: "column",
        boxPack: "center",
        justifyContent: "center"
    },
    badge: {
        display: "inline-table",
        margin: "0 auto"
    },
    listUnstyled: {
        paddingLeft: "0",
        listStyle: "none",
        "& > li": {
            padding: "5px 0px",
            fontSize: "1em"
        }
    },
    profileTabs: {
        marginTop: "4.284rem",
        marginBottom: "50px"
    },
    card: {
        textAlign: "left !important"
    },
    block: {
        color: "inherit",
        padding: "0.9375rem",
        fontWeight: "500",
        fontSize: "12px",
        textTransform: "uppercase",
        borderRadius: "3px",
        textDecoration: "none",
        position: "relative",
        display: "block"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0px",
        width: "auto"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    left: {
        float: "left!important",
        display: "block"
    },
    newJobSteps: {
        height: "20px !important",
        width: "20px !important",
        backgroundColor: successColor[0] + " !important"
    },
    cardWidth: {
        width: "60%",
        "@media (max-width: 992px)": {
            width: "100%",
        }
    },
    divHeight: {
        minHeight: "75vh"
    },
    paddingTop20: {
      paddingTop: "20px"
    },
    marginAuto: {
        margin: "0 auto"
    },
    jobNameWidth: {
      width: "100%",
      "& label": {
          width: '100%',
          marginLeft: "0px",
          marginRight: "0px",
          textAlign: "left",
          "& div": {
              width: '100%'
          }
      }
    },
    jobDescriptionWidth: {
        width: "100%",
        "&>div:first-child": {
            width: '100%',
            marginLeft: "0px",
            marginRight: "0px",
        },
        "& label": {
            width: '100%',
            textAlign: "left",
            marginLeft: "0px",
            marginRight: "0px",
            "& div": {
                width: '100%'
            }
        }
    },
    easyAccessInput: {
        width: "100%",
        "& label": {
            width: '100%',
            marginLeft: "0px",
            marginRight: "0px",
            textAlign: "center",
            "& div": {
                width: '100%',
                "& input": {
                    textAlign: "center"
                },
            },

        }
    },
    setrviceTypesText: {
        marginTop: "20px",
        color: "rgba(0, 0, 0, 1)",
        padding: "0",
        fontSize: "1rem",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
    },
    checkboxGroupWidth: {
        verticalAlign: "top",
        width:  "50%",
        display: "inline-block",
        textAlign: "left",
        "@media (max-width: 600px)": {
            width: "100%",
            display: "block"
        }
    },

    lawnMaintenancewidth: {
        width:  "50%",
        display: "inline-block",
        "@media (max-width: 767px)": {
            width: "100%",
            display: "block",
            marginTop: "20px !important",
        }
    },
    lawnSizeWidth: {
        width: "50%",
        "& label": {
            width: '100%',
            textAlign: "left",
            "& div": {
                width: '100%',
                marginRight: "30px",
            }
        },
        "@media (max-width: 767px)": {
            width: "100%",
            "& label": {
                width: '100%',
                marginLeft: "0px",
                marginRight: "0px",
                "& div": {
                    marginRight: "0px",
                }
            }
        }
    },
    lawnSize: {
        "&>label>div": {
            marginBottom: "0px"
        }
    },
    movingFrequency: {
        "&>div>label": {
            color: "rgba(0, 0, 0, 0.54) !important"
        },
        "&>div": {
          marginTop: "-3px !important"
        },
        "&>div>div": {
                marginTop: "11px"
        }
    },
    cardTitleText: {
        marginBottom: "0px",
        lineHeight: "1.2rem"
    },
    cityWidth: {
        width: "50%",
        display: "inline-block",
        "& label": {
            width: '100%',
            textAlign: "left",
            marginLeft: "0px",
            marginRight: "0px",
            "& div": {
                width: '100%',
                marginRight: "30px",
                "& span": {
                    position: "absolute",
                    bottom: "-20px"
                }
            }
        },
        "@media (max-width: 767px)": {
            width: "100%",
            "& label": {
                width: '100%',
                marginLeft: "0px",
                marginRight: "0px",
                "& div": {
                    marginRight: "0px",
                }
            }
        }
    },
    zipCodeWidth: {
        width: "50%",
        display: "inline-block",
        "& label": {
            width: '100%',
            textAlign: "left",
            marginLeft: "0px",
            marginRight: "0px",
            "& div": {
                width: '100%',
                "& span": {
                    position: "absolute",
                    bottom: "-20px"
                }
            }
        },
        "@media (max-width: 767px)": {
            width: "100%",
            "& label": {
                width: '100%',
                marginLeft: "0px",
                marginRight: "0px"
            }
        }
    },
    addressWidth: {
        width: "100%",
        display: "block",
        "& label": {
            width: '100%',
            marginLeft: "0px",
            marginRight: "0px",
            textAlign: "left",
            "& div": {
                width: '100%'
            }
        },
        "@media (max-width: 767px)": {
            width: "100%",
            "& label": {
                width: '100%',
                marginLeft: "0px",
                marginRight: "0px"
            }
        }
    },
    label: {
        cursor: "pointer",
        display: "inline-flex",
        fontSize: "14px",
        transition: "0.3s ease all",
        lineHeight: "1.428571429",
        fontWeight: "400",
        paddingLeft: "0"
    },
    labelForCalendars: {
        fontSize: "12px",
        color: grayColor[0],
        float:"left",
    },
    datepickerContainer: {
        width: "50%",
        display: "inline-block",
        "& .rdt input.form-control:focus": {
            backgroundImage: "linear-gradient(" + successColor[0] + ", " + successColor[0] + ")",
        },
        "& .rdtPicker .rdtTimeToggle": {
            color: successColor[0] + "!important"
        },
        "& .rdtPicker .rdtTime th.rdtSwitch": {
            color: successColor[0] + "!important"
        },
        "& .rdtCounter .rdtBtn": {
            color: successColor[0] + "!important"
        },
        "& .rdtCounters .rdtCounter:last-child .rdtCount": {
            color: successColor[0] + "!important",
            border: "1px solid " + successColor[0] + "!important"
        },
        "& rdtDay.rdtActive, .rdtActive, .rdtDay.rdtActive:hover": {
            backgroundColor: successColor[0] + "!important",
        },

        "& .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover": {
            backgroundColor: successColor[0] + "!important"
        }
    },
    pricesDivContainer: {
        width: "100%",
        display: "block"
    },
    minPriceWidth: {
        width: "50%",
        display: "inline-block",
        textAlign: "center",
        // marginLeft: "55px",
        "@media (max-width: 768px)": {
            width: "40%",
        },
        "& label": {
            width: "35%",
            textAlign: "left",
            margin: "0 auto",

            "@media (max-width: 768px)": {
                width: "75%",
            },

            "@media (max-width: 510px)": {
                width: "100%",
            },

            "@media (max-width: 323px)": {
                width: "102%",
            },

            "& div": {
                "& label": {
                    width: "100%"
                },
                "& div:before": {
                    borderBottom: "0px"
                }
            }
        }
        // float: "left"
    },
    maxPriceWidth: {
        width: "50%",
        display: "inline-block",
        textAlign: "center",
        "@media (max-width: 768px)": {
            width: "40%",
        },
        "& label": {
            width: "35%",
            textAlign: "left",
            margin: "0 auto",

            "@media (max-width: 768px)": {
                width: "75%",
            },

            "@media (max-width: 510px)": {
                width: "100%",
            },

            "@media (max-width: 323px)": {
                width: "102%",
            },

            "& div": {
                "& label": {
                  width: "100%"
                },
                "& div:before": {
                    borderBottom: "0px"
                }
            }
        }
        // float: "right"
    },
    horizontalMargin20: {
        marginLeft: "20px",
        marginRight: "20px",
    },
    orderPhone: {
        width: "50%",
        display: "inline-block",
        marginTop: "0px !important",
        "& label": {
            width: '100%',
            textAlign: "left",
            marginLeft: "0px",
            marginRight: "0px",
            "& div": {
                width: '100%',
            }
        },
        "@media (min-width: 768px)": {
            marginRight: "10px"
        },
        "@media (max-width: 767px)": {
            width: "100%",
            "& label": {
                width: '100%',
                marginLeft: "0px",
                marginRight: "0px"
            }
        }
    },
    orderBestTimeToCall: {
        width: "48%",
        display: "inline-block",
        "@media (min-width: 768px)": {
            left: "10px"
        },
        "@media (max-width: 767px)": {
            width: "100%",
            display: "block",

            "& label": {
                top: "-3px"
            }
        },
        "& div": {
            display: "block",
            marginTop: "13px"
        }
    },
    displayNone: {
        display: "none"
    },
    displayInlineBlock: {
        display: "inline-block"
    },
    displayBlock: {
        display: "block"
    },
    finalStepP: {
        textAlign: 'left',
        fontWeight: '800',
        "@media (max-width: 500px)": {
            display: "flex",
            flexDirection: "column",
        },
    },
    finalStepSpan: {
        fontWeight: '200'
    },
    top47: {
        top: "47px"
    },
    marginTop20: {
        marginTop: "20px"
    },
    marginBottom20: {
        marginBottom: "20px"
    },
    reviewTitle: {
        fontSize: "26px",
        fontWeight: "800"
    },
    step1Card: {
        transform: "translate(0%, 2%)",

        "@media (max-width: 1900px)": {
            transform: "translate(0%, 2%)"
        },
        "@media (max-width: 600px)": {
            transform: "translate(0%, 0%)"
        },
        "@media (device-width: 1024px) and (device-height: 1366px)": {
            transform: "translate(0%, 15%)"
        }
    },
    step2Card: {
        "@media (min-width: 768px) and (min-height: 680px)": {
            transform: "translate(0%, 25%)"
        },

        "@media (max-width: 767px)": {
            transform: "translate(0%, 7%)"
        },

        "@media (device-width: 1024px) and (device-height: 1366px)": {
            transform: "translate(0%, 45%)"
        }
    },
    step3Card: {
        transform: "translate(0%, 25%)",

        "@media (max-width: 767px)": {
            transform: "translate(0%, 30%)"
        },

        "@media (max-width: 400px)": {
            transform: "translate(0%, 30%)"
        },
        "@media (device-width: 1024px) and (device-height: 1366px)": {
            transform: "translate(0%, 60%)"
        },
    },
    step4Card: {
        transform: "translate(0%, 65%)",

        "@media (max-width: 1200px)": {
            transform: "translate(0%, 40%)"
        },
        "@media (max-width: 992px)": {
            transform: "translate(0%, 50%)"
        },
        "@media (max-width: 767px)": {
            transform: "translate(0%, 40%)"
        },
        "@media (device-width: 1024px) and (device-height: 1366px)": {
            transform: "translate(0%, 70%)"
        },
    },
    navPills: {
        right: "-60px !important",
        position: "absolute !important",
        "@media (max-width: 600px)": {
            right: "-90px !important"
        }
    },
    errorModalTitle: {
        color: "red",
        fontWeight: "800 !important",
        textAlign: "center !important"
    },
    jobPostingPageParallax: {
        height: "100px"
    }
};

export default jobPostingPageStyle;
