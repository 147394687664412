/* eslint-disable react/prop-types,no-unused-vars */
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import PreloaderComponent from "../../components/PreloaderComponent/PreloaderComponent";
import "assets/css/style.css";

import jobDetailsPageStyle from "assets/jss/material-kit-pro-react/views/jobDetailsPageStyle.jsx";
import "assets/css/style.css";
import classNames from "classnames";
import { connect } from "react-redux";
import {alertActions, jobActions} from "../../actions";
import { overtimeActions } from "../../actions/overtime.actions";
import { orderActions } from "../../actions/order.actions";
import { paymentActions } from "../../actions/payment.actions";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import {logger} from "redux-logger/src";
const queryString = require("query-string");

class JobContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: "",
      order: [],
      preloader: true,
      checked: false,
      hours: "",
      overtime: "",
      textValue: "",
      Error: false,
      ErrorMessage: ""
    };
  }

  componentDidMount() {
    let jobId = this.props.match.params.id;
    if (jobId) {
      Promise.all([
          this.props.getJobById(jobId),
          this.props.findOrder(jobId)
        ]
      ).then(res => {
        this.setState({
          job: res[0].job,
          order: res[1].order,
          preloader: false
        });
      }).catch(err => {
          alertActions.error(err);
        }
      );

    }
  }

  handleChecked(e) {
    this.setState({ checked: !e });
  }

  submitWork = e => {
    e.preventDefault();
    const jobObject = {
      jobName: this.state.job.jobName,
      contractorId: this.props.user._id,
      accept: true,
      status: "Pending approval"
    };
    if (this.state.textValue) jobObject.textValue = this.state.textValue;
    if (this.state.hours && this.state.hours > 0) jobObject.overtime = true;

    let jobId = this.props.match.params.id;

    if (this.state.checked === true) {
      let percent = 10;
      
      let overtimeObject = {
        order: this.state.order,
        textValue: this.state.textValue,
        hours: this.state.hours,
        percent: percent,
        price: this.state.hours * 20,
        total: this.state.hours * 20 + (this.state.hours * 20 * percent) / 100
      };

      this.props.createOvertime(overtimeObject).then(
        res => {
          this.updateContractorJob(jobId, jobObject);
        },
        err => {
          console.log(err);
          this.setState({ preloader: false, Error: true, ErrorMessage: err });
        }
      );
    }

    this.updateContractorJob(jobId, jobObject);
  };

  updateContractorJob(jobId, jobObject) {
    this.props.updateContractorJob(jobId, jobObject).then(
      res => {
        this.props.history.push({
          pathname: "/my-contracts",
          state: {
            jobSuccess: "Job has been updated"
          }
        });
      },
      err => {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    );
  }

  handleChange = event => {
    this.setState({ textValue: event.target.value });
  };

  closeAlerts = () => {
    this.setState({ Error: false, ErrorMessage: "" });
  };

  render() {
    const { classes, rest } = this.props;
    if (this.state.preloader) return <PreloaderComponent />
    return (
      <div>
        <div
          className={classNames(
            classes.main + " fix-height-conteiner",
            classes.mainRaised + " m-header" + " content-center"
          )}
        >
          <div
            className={
              classes.container + " " + classes.customerDashboardContainer
            }
          >
            {this.state.Error ? (
              <div>
                <SnackbarContent
                  message={<span>{this.state.ErrorMessage}</span>}
                  close
                  color="danger"
                  icon="info_outline"
                  closeAlert={this.closeAlerts}
                />
              </div>
            ) : null}
            <GridContainer
              justify="center"
              className={classes.customerNameContainer + " m-cont"}
            >
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.customerName}
                >
                  <Card className={"cart-container-job-contractor"}>
                    <CardBody>
                      <form onSubmit={this.submitWork}>
                        <h3
                          className={
                            "v-j-variable job-page-title job-pay-title"
                          }
                        >
                          Submit work
                        </h3>
                        <p className={"v-j-variable text-complete-work"}>
                          Use this form to request approval for work completed.
                          Your payment will be released upon approval.
                        </p>
                        <hr />
                        <div />
                        <div className={"job-pay-details"}>
                          <p className={"v-j-variable "}>Your job details:</p>
                          <p className={"v-j-variable"}>
                            City: {this.state.job.city}
                          </p>
                          <p className={"v-j-variable"}>
                            Address: {this.state.job.address}
                          </p>
                          <p className={"v-j-variable"}>
                            Amount: £{this.state.job.total.toFixed(2)}
                          </p>
                        </div>
                        <hr />
                        <div className={"job-pay-details"}>
                          <div className={"block-over-work"}>
                            <Checkbox
                              tabIndex={-1}
                              onClick={e =>
                                this.handleChecked(this.state.checked)
                              }
                              checked={
                                this.state.checked === true ? true : false
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot + " checkbox-overtime"
                              }}
                            />{" "}
                            <div className={"div-overtime"}>
                              Request payment for overtime work
                            </div>
                          </div>
                          {this.state.checked ? (
                            <div>
                              <div className={"complete-work-square"}>
                                <p className={"v-j-variable"}>
                                  Please enter the amount of hours:
                                </p>
                                <input
                                  type="number"
                                  min="0"
                                  name="hours"
                                  required
                                  value={this.state.hours}
                                  className={"square-meters"}
                                  placeholder=""
                                  onChange={e => {
                                    this.setState({
                                      hours: Number(e.target.value)
                                    });
                                  }}
                                />
                              </div>
                              <p className={"div-comment"}>
                                You can leave your comment here:
                              </p>{" "}
                              <textarea
                                className={"submit-work-textarea"}
                                value={this.state.textValue}
                                onChange={this.handleChange}
                              />
                            </div>
                          ) : null}

                          <Button
                            className={"btn-submit-work"}
                            color={"success"}
                            type={"submit"}
                          >
                            Submit work
                          </Button>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </div>
        </div>
        
        {this.state.preloader && <PreloaderComponent />}
      </div>
    );
  }
}

export default connect(
  store => {
    return {
      user: store.users.user
    };
  },
  {
    getJobById: jobActions.getJobById,
    createOrder: orderActions.createOrder,
    findOrder: orderActions.findOrder,
    createJobPayment: paymentActions.createJobPayment,
    executePayment: paymentActions.executePayment,
    createBatchPayouts: paymentActions.createBatchPayouts,
    updateContractorJob: jobActions.updateContractorJob,
    createOvertime: overtimeActions.createOvertime
  }
)(withStyles(jobDetailsPageStyle)(JobContractor));
